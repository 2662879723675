import React from "react";
import ServiceCard from "../../../../../common/components/ServiceCard";
import ProductDetailModal from "../../../../../common/components/ProductDetailModal";
import { useAppDispatch, useAppSelector } from "../../../../../redux";
import { ProductType } from "../../../../../redux/slices/product-detail/Types";
import {
  cartCreateOrUpdateCartSagaActions,
  cartCreateOrUpdateCartSagaActionsSubscription,
} from "../../../../../redux/saga/cart/saga-actions";
import ServiceContractImage from "../../../../../common/assets/images/contract-image.png";
import moment from "moment";
import {
  Button,
  Divider,
  Iconography,
  IconographySizes,
  IconographyThemes,
} from "@hid-galaxy-ui/galaxy-react";
import { DividerVariants } from "@hid-galaxy-ui/galaxy-react/components/Divider/dividerEnums";
import { useNavigate } from "react-router";
import ServiceContractDetailModal from "../../../../../common/components/ServiceContractDetailModal";
import { ContractType } from "../../../../../redux/slices/my-account/Types";
import "./TabContentHome.scss";

const TabContentHome: React.FC<{
  collection: any;
  contractState: {
    filteredServiceContract: ContractType[];
    activeContract: ContractType[];
    inActiveContract: ContractType[];
  };
}> = ({ collection, contractState }) => {
  const [productDetailModalOpen, setProductDetailModalOpen] =
    React.useState<boolean>(false);
  const [serviceDetailModalOpen, setServiceDetailModalOpen] =
    React.useState<boolean>(false);
  const [detailModalProduct, setDetailModalProduct] =
    React.useState<ProductType>({
      title: "",
      id: "",
      description: "",
      productType: "",
      priceRange: {
        minVariantPrice: {
          amount: "",
          currencyCode: "",
        },
        maxVariantPrice: {
          amount: "",
          currencyCode: "",
        },
      },
      featuredImage: {
        url: "",
      },
      metafields: [
        {
          key: "",
          value: "",
        },
      ],
      variants: {
        edges: [
          {
            node: {
              sku: "",
              id: "",
            },
          },
        ],
      },
      tags: [],
      sellingPlanGroups: {
        edges: [
          {
            node: {
              sellingPlans: {
                edges: [
                  {
                    node: {
                      id: "",
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    });
  const [detailModalContract, setDetailModalContract] =
    React.useState<ContractType>({
      Active: "",
      ContractEndDate: "",
      ContractId: "",
      ContractName: "",
      ContractStartDate: "",
      Created: "",
      LastUpdated: "",
      OnboardingStageId: "",
      PartnerId: "",
      PartnerManagerId: "",
      Status: "",
      Orderdate: "",
      OrderId: "",
      Collection: "",
      SubscriptionId: "",
      Price: "",
      Sku: "",
      SubscriptionStatus: "",
      ProductType: "",
    });
  const { cartItems } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userToken: string | null = localStorage.getItem("user-token");

  const onAddToCartClick: (
    productVariantId: string,
    sellingPlanID: string | undefined
  ) => void = (productVariantId, sellingPlanID) => {
    let cartID = null;
    if (cartItems?.id) {
      let idArray = cartItems.id.split("/");
      cartID = idArray[idArray.length - 1];
    }
    if (sellingPlanID) {
      dispatch(
        cartCreateOrUpdateCartSagaActionsSubscription({
          cartId: cartID,
          userToken: userToken,
          pdp: {
            productVariantId: productVariantId,
            sellingPlanId: sellingPlanID,
          },
          navigateFunction: () => {},
        })
      );
    } else {
      dispatch(
        cartCreateOrUpdateCartSagaActions({
          cartId: cartID,
          userToken: userToken,
          pdp: {
            featureSectionTitleAndVariantIdArray: [],
            productVariantId: productVariantId,
          },
          navigateFunction: () => {},
        })
      );
    }
  };

  const onViewClick: (product: ProductType) => void = (product) => {
    setDetailModalProduct(product);
    setProductDetailModalOpen(true);
  };

  const onRenewNowClick = (contract: ContractType) => {
    const subscription = contract.SubscriptionId ? true : false;
    if (subscription) {
      setDetailModalContract(contract);
      setServiceDetailModalOpen(true);
    } else {
      navigate("/partner/dashboard/services");
    }
  };

  if (contractState.activeContract.length > 0) {
    return (
      <div className="contract-detail-container">
        {contractState.activeContract.map(
          (contract: ContractType, index: number) => {
            const subscription = contract.SubscriptionId ? true : false;
            const isProductAvailable =
              collection.products.nodes.filter(
                (product: any) =>
                  product.variants.edges[0].node.sku === contract.Sku
              ).length > 0;
            const dateBoolean =
              subscription && contract.SubscriptionStatus === "active";
            const contractStartDateMoment = moment(
              contract.ContractEndDate
            ).startOf("day");
            const expiringSoon =
              contractStartDateMoment <= moment().add(1, "M") &&
              contract.SubscriptionStatus !== "active";
            const renewingSoon =
              contractStartDateMoment <= moment().add(1, "M") &&
              contract.SubscriptionStatus === "active";
            const expiringSoonAndPrimary =
              expiringSoon &&
              contract.Collection.replaceAll(" ", "_").toLowerCase() ===
                "support_service";
            const renewingSoonAndPrimary =
              renewingSoon &&
              contract.Collection.replaceAll(" ", "_").toLowerCase() ===
                "support_service";
            const daysLeft = dateBoolean
              ? contractStartDateMoment
                  .clone()
                  .add(1, "d")
                  .diff(moment().startOf("day"), "days")
              : contractStartDateMoment.diff(moment().startOf("day"), "days");
            return (
              <div className="contract-detail-row" key={contract.ContractId}>
                {index === 0 ? (
                  <></>
                ) : (
                  <Divider mode={DividerVariants.Canvas} />
                )}
                <div className="row-1">
                  <div className="left">
                    <div className="image-wrapper">
                      <img src={ServiceContractImage} alt="contract-image" />
                    </div>
                    <div className="info">
                      <div className="name">{contract.ContractName}</div>
                      {isProductAvailable ? (
                        <div className="date">
                          <label>{dateBoolean ? "Renews" : "Expires"}</label>
                          <p>
                            {dateBoolean
                              ? contractStartDateMoment
                                  .clone()
                                  .add(1, "d")
                                  .format("DD MMM, YYYY")
                              : contractStartDateMoment.format("DD MMM, YYYY")}
                          </p>
                        </div>
                      ) : (
                        <div className="no-product">Product Discontinued</div>
                      )}
                    </div>
                  </div>
                  <div className="right">
                    {expiringSoonAndPrimary && (
                      <Button
                        variant="secondary"
                        label="Renew Now"
                        disabled={!isProductAvailable}
                        onClick={() => onRenewNowClick(contract)}
                      />
                    )}
                  </div>
                </div>
                {expiringSoonAndPrimary && (
                  <div className="warning">
                    <Iconography
                      icon="triangleExclamation"
                      size={IconographySizes.Medium}
                      theme={IconographyThemes.Error}
                    />
                    <p>
                      Attention Required - {contract.ContractName} Expires in{" "}
                      {daysLeft} Day(s)
                    </p>
                  </div>
                )}
                {renewingSoonAndPrimary && (
                  <div className="warning">
                    <Iconography
                      icon="triangleExclamation"
                      size={IconographySizes.Medium}
                      theme={IconographyThemes.Warning}
                    />
                    <p>
                      Attention Required - {contract.ContractName} Renews in{" "}
                      {daysLeft} Day(s)
                    </p>
                  </div>
                )}
              </div>
            );
          }
        )}
        {serviceDetailModalOpen && (
          <ServiceContractDetailModal
            serviceContractDetail={detailModalContract}
            setDetailModalOpen={setServiceDetailModalOpen}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="tab-service-container">
        <div className="no-contract-message">
          Purchase a product to view it here
        </div>
        <div className="tile-container">
          {collection.products.nodes.map((product: any, index: number) => {
            return (
              <ServiceCard
                width={328}
                product={product}
                onAddToCartClick={onAddToCartClick}
                onViewClick={onViewClick}
                key={product.id}
              />
            );
          })}
        </div>
        {productDetailModalOpen && (
          <ProductDetailModal
            product={detailModalProduct}
            onAddToCartClick={onAddToCartClick}
            setDetailModalOpen={setProductDetailModalOpen}
          />
        )}
      </div>
    );
  }
};

export default TabContentHome;

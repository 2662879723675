import React, { FC } from "react";
import { useAppSelector } from "../../../redux";
import InvoicePage from "./components/InvoicePage";
import Download from "./components/DownloadPDF";
import "./scss/main.scss";

interface Props {
  orderID: string;
  showInvoice: boolean;
}

const InvoiceGenerator: FC<Props> = ({ orderID, showInvoice }) => {
  const { orderDetail, status } = useAppSelector((state) => state.my_account);

  return (
    <div className="invoice-generator">
      {showInvoice ? (
        <>
          <h1 className="center fs-30">React Invoice Generator</h1>
          {status?.orderDetailLoading ? <></> : <InvoicePage orderDetail={orderDetail} />}
        </>
      ) : (
        <></>
      )}
      <Download orderID={orderID} />
    </div>
  );
};

export default InvoiceGenerator;

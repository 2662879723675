import React from "react";
import CustomerDetail from "./customer-detail";
import {
  allOrdersSagaAction,
  contractFindAllSagaAction,
  fetchCustomerDetailSagaAction,
} from "../../../redux/saga/my-account/saga-actions";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { getCollectionListAction } from "../../../redux/saga/product-list/saga-action";
import { Loader, LoaderSizes, Tabs } from "@hid-galaxy-ui/galaxy-react";
import TabContentServiceContract from "./tab-content-service-contract";
import TabContentOrderHistory from "./tab-content-order-history";
import { useLocation } from "react-router";
import useProductListPageRoute from "../../../hooks/useProductListPageRoute";
import "./MyAccount.scss";

const MyAccount = () => {
  const [tabSelectedKey, setTabSelectedKey] = React.useState<string>("");
  const [tabsMyAccount, setTabsMyAccount] = React.useState([]);
  const { customer, contracts, allOrders, status } = useAppSelector(
    (state) => state.my_account
  );
  const { collection, status: statusPLP } = useAppSelector(
    (state) => state.plp
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { getRouteIndex, dispatchIt } = useProductListPageRoute();
  const shopifyNavigationHandle = "service-catalog";
  const subCollection = collection[getRouteIndex(shopifyNavigationHandle)];
  const sortedCollection = React.useMemo(() => subCollection, [collection]);

  React.useEffect(() => {
    if (location.state) {
      const element = document.getElementById("my-services-container");
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
      if (location.state.selectedTab === "order-history") {
        setTabSelectedKey("order-history");
      }
    } else {
      window.scrollTo({ top: 0 });
    }

    if (customer.email === "") dispatch(fetchCustomerDetailSagaAction());

    if (collection[getRouteIndex(shopifyNavigationHandle)]?.length === 0) {
      dispatchIt(shopifyNavigationHandle);
    }
  }, []);

  React.useEffect(() => {
    const subColl = collection[getRouteIndex(shopifyNavigationHandle)];
    if (subColl.length > 0) {
      let TabsMyAccount = subColl.map((collection) => {
        return {
          key: collection.handle.trim().toLowerCase().replaceAll("-", "_"),
          label: collection.title,
        };
      });
      TabsMyAccount.push({ key: "order-history", label: "Order History" });
      setTabsMyAccount(TabsMyAccount);
    }
  }, [collection]);

  React.useEffect(() => {
    if (contracts.length === 0 && customer.company !== "") {
      dispatch(contractFindAllSagaAction(customer.company));
    }
    if (allOrders.length === 0 && customer.company !== "") {
      dispatch(allOrdersSagaAction(customer.company));
    }
  }, [customer]);

  function onTabClickHandler(e: any, key: string): void {}

  return (
    <div className="my-account-container">
      <h1 className="title">My Account</h1>
      <CustomerDetail />
      <div className="my-services-container" id="my-services-container">
        <h1 className="service-title">My Services</h1>
        {statusPLP.collectionListLoading ? (
          <div className="loading-tabs">
            <Loader variant={LoaderSizes.large} />
          </div>
        ) : tabsMyAccount?.length > 0 ? (
          <Tabs
            theme="frameless"
            selectedKey={tabSelectedKey}
            items={tabsMyAccount.map((tab, index: number) => {
              const filteredCollection = sortedCollection.filter(
                (collection) => {
                  return (
                    collection.handle
                      .trim()
                      .toLowerCase()
                      .replaceAll("-", "_") === tab.key
                  );
                }
              );
              return {
                key: tab.key,
                label: tab.label,
                content:
                  tab.key === "order-history" ? (
                    <TabContentOrderHistory />
                  ) : (
                    <TabContentServiceContract
                      collectionKey={tab.key}
                      collection={filteredCollection[0]}
                    />
                  ),
              };
            })}
            onClick={onTabClickHandler}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MyAccount;

import React from "react";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import InvoicePage from "./InvoicePage";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { saveAs } from "file-saver";
import { orderDetailPDFSagaAction } from "../../../../redux/saga/my-account/saga-actions";
import {
  Button,
  Iconography,
  IconographySizes,
  Loader,
  Tooltip,
  TooltipPlacements,
} from "@hid-galaxy-ui/galaxy-react";
import { LoaderSizes } from "@hid-galaxy-ui/galaxy-react/components/Loader/loaderEnums";

interface Props {
  orderID: string;
}

const Download: React.FC<Props> = ({ orderID }) => {
  const { orderDetailPDF, orderDetailPDFID, orderDetail, status } = useAppSelector(
    (state) => state.my_account
  );
  const dispatch = useAppDispatch();

  const title = orderDetail?.name
    ? "invoice" + orderDetail.name.toLowerCase()
    : "invoice";

  const generatePdfDocument = async (
    orderDetailPdfData: typeof orderDetailPDF
  ) => {
    const title = orderDetailPdfData?.name
      ? "invoice" + orderDetailPdfData.name.toLowerCase()
      : "invoice";
    const blob = await pdf(
      <InvoicePage pdfMode={true} orderDetail={orderDetailPdfData} />
    ).toBlob();
    saveAs(blob, `${title}.pdf`);
  };

  const onDownloadPDFClick = () => {
    dispatch(
      orderDetailPDFSagaAction({
        orderId: orderID,
        generatePdfDocumentFunction: generatePdfDocument,
      })
    );
  };

  return (
    <Tooltip message="Download PDF" placement={TooltipPlacements.Bottom}>
      <Button
        variant="secondary"
        icon={
          orderID === orderDetailPDFID && status.orderDetailPDFLoading ? (
            <Loader variant={LoaderSizes.small} />
          ) : (
            <Iconography icon="fileArrowDown" size={IconographySizes.Medium} />
          )
        }
        onClick={onDownloadPDFClick}
      />
    </Tooltip>
  );
};

export default Download;

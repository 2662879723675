import { createSlice } from "@reduxjs/toolkit"
import useProductListPageRoute from "../../../hooks/useProductListPageRoute"

type initialstateType = {
  collectionList: {
    title: string, 
    url: string 
  }[],
  collection: {
    id?: string,
    handle?: string,
    title?: string,
    description?: string,
    products?: {
      nodes?: {
        title?: string,
        id?: string,
        description?: string,
        descriptionHtml?: string,
        productType?: string,
        priceRange?: {
          minVariantPrice?: {
            amount?: string,
            currencyCode?: string
          }
          maxVariantPrice?: {
            amount?: string,
            currencyCode?: string,
          }
        }
        featuredImage?: {
          url?: string,
        }
        metafields: Array<{
          key: string;
          value: string;
          }>,
        variants?: {
          edges?: {
            node?: {
              sku?: string
              id?: string
            }
          }[],
        }
        tags?: string[],
        sellingPlanGroups: {
          edges: {
              node: {
                  sellingPlans: {
                      edges: {
                          node: {
                              id: string,
                          }
                      }[]
                  }
              }
          }[],
        }
      }[]
    }
  }[][],
  allowedSupportPackage: Array<string>;
  purchasedSupportPackage: string;
  status : {
    collectionListLoading: boolean,
    loading : boolean,
    error : boolean,
    errorMessage : string,
    errorType : string,
  }
}

var initialstate: initialstateType = {
  collectionList: [],
  collection: [
    [],
    [],
    [],
    [],
  ],
  allowedSupportPackage: [],
  purchasedSupportPackage: "",
  status: {
    error: false,
    errorMessage: "",
    errorType: "",
    loading: false,
    collectionListLoading: false
  },
}

const productList = createSlice({
  name: "plp",
  initialState: initialstate,
  reducers: {
    setCollectionList: (state, action) => {
      state.collectionList = action.payload
    },
    setCollectionListLoading : (state, action) => {
      state.status.collectionListLoading = action.payload;
    },
    setCollection: (state, action) => {
      state.collection[action?.payload?.subTypeIndex].push(action.payload.data);
    },
    setLoadingStatus : (state, action) => {
      state.status.loading = action.payload;
    },
    setAllowedSupportPackage : (state, action) => {
      state.allowedSupportPackage = action.payload;
    },
    setPurchasedSupportPackage : (state, action) => {
      state.purchasedSupportPackage = action.payload;
    },
    reset : (state) => {
      state.allowedSupportPackage = [];
      state.purchasedSupportPackage = "";
      state.collectionList = []
      state.collection = [
        [],
        [],
        [],
        [],
      ]
    }
  }
})

export const productListSliceReducer = productList.reducer;
export const productListSliceAction = productList.actions;

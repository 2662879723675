import {all} from "redux-saga/effects"
import productSaga from "./product-list"
import authSaga from './auth'
import homeSaga from './home'
import productDetailSaga from './product-detail'
import myAccountSaga from "./my-account"
import cartSaga from './cart'
import faqSaga from "./faq"
import partnerDetailsSaga from "./partner-details"

function* rootSaga(){
    try{
        yield all([
            authSaga(),
            homeSaga(),
            productSaga(),
            cartSaga(),
            productDetailSaga(),
            myAccountSaga(),
            faqSaga(),
            partnerDetailsSaga(),
        ])
    }
    catch(e){
       alert("error in saga")
    }
   
}

export default rootSaga;
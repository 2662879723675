import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import {
  Avatar,
  AvatarSize,
  Button,
  Divider,
  DividerVariants,
  Dropdown,
  Iconography,
  IconographySizes,
  Loader,
  LoaderSizes,
  TextField,
} from "@hid-galaxy-ui/galaxy-react";
import { CountryCode } from "./CountryCode";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { updateCustomerSagaAction } from "../../../../redux/saga/my-account/saga-actions";
import "./CustomerDetail.scss";

const CustomerDetail = () => {
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [customerDetail, setCustomerDetail] = React.useState<{
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    countryCode: string;
    phoneNo: string;
  }>({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    countryCode: "",
    phoneNo: "",
  });
  const [emailStatus, setEmailStatus] = React.useState<
    { status: "success" | "error" } | {}
  >({});
  const [phoneStatus, setPhoneStatus] = React.useState<
    { status: "success" | "error" } | {}
  >({});
  const [errorMsg, setErrorMsg] = React.useState({
    emailMsg: "",
    phoneMsg: "",
  });
  const [selected, setSelected] = React.useState({});
  const { customer, status } = useAppSelector((state) => state.my_account);
  const dispatch = useAppDispatch();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const digitRegex = /^\d+$/;
  const CountryCodeOptions = CountryCode.map((country) => {
    return {
      label: country.name,
      value: country.dial_code,
    };
  });

  React.useEffect(() => {
    const phone = customer.phone ? customer.phone : "";
    const parsedPhoneNumber = parsePhoneNumberFromString(phone);
    let dialCode = "+1";
    let nationalNo = "";
    if (parsedPhoneNumber) {
      dialCode = "+" + parsedPhoneNumber.countryCallingCode;
      nationalNo = parsedPhoneNumber.nationalNumber;
      let option = CountryCode.find(
        (country) => country.code === parsedPhoneNumber.country
      );
      setSelected({
        label: option.name,
        value: option.dial_code,
      });
    } else {
      setSelected({
        label: "United States",
        value: "+1",
      });
    }

    setCustomerDetail({
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      role: "Channel Partner",
      countryCode: dialCode,
      phoneNo: nationalNo,
    });
  }, [customer]);

  React.useEffect(() => {
    if (errorMsg.emailMsg !== "" && emailRegex.test(customerDetail.email)) {
      setEmailStatus({});
      setErrorMsg({ ...errorMsg, emailMsg: "" });
    }
  }, [customerDetail.email]);

  React.useEffect(() => {
    if (customerDetail.phoneNo.length === 0) {
      setPhoneStatus({});
      setErrorMsg({ ...errorMsg, phoneMsg: "" });
    }
    const parsedPhoneNumber = parsePhoneNumberFromString(
      customerDetail.countryCode + customerDetail.phoneNo
    );
    if (
      parsedPhoneNumber &&
      parsedPhoneNumber.isValid() &&
      digitRegex.test(customerDetail.phoneNo)
    ) {
      setPhoneStatus({});
      setErrorMsg({ ...errorMsg, phoneMsg: "" });
    }
  }, [customerDetail.phoneNo, customerDetail.countryCode]);

  const onCancelButtonClick = () => {
    setEditMode(false);
    const phone = customer.phone ? customer.phone : "";
    const parsedPhoneNumber = parsePhoneNumberFromString(phone);
    let dialCode = "+1";
    let nationalNo = "";
    if (parsedPhoneNumber) {
      dialCode = "+" + parsedPhoneNumber.countryCallingCode;
      nationalNo = parsedPhoneNumber.nationalNumber;
      let option = CountryCode.find(
        (country: { name: string; dial_code: string; code: string }) =>
          country.code === parsedPhoneNumber.country
      );
      setSelected({
        label: option.name,
        value: option.dial_code,
      });
    } else {
      setSelected({
        label: "United States",
        value: "+1",
      });
    }

    setCustomerDetail({
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      role: "Channel Partner",
      countryCode: dialCode,
      phoneNo: nationalNo,
    });
  };

  const onSaveButtonClick = () => {
    setEditMode(false);
    const updateCustomer = {
      id: customer.id,
      firstName: customerDetail.firstName,
      lastName: customerDetail.lastName,
      email: customerDetail.email,
      phone:
        customerDetail.phoneNo.length > 0
          ? customerDetail.countryCode + customerDetail.phoneNo
          : "",
    };
    if (
      updateCustomer.firstName !== customer.firstName ||
      updateCustomer.lastName !== customer.lastName ||
      updateCustomer.email !== customer.email ||
      updateCustomer.phone !== (customer.phone ? customer.phone : "")
    )
    dispatch(updateCustomerSagaAction(updateCustomer))
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setCustomerDetail({
      ...customerDetail,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const onCountryCodeSelect = (data: { label: string; value: string }) => {
    let value = data ? data.value : "";
    setSelected(data);
    setCustomerDetail({
      ...customerDetail,
      countryCode: value,
    });
    const parsedPhoneNumber = parsePhoneNumberFromString(
      data.value + customerDetail.phoneNo
    );
    if (customerDetail.phoneNo.length > 0) {
      if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        setPhoneStatus({ status: "error" });
        setErrorMsg({ ...errorMsg, phoneMsg: "Enter a valid phone number" });
      }
    }
  };

  const validateEmail = () => {
    if (!emailRegex.test(customerDetail.email)) {
      setEmailStatus({ status: "error" });
      setErrorMsg({ ...errorMsg, emailMsg: "Enter a valid email" });
    }
  };

  const validatePhone = () => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      customerDetail.countryCode + customerDetail.phoneNo
    );
    if (customerDetail.phoneNo.length > 0) {
      if (
        !parsedPhoneNumber ||
        !parsedPhoneNumber.isValid() ||
        !digitRegex.test(customerDetail.phoneNo)
      ) {
        setPhoneStatus({ status: "error" });
        setErrorMsg({ ...errorMsg, phoneMsg: "Enter a valid phone number" });
      }
    }
  };

  const saveButtonDisabled = () => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      customerDetail.countryCode + customerDetail.phoneNo
    );
    if (customerDetail.phoneNo.length > 0) {
      if (
        !parsedPhoneNumber ||
        !parsedPhoneNumber.isValid() ||
        !digitRegex.test(customerDetail.phoneNo)
      ) {
        return true;
      }
    }
    if (!emailRegex.test(customerDetail.email)) return true;

    return false;
  };

  return (
    <div className="customer-detail-container">
      {status.customerDetailLoading || status.customerUpdateLoading ? (
        <div
          style={{
            padding: "64px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader variant={LoaderSizes.large} />
        </div>
      ) : (
        <>
          <div className="row-1">
            <h1>Name & Avatar</h1>
            {editMode ? (
              <div style={{ display: "flex", gap: "12px" }}>
                <Button
                  label="Cancel"
                  variant="secondary"
                  onClick={onCancelButtonClick}
                />
                <Button
                  label="Save"
                  variant="primary"
                  disabled={saveButtonDisabled()}
                  onClick={onSaveButtonClick}
                />
              </div>
            ) : (
              <Button
                label="Edit"
                variant="secondary"
                icon={<Iconography icon="pen" />}
                onClick={() => setEditMode(true)}
              />
            )}
          </div>
          <div className="row-2">
            {false ? (
              <Avatar
                size={AvatarSize.XXLarge}
                isEditable={false}
                image="https://gw-assets.assaabloy.com/is/image/assaabloy/divisions-card-list-16x9:16x9-M"
              />
            ) : (
              <Avatar size={AvatarSize.XXLarge} isEditable={false} />
            )}
            {editMode ? (
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="input-wrapper">
                  <TextField
                    label="First Name"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter your first name"
                    value={customerDetail.firstName}
                    theme="dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="input-wrapper">
                  <TextField
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter your last name"
                    value={customerDetail.lastName}
                    theme="dark"
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : (
              <h2>{customer.displayName}</h2>
            )}
          </div>
          <Divider mode={DividerVariants.Content} />
          <div className="row-3">
            <h1>Details</h1>
          </div>
          <div className="row-4">
            <div className="info-group">
              <div
                className="icon-wrapper"
                style={{ marginTop: editMode ? "18px" : "" }}
              >
                <Iconography icon="envelop" size={IconographySizes.Medium} />
              </div>
              <div className="info">
                <label>E-mail</label>
                {editMode ? (
                  <div className="input-wrapper">
                    <TextField
                      {...emailStatus}
                      id="email"
                      name="email"
                      placeholder="Enter your E-mail"
                      value={customerDetail.email}
                      theme="dark"
                      helperText={errorMsg.emailMsg}
                      onChange={handleChange}
                      onBlur={validateEmail}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <p>{customer.email}</p>
                )}
              </div>
            </div>
            <div className="info-group">
              <div
                className="icon-wrapper"
                style={{ marginTop: editMode ? "18px" : "" }}
              >
                <Iconography icon="usersGear" size={IconographySizes.Medium} />
              </div>
              <div className="info">
                <label>Partner Company Name</label>
                {editMode ? (
                  <div className="input-wrapper">
                    <TextField
                      id="partnerCompanyName"
                      name="Partner Company Name"
                      placeholder="Partner Company Name"
                      value={customer.company}
                      theme="dark"
                      onChange={() => {}}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <p>{customer.company}</p>
                )}
              </div>
            </div>
            <div className="info-group">
              <div
                className="icon-wrapper"
                style={{ marginTop: editMode ? "18px" : "" }}
              >
                <Iconography icon="phone" size={IconographySizes.Medium} />
              </div>
              <div className="info">
                <label>Phone Number</label>
                {editMode ? (
                  <div style={{ display: "flex", gap: "8px" }}>
                    <div className="dropdown-wrapper">
                      <Dropdown
                        id="countryCode"
                        placeholder="Country Code"
                        options={CountryCodeOptions}
                        selected={selected}
                        onSelect={onCountryCodeSelect}
                        menuPortalTarget={document.body}
                      />
                    </div>
                    <div className="phone-wrapper">
                      <TextField
                        {...phoneStatus}
                        id="phoneNo"
                        name="phoneNo"
                        placeholder="Phone number"
                        value={customerDetail.phoneNo}
                        theme="dark"
                        helperText={errorMsg.phoneMsg}
                        onChange={handleChange}
                        onBlur={validatePhone}
                      />
                    </div>
                  </div>
                ) : (
                  <p>{customer.phone ? customer.phone : <span>-</span>}</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDetail;

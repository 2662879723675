import { createSlice } from "@reduxjs/toolkit"

type initialstateType = {
    banners : {}[],
    featuredCollection?: {
        "id"?: string,
        "handle"?: string,
        "title"?: string,
        "description"?: string,
        "products"?: {
            "nodes"?:
            {
                "title"?: string,
                "id"?: string,
                "description"?: string,
                "priceRange"?: {
                    "maxVariantPrice"?: {
                        "amount"?: string,
                        "currencyCode"?: string
                    }
                },
                "featuredImage"?: {
                    "src"?: string
                }
            }[]

        }
    }[],
    status : {
        loading : boolean,
        error : boolean,
        errorMessage : string,
        errorType : string,
    }
}

var initialstate: initialstateType = {
    banners : [],
    featuredCollection: [],
    status : {
        error : false,
        errorMessage : "",
        errorType : "",
        loading : false
    }
}

const homeSlice = createSlice({
    name: "home",
    initialState: initialstate,
    reducers: {
        setFeaturedCollection: (state, action) => {
            state.featuredCollection.push(action.payload)
        },
        setBanners : (state, action) => {
            state.banners = action.payload
        },
        setLoadingStatus : (state, action) => {
            state.status.loading = action.payload
        }
    }
})

export const homeScileReducer = homeSlice.reducer;
export const homeSliceAction = homeSlice.actions;


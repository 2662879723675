// creating actionTypes so that we do not have a mismatch of action type stings in saga!!
export const productListSagaActionTypes = {
    callGetCollectionListSaga : 'callGetCollectionListSaga',
    callGetCollectionSaga : 'callGetCollectionSaga',
    callAllowedSupportPackageSaga : 'callAllowedSupportPackageSaga'
}

// these are the saga acton that are need to be dispatched from components;
export const getCollectionListAction : (subTypeData? : string) => {
    type: string,
    payload? : {
        subType : string,
    }
} = (subTypeData) => {
    return {
        type : productListSagaActionTypes.callGetCollectionListSaga,
        payload :  {
            subType : subTypeData,
        }
    }
}


export const getCollectionAction : (subTypeData? : string, subTypeIndex? : number) => {
    type: string,
    payload? : {
        subType : string,
        subTypeIndex : number,
    }
} = (subTypeData, subTypeIndex) => {
    return {
        type : productListSagaActionTypes.callGetCollectionSaga,
        payload :  {
            subType : subTypeData,
            subTypeIndex : subTypeIndex
        }
    }
}

export const getAllowedSupportPackageAction : (company: string) => {
    type : string,
    payload : {
        company : string,
    }
} = (company) => {
    return {
        type : productListSagaActionTypes.callAllowedSupportPackageSaga,
        payload : {
            company : company
        }
    }
}
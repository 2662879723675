import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  contractFindAllSagaAction,
  activateBoldSubscriptionSagaAction,
  pauseBoldSubscriptionSagaAction,
} from "../../../redux/saga/my-account/saga-actions";
import {
  Divider,
  DividerVariants,
  Iconography,
  IconographySizes,
  Modal,
  Toggle,
} from "@hid-galaxy-ui/galaxy-react";
import convertToNoDecimalValue from "../../../common/utils/convertToNoDecimalValue";
import ServiceContractImage from "../../../common/assets/images/contract-image.png";
import moment from "moment";
import { ContractType } from "../../../redux/slices/my-account/Types";
import "./ServiceContractDetailModal.scss";

const ServiceContractDetailModal: React.FC<{
  serviceContractDetail: ContractType;
  setDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ serviceContractDetail, setDetailModalOpen }) => {
  const [subscriptionToggled, setSubscriptionToggled] = React.useState<boolean>(
    serviceContractDetail.SubscriptionStatus === "active"
  );
  const [isSubscriptionToggled, setIsSubscriptionToggled] =
    React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const { customer, subscriptionID, subscriptionStatus, status } =
    useAppSelector((state) => state.my_account);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!disabled && isSubscriptionToggled) {
      if (subscriptionStatus === "active") {
        setSubscriptionToggled(true);
      } else if (subscriptionToggled) {
        setSubscriptionToggled(false);
      }
      setDisabled(false);
    }
  }, [disabled]);

  React.useEffect(() => {
    // console.log(status.subscriptionLoading);
    if (subscriptionID === serviceContractDetail.SubscriptionId) {
      if (status.subscriptionLoading) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [status.subscriptionLoading]);

  const handleSubscriptionToggle = (e: any) => {
    setIsSubscriptionToggled(true);
    setSubscriptionToggled(e.target.checked);
    if (e.target.checked) {
      dispatch(
        activateBoldSubscriptionSagaAction(serviceContractDetail.SubscriptionId)
      );
    } else {
      dispatch(
        pauseBoldSubscriptionSagaAction(serviceContractDetail.SubscriptionId)
      );
    }
  };

  const onModalClose = () => {
    setDetailModalOpen(false);
    if (isSubscriptionToggled)
      dispatch(contractFindAllSagaAction(customer.company));
  };
  return (
    <Modal
      width={518}
      isDisplayCloseIcon={false}
      footerConfig={{
        primaryBtnLabel: "CLOSE WINDOW",
        onClickPrimaryBtn: () => onModalClose(),
      }}
      className="contract-detail-modal"
    >
      <div className="close-btn">
        <span onClick={() => onModalClose()}>
          <Iconography icon="circleX" size={IconographySizes.Medium} />
        </span>
      </div>
      <h1 className="title">Manage Service</h1>
      <div className="detail-body">
        <div className="image-wrapper">
          <img src={ServiceContractImage} alt="contract-img" />
        </div>
        <div className="info-1">
          <div className="name">{serviceContractDetail.ContractName}</div>
          <div className="collection">
            {serviceContractDetail.Collection.replaceAll("_", " ")}
          </div>
        </div>
        <div className="info-2">
          <div className="tile">
            <Iconography icon="moneyBill_1" size={IconographySizes.Medium} />
            <label>Price</label>
            <p>$ {convertToNoDecimalValue(serviceContractDetail.Price)}</p>
          </div>
          <div className="tile">
            <Iconography icon="circleInfo" size={IconographySizes.Medium} />
            <label>Part Number</label>
            <p>{serviceContractDetail.Sku}</p>
          </div>
          <div className="tile">
            <Iconography icon="calendar" size={IconographySizes.Medium} />
            <label>Type</label>
            <p>{serviceContractDetail.ProductType}</p>
          </div>
        </div>
        {false &&
        serviceContractDetail.SubscriptionId &&
        serviceContractDetail.SubscriptionId.length > 0 ? (
          <>
            <Divider mode={DividerVariants.Content} />
            <div className="subscription-toggle">
              <div className="left">
                <div className="message">
                  Enable or Disable this subscription if you wish to pause.
                </div>
                <div className="start-date">
                  Subscription Started:
                  {" " +
                    moment(serviceContractDetail.ContractStartDate).format(
                      "DD-MM-YYYY"
                    )}
                </div>
              </div>
              <div className="right">
                <Toggle
                  id="subscription-toggle"
                  labelON="Enabled"
                  labelOFF="Disabled"
                  onClick={handleSubscriptionToggle}
                  toggled={subscriptionToggled}
                  disabled={disabled}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default ServiceContractDetailModal;

import { call, delay, put, select } from "redux-saga/effects";
import {
  fetchCartIDSagaActions,
  fetchCartSagaActions,
  insertCartIDSagaActions,
} from "../saga-actions";
import {
  axiosGraphqlInstance,
  axiosRestInstance,
} from "../../../../common/axios";
import { cartSliceAction } from "../../../../redux/slices/cart";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router";
import { RootStateType } from "../../..";

type createCartApiResponseType = {
  data: {
    cartCreate: {
      cart: {
        id: string;
        checkoutUrl: string;
        buyerIdentity: {
          customer: {
            email: string;
          };
        };
        lines: {
          edges: {
            node: {
              id: string;
            };
          }[];
        };
      };
      userErrors: [];
    };
  };
};
const createCartAPI = (data: { lines: string[]; userToken: string }) => {
  // console.log("createCartAPI --> data", data?.lines.join(","));
  let response = axiosGraphqlInstance
    .post<AxiosResponse<createCartApiResponseType>>("/graphql.json", {
      query: `mutation {
            cartCreate(input: {lines: [${data?.lines.join(",")}] , 
            buyerIdentity: {customerAccessToken: "${data?.userToken}"}}) {
              cart {
                id
                checkoutUrl
                buyerIdentity {
                  customer {
                    email
                  }
                }
                lines(first: 10) {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              userErrors {
                        code
                        field
                        message
                        }
            }
          }
        `,
      variables: {},
    })
    .then((response) => response.data)
    .catch((error) => error);

  return response;
};

type addToCartApiResponseType = {
  data: {
    cartLinesAdd: {
      cart: {
        id: string;
        checkoutUrl: string;
        lines: {
          edges: {
            node: {
              id: string;
            };
          }[];
        };
        buyerIdentity: {
          customer: {
            email: string;
          };
        };
      };
      userErrors: {
        message: string;
        code: string;
      }[];
    };
  };
};
export const addToCartAPI = (data: { cartId: string; lines: string[] }) => {
  // lines: [${data?.lines.join(',')}],
  // console.log("addToCartAPI --> data", data?.lines.join(","));
  let response = axiosGraphqlInstance
    .post<AxiosResponse<addToCartApiResponseType>>("/graphql.json", {
      query: `mutation { 
            cartLinesAdd(cartId: "gid://shopify/Cart/${data?.cartId}", 
            lines: [${data?.lines.join(",")}], 
          ) { 
              cart { 
                id 
                checkoutUrl 
                lines(first: 10) { 
                  edges { 
                    node { 
                      id  
                    } 
                  }      
                }        
                buyerIdentity { 
                  customer { 
                    email 
                  } 
                } 
              } 
              userErrors { 
                message 
                code
              } 
            } 
          }
            `,
      variables: {},
    })
    .then((response) => response.data)
    .catch((error) => error);
  return response;
};

type insertCartIDAPIResponseType = {
  success: boolean;
  message: string;
  data: any;
};
const insertCartIDAPI = (cartID: string) => {
  let user_emailId = localStorage.getItem("user-emailId");
  let body = {
    useremail: user_emailId,
    cartId: cartID,
  };
  let response = axiosRestInstance
    .post<AxiosResponse<insertCartIDAPIResponseType>>(
      "/usercart/insertcart",
      body
    )
    .then((res) => res.data);
  return response;
};

// API above - SAGA below

type cartSagaActionPayloadType = {
  pdp: {
    productVariantId: string;
    featureSectionTitleAndVariantIdArray: {
      variantId: string;
      title: string;
    }[];
  }; // as same as action payload type!!
  cartId: string | null;
  userToken: string | null;
  navigateFunction: () => void;
};
function* cartCreateOrUpdateCart(actions: {
  type: string;
  payload: cartSagaActionPayloadType;
}) {
  try {
    yield put(
      cartSliceAction.setAddedProductID(actions.payload.pdp.productVariantId)
    );
    yield put(cartSliceAction.setAddToCartLoading(true));
    const attributes: { key: string; value: string }[] =
      actions?.payload?.pdp?.featureSectionTitleAndVariantIdArray?.map(
        (element) => {
          return {
            key: element?.variantId?.split("/")[
              element?.variantId?.split("/").length - 1
            ],
            value: element?.title,
          };
        }
      );
    const objectToStringConverterForLines = ({
      quantity,
      merchandiseId,
      attributes,
    }: {
      quantity: number;
      merchandiseId: string;
      attributes: string | null;
    }) => {
      if (attributes === null) {
        return `{
                quantity : ${quantity},
                merchandiseId : "${merchandiseId}"
            }`;
      } else {
        return `{
                quantity : ${quantity},
                merchandiseId : "${merchandiseId}",
                attributes : [${attributes}]
            }`;
      }
    };

    const objectToStringConverterForAttributes = ({
      key,
      value,
    }: {
      key: string;
      value: string;
    }) => {
      return `{
                key: "${key}",
                value: "${value}"
            }`;
    };
    const lines: string[] = [
      objectToStringConverterForLines({
        quantity: 1,
        merchandiseId: `${actions?.payload?.pdp?.productVariantId}`,
        attributes: attributes
          .map((element) =>
            objectToStringConverterForAttributes({
              key: "_" + element.key,
              value: element.value,
            })
          )
          .join(","),
      }),
    ];
    // attributes.forEach((element) => {
    //   lines.push(objectToStringConverterForLines({ quantity: 1, merchandiseId: `gid://shopify/ProductVariant/${element?.key}`, attributes: null }))
    // })
    if (actions?.payload?.cartId) {
      // update existing cart - API call
      let responseAdd: addToCartApiResponseType = yield call(addToCartAPI, {
        cartId: actions?.payload?.cartId,
        lines: lines,
      });
      yield put(insertCartIDSagaActions(actions?.payload?.cartId));
      yield put(fetchCartSagaActions(actions?.payload?.cartId));
      //console.log("response --> update/cart", responseAdd);
    } else {
      // create new cart - API call!!
      let response: createCartApiResponseType = yield call(createCartAPI, {
        userToken: actions?.payload?.userToken,
        lines: lines,
      });
      let cartFullIdSplited = response?.data?.cartCreate?.cart?.id?.split("/");
      let cartId = cartFullIdSplited[cartFullIdSplited.length - 1];
      yield put(insertCartIDSagaActions(cartId));
      yield put(fetchCartSagaActions(cartId));
      localStorage.setItem("cartId", cartId);
      // console.log("response --> create/cart", response);
    }
    yield put(cartSliceAction.setAddToCartLoading(false));
    yield put(cartSliceAction.setAddedProductID(""));
    yield put(
      cartSliceAction.setAddToCartMessage({
        success: true,
        message: "Item added to your cart!",
      })
    );
  } catch (e) {
    yield put(cartSliceAction.setAddToCartLoading(false));
    yield put(cartSliceAction.setAddedProductID(""));
    yield put(
      cartSliceAction.setAddToCartMessage({
        success: false,
        message: "Something went wrong. Please try again.",
      })
    );
    yield put(
      cartSliceAction.errorInfoTrigger({
        severity: "error",
        errorMsg: "Technical Error!",
        open: true,
      })
    );
  }
}

type cartSubscriptionSagaActionPayloadType = {
  pdp: {
    productVariantId: string;
    sellingPlanId: string;
  };
  cartId: string | null;
  userToken: string | null;
  navigateFunction: () => void;
};
export function* cartCreateOrUpdateCartSubscription(actions: {
  type: string;
  payload: cartSubscriptionSagaActionPayloadType;
}) {
  try {
    yield put(
      cartSliceAction.setAddedProductID(actions.payload.pdp.productVariantId)
    );
    yield put(cartSliceAction.setAddToCartLoading(true));
    const objectToStringConverterForLines = ({
      quantity,
      merchandiseId,
      sellingPlanId,
    }: {
      quantity: number;
      merchandiseId: string;
      sellingPlanId: string;
    }) => {
      return `{
        quantity : ${quantity},
        merchandiseId : "${merchandiseId}",
        sellingPlanId : "${sellingPlanId}"
      }`;
    };

    const lines: string[] = [
      objectToStringConverterForLines({
        quantity: 1,
        merchandiseId: actions?.payload?.pdp?.productVariantId,
        sellingPlanId: actions.payload.pdp.sellingPlanId,
      }),
    ];

    if (actions?.payload?.cartId) {
      // update existing cart - API call
      let responseAdd: addToCartApiResponseType = yield call(addToCartAPI, {
        cartId: actions?.payload?.cartId,
        lines: lines,
      });
      yield put(insertCartIDSagaActions(actions?.payload?.cartId));
      yield put(fetchCartSagaActions(actions?.payload?.cartId));
      yield call(actions?.payload?.navigateFunction);
    } else {
      // create new cart - API call!!
      let response: createCartApiResponseType = yield call(createCartAPI, {
        userToken: actions?.payload?.userToken,
        lines: lines,
      });
      let cartFullIdSplited = response?.data?.cartCreate?.cart?.id?.split("/");
      let cartId = cartFullIdSplited[cartFullIdSplited.length - 1];
      yield put(insertCartIDSagaActions(cartId));
      yield put(fetchCartSagaActions(cartId));
      localStorage.setItem("cartId", cartId);
      yield call(actions?.payload?.navigateFunction);
    }
    yield put(cartSliceAction.setAddToCartLoading(false));
    yield put(cartSliceAction.setAddedProductID(""));
    yield put(
      cartSliceAction.setAddToCartMessage({
        success: true,
        message: "Item added to your cart!",
      })
    );
  } catch (e) {
    yield put(cartSliceAction.setAddToCartLoading(false));
    yield put(cartSliceAction.setAddedProductID(""));
    yield put(
      cartSliceAction.setAddToCartMessage({
        success: false,
        message: "Something went wrong. Please try again.",
      })
    );
    yield put(
      cartSliceAction.errorInfoTrigger({
        severity: "error",
        errorMsg: "Technical Error!",
        open: true,
      })
    );
  }
}

export function* insertCartIDSaga(actions: {
  type: string;
  payload: {
    cartId: string;
  };
}) {
  try {
    let response: insertCartIDAPIResponseType = yield call(
      insertCartIDAPI,
      actions.payload.cartId
    );
    if (actions.payload.cartId === "") {
      yield put(fetchCartIDSagaActions());
    }
  } catch (e) {
    console.log(e);
  }
}

type buyNowSagaActionPayloadType = {
  pdp: {
    productVariantId: string;
    featureSectionTitleAndVariantIdArray: {
      variantId: string;
      title: string;
    }[];
  };
  userToken: string | null;
};
export function* buyNowSaga(actions: {
  type: string;
  payload: buyNowSagaActionPayloadType;
}) {
  try {
    yield put(cartSliceAction.setBuyNowLoading(true));
    const attributes: { key: string; value: string }[] =
      actions?.payload?.pdp?.featureSectionTitleAndVariantIdArray?.map(
        (element) => {
          return {
            key: element?.variantId?.split("/")[
              element?.variantId?.split("/").length - 1
            ],
            value: element?.title,
          };
        }
      );
    const objectToStringConverterForLines = ({
      quantity,
      merchandiseId,
      attributes,
    }: {
      quantity: number;
      merchandiseId: string;
      attributes: string | null;
    }) => {
      if (attributes === null) {
        return `{
                quantity : ${quantity},
                merchandiseId : "${merchandiseId}"
            }`;
      } else {
        return `{
                quantity : ${quantity},
                merchandiseId : "${merchandiseId}",
                attributes : [${attributes}]
            }`;
      }
    };

    const objectToStringConverterForAttributes = ({
      key,
      value,
    }: {
      key: string;
      value: string;
    }) => {
      return `{
                key: "${key}",
                value: "${value}"
            }`;
    };
    const lines: string[] = [
      objectToStringConverterForLines({
        quantity: 1,
        merchandiseId: `${actions?.payload?.pdp?.productVariantId}`,
        attributes: attributes
          .map((element) =>
            objectToStringConverterForAttributes({
              key: "_" + element.key,
              value: element.value,
            })
          )
          .join(","),
      }),
    ];
    attributes.forEach((element) => {
      lines.push(
        objectToStringConverterForLines({
          quantity: 1,
          merchandiseId: `gid://shopify/ProductVariant/${element?.key}`,
          attributes: null,
        })
      );
    });
    let response: createCartApiResponseType = yield call(createCartAPI, {
      userToken: actions?.payload?.userToken,
      lines: lines,
    });
    let checkoutUrl = response?.data?.cartCreate?.cart?.checkoutUrl;
    yield put(cartSliceAction.setBuyNowLoading(false));
    window.open(checkoutUrl, "_self");
  } catch (e) {
    yield put(cartSliceAction.setBuyNowLoading(false));
    yield put(
      cartSliceAction.errorInfoTrigger({
        severity: "error",
        errorMsg: "Technical Error!",
        open: true,
      })
    );
  }
}

export default cartCreateOrUpdateCart;

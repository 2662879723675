import { call, put, select } from "redux-saga/effects"
import { cartSagaActionTypes, fetchCartSagaActions } from "../saga-actions"
import { cartSliceAction } from '../../../../redux/slices/cart'
import { axiosGraphqlInstance, axiosRestInstance } from "../../../../common/axios"
import { AxiosResponse } from "axios"

type fetchCartApiResponseType = {
  data: {
    cart: {
      id: string | null,
      checkoutUrl: string | null,
      lines: {
          edges: {
              node: {
                id: string | null,
                quantity: number | null,
                attributes: {
                  key: string | null,
                  value: string | null
                }[] | [],
                merchandise: {
                  id: string | null,
                  price: {
                      amount: string | null
                  },
                  product: {
                    id: string | null,
                    title: string | null,
                    productType: string | null,
                    metafield: {
                        value: string | null
                    },
                    featuredImage: {
                        url: string | null
                    }
                  }
                },
                sellingPlanAllocation: {
                  sellingPlan: {
                    id: string | null,
                    name: string | null
                  }
                }
              }
          }[]
      },
      cost?: {
          totalAmount: {
              amount: string | null
          },
          subtotalAmount: {
              amount: string | null
          }
      },
      buyerIdentity?: {
          customer: {
              id: string | null,
              email: string | null,
              phone: string | null
          }
      }
    }
  }
}

const fetchCartAPI = async (cartId: string) => {
    let response = await axiosGraphqlInstance.post<AxiosResponse<fetchCartApiResponseType>>('/graphql.json',
      {
        query: `{ 
          cart(id: "gid://shopify/Cart/${cartId}") { 
            id 
            checkoutUrl 
            lines(first: 100) { 
              edges { 
                node { 
                  id 
                  quantity 
                  attributes { 
                    key 
                    value 
                  }         
                  merchandise { 
                    ... on ProductVariant { 
                      id 
                      price{ 
                        amount 
                      } 
                      product { 
                        id 
                        title 
                        productType 
                        metafield(namespace: "custom", key: "validation_date") { 
                          value 
                        } 
                        featuredImage { 
                          url 
                        } 
                      }
                    } 
                  }
                  sellingPlanAllocation {
                    sellingPlan {
                      id
                      name
                    }
                  }
                } 
              } 
            } 
            cost{ 
              totalAmount{ 
                amount 
              } 
              subtotalAmount{ 
                amount 
              } 
            } 
            buyerIdentity { 
              customer { 
                id 
                email 
                phone 
              } 
            } 
          } 
        }`,

            variables: {

            }
        }
    )
        .then((response) => response.data)
        .catch((error) => error)
    return response
}

type fetchCartIDApiResponseType = {
    "success": boolean,
    "message": string,
    "data": {
      "useremail": string,
      "cartId": string
    }[]
}

const fetchCartIDAPI = () => {
  let user_emailId = localStorage.getItem("user-emailId");
  let response = axiosRestInstance.get<AxiosResponse<fetchCartIDApiResponseType>>(`/usercart/getcart/${user_emailId}`)
      .then((res) => res.data);
  return response;
}

// let customerData = response.data.data.cart.buyerIdentity.customer.email
// const checkoutURLHit = response.data.data.cart.checkoutUrl + `?checkout[email]=${customerData}`
// localStorage.setItem('checkout-redirect-url', checkoutURLHit);
function* fetchCart(actions: {
    type: string,
    payload: {
        cartId: string
    }
}) {
    try {
        yield put(cartSliceAction.setIsLoading(true));
        let response: fetchCartApiResponseType = yield call(fetchCartAPI, actions?.payload?.cartId);
        let customerEmail : string = response?.data?.cart?.buyerIdentity?.customer?.email;
        let checkoutURL : string = `${response?.data?.cart?.checkoutUrl}?checkout[email]=${customerEmail}`;
        yield put(cartSliceAction.setCartItems(response?.data?.cart));
        yield put(cartSliceAction.setCheckoutURL(checkoutURL));
        yield put(cartSliceAction.setIsLoading(false));
        // let cartItemsToStore : any[] = [];
        // let serviceItems : any[] = [];
        // let componentItems : any[] = [];

    } catch (e) {
        yield put(cartSliceAction.setIsLoading(false));
        yield put(cartSliceAction.errorInfoTrigger({
            severity: "error",
            errorMsg: "Technical Error!",
            open: true
        }))
    }
}

export function* fetchCartIDSaga(actions: {
  type: string,
}) {
  try {
      let response: fetchCartIDApiResponseType = yield call(fetchCartIDAPI);
      if (response.success && response.data.length > 0 && response.data[0].cartId !== "") {
        yield put(fetchCartSagaActions(response.data[0].cartId));
        localStorage.setItem('cartId', response.data[0].cartId);
      } else {
        localStorage.removeItem('cartId');
      }
  } catch (e) {
      console.log(e)
  }
}


export default fetchCart;
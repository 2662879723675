import { FC, useState, useEffect } from "react";
import FileImage from "./FileImage";
import Document from "./Document";
import Page from "./Page";
import View from "./View";
import Text from "./Text";
import { Font } from "@react-pdf/renderer";
import format from "date-fns/format";
import hidLogo from "../../../../common/assets/images/hid_logo.png";
import convertToTwoDecimalValue from "../../../utils/convertToTwoDecimalValue";

Font.register({
  family: "Nunito",
  fonts: [
    { src: "https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf" },
    {
      src: "https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf",
      fontWeight: 600,
    },
  ],
});

interface Props {
  pdfMode?: boolean;
  orderDetail?: {
    id?: string | null;
    name?: string | null;
    processedAt?: string | null;
    shippingAddress?: {
      firstName: string | null;
      lastName: string | null;
      address1: string | null;
      address2: string | null;
      zip: string | null;
      phone: string | null;
    };
    billingAddress?: {
      firstName: string | null;
      lastName: string | null;
      company: string | null;
      address1: string | null;
      address2: string | null;
      zip: string | null;
      phone: string | null;
      city: string | null;
      country: string | null;
    };
    orderNumber?: number;
    fulfillmentStatus?: string | null;
    financialStatus?: string | null;
    subtotalPrice?: {
      amount: string | null;
      currencyCode: string | null;
    };
    totalShippingPrice?: {
      amount: string | null;
      currencyCode: string | null;
    };
    totalTax?: {
      amount: string | null;
      currencyCode: string | null;
    };
    totalPrice?: {
      amount: string | null;
      currencyCode: string | null;
    };
    lineItems?: {
      nodes: {
        title: string | null;
        quantity: number;
        variant: {
          sku: string | null;
          image: string | null;
          product: {
            productType: string | null;
          };
        };
        originalTotalPrice: {
          amount: string | null;
          currencyCode: string | null;
        };
      }[];
    };
  };
}

const InvoicePage: FC<Props> = ({ pdfMode, orderDetail }) => {
  const checkNullString = (value: string | null): string => {
    if (value) {
      return value;
    }
    return "";
  };

  return (
    <>
      <Document pdfMode={pdfMode}>
        <Page className="invoice-wrapper" pdfMode={pdfMode}>
          <View className="flex border-bottom-dark p-5" pdfMode={pdfMode}>
            <View className="w-45" pdfMode={pdfMode}>
              <FileImage
                className="logo"
                placeholder="Your Logo"
                value={hidLogo}
                width={60}
                pdfMode={pdfMode}
              />
            </View>
            <View className="w-55" pdfMode={pdfMode}>
              <Text className="fs-30 right bold dark" pdfMode={pdfMode}>
                INVOICE
              </Text>
              <Text className="fs-20 right bold dark" pdfMode={pdfMode}>
                Origo Tech Partners
              </Text>
              <Text className="fs-12 right" pdfMode={pdfMode}>
                4811 Airport Plaza Drive, Long Beach, California, United States
              </Text>
              <Text className="fs-12 right" pdfMode={pdfMode}>
                {process.env.REACT_APP_SHOPIFY_AXIOS_BASEURL.split("/")[2]}
              </Text>
            </View>
          </View>

          <View className="flex mt-20" pdfMode={pdfMode}>
            <View className="w-55" pdfMode={pdfMode}>
              <Text className="bold dark mb-5" pdfMode={pdfMode}>
                SHIPPING ADDRESS
              </Text>
              <Text
                className="fs-12 dark"
                pdfMode={pdfMode}
              >{`${checkNullString(
                orderDetail.billingAddress?.firstName
              )} ${checkNullString(
                orderDetail.billingAddress?.lastName
              )}`}</Text>
              <Text className="fs-12 dark" pdfMode={pdfMode}>
                {checkNullString(orderDetail.billingAddress?.company)}
              </Text>
              <Text
                className="fs-12 dark"
                pdfMode={pdfMode}
              >{`${checkNullString(
                orderDetail.billingAddress?.address1
              )}, ${checkNullString(
                orderDetail.billingAddress?.address2
              )}`}</Text>
              <Text
                className="fs-12 dark"
                pdfMode={pdfMode}
              >{`${checkNullString(
                orderDetail.billingAddress?.zip
              )}, ${checkNullString(
                orderDetail.billingAddress?.city
              )}, ${checkNullString(
                orderDetail.billingAddress?.country
              )}`}</Text>
            </View>
            <View className="w-45" pdfMode={pdfMode}>
              <View className="flex justify-end" pdfMode={pdfMode}>
                <Text className="fs-12 dark w-auto pr-2" pdfMode={pdfMode}>
                  {"Amount:"}
                </Text>
                <Text className="fs-12 dark bold w-auto" pdfMode={pdfMode}>
                  {`$${convertToTwoDecimalValue(
                    orderDetail.totalPrice?.amount
                  )}`}
                </Text>
              </View>
              <View className="flex justify-end" pdfMode={pdfMode}>
                <Text className="fs-12 dark w-auto pr-2" pdfMode={pdfMode}>
                  {"Invoice Number:"}
                </Text>
                <Text className="fs-12 dark bold w-auto" pdfMode={pdfMode}>
                  {orderDetail.name}
                </Text>
              </View>
              <View className="flex justify-end" pdfMode={pdfMode}>
                <Text className="fs-12 dark w-auto pr-2" pdfMode={pdfMode}>
                  {"Order Date:"}
                </Text>
                <Text className="fs-12 dark bold w-auto" pdfMode={pdfMode}>
                  {orderDetail.processedAt?.split("T")[0]}
                </Text>
              </View>
            </View>
          </View>

          <View className="mt-30 bg-dark flex" pdfMode={pdfMode}>
            <View className="w-60 p-4-8" pdfMode={pdfMode}>
              <Text className="white bold" pdfMode={pdfMode}>
                Items
              </Text>
            </View>
            <View className="w-20 p-4-8" pdfMode={pdfMode}>
              <Text className="white bold right" pdfMode={pdfMode}>
                Qty
              </Text>
            </View>
            <View className="w-20 p-4-8" pdfMode={pdfMode}>
              <Text className="white bold right" pdfMode={pdfMode}>
                Price
              </Text>
            </View>
          </View>

          {orderDetail.lineItems?.nodes?.map((lineItem, index) => {
            return (
              <View key={index} className="row flex" pdfMode={pdfMode}>
                <View className="w-60 p-4-8 pb-10" pdfMode={pdfMode}>
                  <Text className="dark bold" pdfMode={pdfMode}>
                    {lineItem.title}
                  </Text>
                  <Text className="fs-12" pdfMode={pdfMode}>
                    {`SKU: ${lineItem.variant.sku}`}
                  </Text>
                </View>
                <View className="w-20 p-4-8" pdfMode={pdfMode}>
                  <Text
                    className="dark right mt-10"
                    pdfMode={pdfMode}
                  >{`${lineItem.quantity}`}</Text>
                </View>
                <View className="w-20 p-4-8" pdfMode={pdfMode}>
                  <Text
                    className="dark right mt-10"
                    pdfMode={pdfMode}
                  >{`$${convertToTwoDecimalValue(
                    lineItem.originalTotalPrice?.amount
                  )}`}</Text>
                </View>
              </View>
            );
          })}

          <View className="flex justify-end" pdfMode={pdfMode}>
            <View className="mt-20 w-50" pdfMode={pdfMode}>
              <View className="flex border-bottom-dark" pdfMode={pdfMode}>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="bold dark" pdfMode={pdfMode}>
                    Subtotal
                  </Text>
                </View>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="right" pdfMode={pdfMode}>
                    {`$${convertToTwoDecimalValue(
                      orderDetail.subtotalPrice?.amount
                    )}`}
                  </Text>
                </View>
              </View>
              <View className="flex border-bottom-dark" pdfMode={pdfMode}>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="bold dark" pdfMode={pdfMode}>
                    Shipping
                  </Text>
                </View>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="right" pdfMode={pdfMode}>
                    {`$${convertToTwoDecimalValue(
                      orderDetail.totalShippingPrice?.amount
                    )}`}
                  </Text>
                </View>
              </View>
              <View className="flex border-bottom-dark" pdfMode={pdfMode}>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="bold dark" pdfMode={pdfMode}>
                    Tax
                  </Text>
                </View>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="right" pdfMode={pdfMode}>
                    {`$${convertToTwoDecimalValue(
                      orderDetail.totalTax?.amount
                    )}`}
                  </Text>
                </View>
              </View>
              <View className="flex bg-gray" pdfMode={pdfMode}>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="bold dark" pdfMode={pdfMode}>
                    Grand total
                  </Text>
                </View>
                <View className="w-50 p-5" pdfMode={pdfMode}>
                  <Text className="bold right" pdfMode={pdfMode}>
                    {`$${convertToTwoDecimalValue(
                      orderDetail.totalPrice?.amount
                    )}`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default InvoicePage;

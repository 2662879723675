const CircleMinus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 11.375H8.25C7.90625 11.375 7.625 11.6562 7.625 12C7.625 12.3438 7.90625 12.625 8.25 12.625H15.75C16.0955 12.625 16.375 12.3455 16.375 12C16.375 11.6545 16.0938 11.375 15.75 11.375ZM12 2C6.47656 2 2 6.47656 2 12C2 17.5234 6.47656 22 12 22C17.5234 22 22 17.5234 22 12C22 6.47656 17.5234 2 12 2ZM12 20.75C7.17578 20.75 3.25 16.8242 3.25 12C3.25 7.17578 7.17578 3.25 12 3.25C16.8242 3.25 20.75 7.17578 20.75 12C20.75 16.8242 16.8242 20.75 12 20.75Z"
        fill="#D31245"
      />
    </svg>
  );
};

export default CircleMinus;
import React from "react";
import {
  Button,
  Iconography,
  IconographyThemes,
  Loader,
  LoaderSizes,
} from "@hid-galaxy-ui/galaxy-react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import "./MiniCart.scss";
import { deleteCartSagaActions } from "../../../redux/saga/cart/saga-actions";
import convertToNoDecimalValue from "../../utils/convertToNoDecimalValue";

const MiniCart = () => {
  const [clickCheckout, setClickCheckout] = React.useState(false);
  const { cartSize, cartItems, checkoutURL, isLoading } = useAppSelector(
    (state) => state.cart
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setClickCheckout(false);
  }, []);
  return (
    <div className="mini-cart-container">
      <h1 className="title">Cart</h1>
      {isLoading ? (
        <div
          style={{ padding: "32px", display: "flex", justifyContent: "center" }}
        >
          <Loader variant={LoaderSizes.large} />
        </div>
      ) : (
        <>
          <div className="gray-box">
            {cartSize === 0 || cartSize === undefined ? (
              <div className="empty-cart">Cart Empty</div>
            ) : (
              <>
                {cartItems.lines.edges.map((item: any, index: number) => {
                  return (
                    <div className="cart-card" key={item.node.id}>
                      <div className="img-wrapper">
                        <img
                          src={item.node.merchandise.product.featuredImage?.url}
                        />
                      </div>
                      <div className="info">
                        <p className="type">
                          {item.node.merchandise.product.productType}
                        </p>
                        <p className="title">
                          {item.node.merchandise.product.title}
                        </p>
                      </div>
                      <div className="qty">
                        <label>Qty</label>
                        <span>{item.node.quantity}</span>
                      </div>
                      <Button
                        variant="secondary"
                        icon={
                          <Iconography
                            icon="trashCan"
                            theme={IconographyThemes.Error}
                          />
                        }
                        onClick={() => {
                          dispatch(
                            deleteCartSagaActions({
                              attributesIdArray: [],
                              productCartLineId: item.node.id,
                              productVariantId:
                                item.node.merchandise.product.id,
                            })
                          );
                        }}
                      />
                    </div>
                  );
                })}
                <div className="total-price">
                  <label>Price</label>
                  <span>
                    ${" "}
                    {convertToNoDecimalValue(
                      cartItems.cost.totalAmount.amount
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
          <Button
            label={
              clickCheckout ? (
                <Loader variant={LoaderSizes.small} />
              ) : (
                "continue to payment"
              )
            }
            variant="primary"
            disabled={cartSize === 0 || cartSize === undefined ? true : false}
            onClick={() => {
              setClickCheckout(true);
              window.open(checkoutURL, "_self");
              setTimeout(() => {
                setClickCheckout(false);
              }, 2000);
            }}
          />
        </>
      )}
    </div>
  );
};

export default MiniCart;

import {createSlice} from "@reduxjs/toolkit"


type initialstateType = {
    cartItems : {
        id?: string | null,
        checkoutUrl?: string | null,
        lines?: {
            edges: {
                node: {
                    id: string | null,
                    quantity: number | null,
                    attributes: {
                            key: string | null,
                            value: string | null
                        }[] | [],
                    merchandise: {
                        id: string | null,
                        price: {
                            amount: string | null
                        },
                        product: {
                            id: string | null,
                            title: string | null,
                            productType: string | null,
                            metafield: {
                                value: string | null
                            },
                            featuredImage: {
                                url: string | null
                            }
                        }
                    },
                    sellingPlanAllocation: {
                        sellingPlan: {
                            id: string | null,
                            name: string | null
                        }
                    },
                }
            }[]
        },
        cost?: {
            totalAmount: {
                amount: string | null
            },
            subtotalAmount: {
                amount: string | null
            }
        },
        buyerIdentity?: {
            customer: {
                id: string | null,
                email: string | null,
                phone: string | null
            }
        }
    },
    cartSize : number,
    supportServiceInCart : boolean,
    buyNowLoading: boolean,
    isLoading : boolean,
    addToCartLoading: boolean,
    checkoutURL : string,
    addedProductID: string,
    addToCartMessage: {
        success: boolean;
        message: string;
    },
    errorInfo : {
        severity : string,
        errorMsg : string,
        open : boolean
    }
}

var initialstate : initialstateType = {
    cartItems : {},
    cartSize : 0,
    supportServiceInCart : false,
    buyNowLoading: false,
    isLoading : false,
    addToCartLoading: false,
    checkoutURL : "",
    addedProductID: "",
    addToCartMessage: {
        success: true,
        message: ""
    },
    errorInfo : {
        severity : "",
        errorMsg : "",
        open : false
    }
}

const cartSlice = createSlice({
    name : "cart",
    initialState : initialstate,
    reducers : {
        setCartItems : (state, action) => {
            state.cartItems = action.payload; 
            // state.cartSize = state.cartItems?.lines?.edges?.length;
            state.cartSize = state.cartItems?.lines?.edges?.length;
            state.supportServiceInCart = state.cartItems?.lines?.edges?.filter((element) => element?.node?.merchandise?.product?.productType === 'Support Service').length > 0;
        },
        setIsLoading : (state, action) => {
            state.isLoading = action.payload;
        },
        setAddToCartLoading : (state, action) => {
            state.addToCartLoading = action.payload;
        },
        setBuyNowLoading : (state, action) => {
            state.buyNowLoading = action.payload;
        },
        setCheckoutURL : (state, action) => {
            state.checkoutURL = action.payload;
        },
        setAddedProductID : (state, action) => {
            state.addedProductID = action.payload;
        },
        setAddToCartMessage : (state, action) => {
            state.addToCartMessage = action.payload;
        },
        errorInfoTrigger : (state, action) => {
           state.errorInfo = action.payload
        },
        reset : (state) => {
            state.cartItems = {}
            state.cartSize = 0
        }
    }
})

export const cartSliceReducer = cartSlice.reducer;
export const cartSliceAction = cartSlice.actions;
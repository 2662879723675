import { call, put, takeLatest, } from "redux-saga/effects"
import { authSagaActionTypes } from "./saga-actions"
import { authSliceAction } from '../../slices/auth'
import { axiosGraphqlInstance, axiosRestInstance } from "../../../common/axios"
import { AxiosResponse } from "axios"

type LoginDataResponseType = {
    data: {
        customerAccessTokenCreate: {
            customerAccessToken: { accessToken: string, expiresAt: string }
            customerUserErrors: []
        }
    }
}

const getAuthDetailAPI = async (userDetail: { email?: string, password?: string }) => {
    // console.log('====== getAuthDetailAPI ----', userDetail.email, userDetail.password)
    let response = await axiosGraphqlInstance.post<LoginDataResponseType | {}>('/graphql.json',
        {
            query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
        customerAccessTokenCreate(input : $input) {
          customerUserErrors {
            code
            field
            message
          }
          customerAccessToken {
            accessToken
            expiresAt
          }
        }
      }`,
            variables: {
                "input": {
                    "email": userDetail?.email,
                    "password": userDetail?.password
                }
            }
        }
    )
        .then((response) => {
            if (response.status === 200) {
                return response
                // localStorage.setItem('dataKey', JSON.stringify(data));
                // console.log('=====>getItem()', localStorage.getItem('dataKey'))
            }
        })
        .catch((error) => {
            throw error
        })
    // console.log("2022-10/products.json --> authSlice", response)
    return response.data
}

type CutomerRecoverResponseType = {
    data: {
        customerRecover: {
            customerUserErrors: [] | [{
                message: string;
            }]
        }
    },
    errors: [] | [{
        message: string;
    }]
}

const customerRecoverAPI = async (email: string) => {
    const query = `
        mutation CustomerRecover($email : String!) {
            customerRecover(email:$email) {
                customerUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `;
    let response = await axiosGraphqlInstance.post<CutomerRecoverResponseType | {}>('/graphql.json',
        {
            query,
            variables: {
                email
            }
        }
    )
        .then((response) => {
            if (response.status === 200) {
                return response
            }
        })
        .catch((error) => {
            throw error
        })
    return response.data
}

type CutomerResetAPIResponseType = {
    data: {
        customerReset: {
            customer: {
                email: string;
            };
            customerAccessToken: { 
                accessToken: string;
                expiresAt: string;
            };
            customerUserErrors: [] | [{
                message: string;
            }];
        }
    },
    errors: [] | [{
        message: string;
    }]
}

const customerResetAPI = async (variables: { id?: string, password?: string, resetToken?: string}) => {
    const query = `
        mutation customerReset($id: ID!, $input: CustomerResetInput!) {
            customerReset(id: $id, input: $input) {
                customer {
                    email
                }
                customerAccessToken {
                    accessToken
                    expiresAt
                }
                customerUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `;
    let response = await axiosGraphqlInstance.post<CutomerResetAPIResponseType | {}>('/graphql.json',
        {
            query,
            variables: {
                id: `gid://shopify/Customer/${variables.id}`,
                input: {
                    password: variables.password,
                    resetToken: variables.resetToken
                }
            }
        }
    )
        .then((response) => {
            if (response.status === 200) {
                return response
            }
        })
        .catch((error) => {
            throw error
        })
    return response.data
}

type GetUserRoleAPIResponseType = {
    success: boolean;
    message: string;
    data: string;
}

const getUserRoleAPI = async (email: string) => {
    let response = axiosRestInstance.get<AxiosResponse<GetUserRoleAPIResponseType>>(`/userrole/getuserRole/${email}`)
        .then((res) => res.data);
    return response;
  }

function* getLogin(actions: {
    type: string,
    payload: { email: string, password: string }
}) {
    try {;
        const response: LoginDataResponseType = yield call(getAuthDetailAPI, actions.payload);
        //const responseRole: GetUserRoleAPIResponseType = yield call(getUserRoleAPI, actions.payload.email);
        if (response.data.customerAccessTokenCreate.customerUserErrors.length === 0) {
            yield put(authSliceAction.setLoginDetails(response.data));
            //yield put(authSliceAction.setUserRole(responseRole.data));
            localStorage.setItem("user-token", response.data.customerAccessTokenCreate.customerAccessToken.accessToken);
            localStorage.setItem("user-token-expiry-date", response.data.customerAccessTokenCreate.customerAccessToken.expiresAt);
            localStorage.setItem("user-emailId", actions?.payload?.email);
            yield put(authSliceAction.errorInfoTrigger({
                severity: "",
                errorMsg: "",
                open: false
            }))
        } else {
            yield put(authSliceAction.errorInfoTrigger({
                severity: "error",
                errorMsg: "Invalid E-Mail or Password",
                open: true
            }))
        }
    } catch (e) {
        console.error('====>error from axios', e)
        yield put(authSliceAction.errorInfoTrigger({
            severity: "error",
            errorMsg: "Technical Error!",
            open: true
        }))
    }
}

function* customerRecoverSaga(actions: {
    type: string,
    payload: { email: string }
}) {
    try {;
        const response: CutomerRecoverResponseType = yield call(customerRecoverAPI, actions.payload.email);
        if (response.data.customerRecover?.customerUserErrors.length === 0) {
            const msg = `The password reset email has been sent to ${actions.payload.email}`;
            yield put(authSliceAction.setCustRecoverSuccessMsg(msg));
            yield put(authSliceAction.errorInfoTrigger({
                severity: "",
                errorMsg: "",
                open: true
            }))
        } else {
            const msg = response.data.customerRecover ? 
                response.data.customerRecover.customerUserErrors[0].message :
                response.errors[0].message;
                
            yield put(authSliceAction.errorInfoTrigger({
                severity: "error",
                errorMsg: msg,
                open: true
            }))
        }
    } catch (e) {
        console.error('====>error from axios', e)
        yield put(authSliceAction.errorInfoTrigger({
            severity: "error",
            errorMsg: "Technical Error!",
            open: true
        }))
    }
}

function* customerResetSaga(actions: {
    type: string,
    payload: { id: string, password: string, resetToken: string }
}) {
    try {
        const response: CutomerResetAPIResponseType = yield call(customerResetAPI, actions.payload);
        if (response.data.customerReset?.customerUserErrors.length === 0) {
            yield put(authSliceAction.setLoginDetails(response.data));
            localStorage.setItem("user-token", response.data.customerReset.customerAccessToken.accessToken);
            localStorage.setItem("user-token-expiry-date", response.data.customerReset.customerAccessToken.expiresAt);
            localStorage.setItem("user-emailId", response.data.customerReset.customer.email);
        } else {
            const msg = response.data.customerReset ? 
                response.data.customerReset.customerUserErrors[0].message :
                response.errors[0].message;
            yield put(authSliceAction.errorInfoTrigger({
                severity: "error",
                errorMsg: msg,
                open: true
            }))
        }
    } catch (e) {
        console.error('====>error from axios', e)
        yield put(authSliceAction.errorInfoTrigger({
            severity: "error",
            errorMsg: "Technical Error!",
            open: true
        }))
    }
}


function* getUserRoleSaga(actions: {
    type: string,
}) {
    try {
        yield put(authSliceAction.setUserRoleLoading(true));
        const email = localStorage.getItem("user-emailId");
        const responseRole: GetUserRoleAPIResponseType = yield call(getUserRoleAPI, email);
        yield put(authSliceAction.setUserRole(responseRole.data));       
    } catch (e) {
        console.error('====>error from axios', e)
        yield put(authSliceAction.errorInfoTrigger({
            severity: "error",
            errorMsg: "Technical Error!",
            open: true
        }))
    } finally {
        yield put(authSliceAction.setUserRoleLoading(false));
    }
}

function* authSaga() {
    yield takeLatest(authSagaActionTypes.callAuthSaga, getLogin);
    yield takeLatest(authSagaActionTypes.callGetUserRoleSaga, getUserRoleSaga);
    yield takeLatest(authSagaActionTypes.callCustomerRecoverSaga, customerRecoverSaga);
    yield takeLatest(authSagaActionTypes.callCustomerResetSaga, customerResetSaga);
}

export default authSaga;
import React from "react"
import { useAppSelector , useAppDispatch} from "../redux"
import { useNavigate, useLocation } from "react-router"
const useCheckAuthentication : () => Array<boolean | Object >  = () => {
    const { loginDetail } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const initialAuthState = localStorage.getItem("user-token") !== null && localStorage.getItem("user-token-expiry-date") !== null && new Date() <= new Date(localStorage.getItem("user-token-expiry-date"));
    const [authenticationStatus, setAuthenticationStatus] = React.useState<boolean>(initialAuthState);

    let authenticationConsoleArray = {
            authenticationStatus : authenticationStatus,
            userTokenByLocalStorage : localStorage.getItem("user-token"),
            userTokenExpiryDateByLocalStorage : localStorage.getItem("user-token-expiry-date"),
            expiryDate : new Date(localStorage.getItem("user-token-expiry-date")),
            currentDateIsGreater : new Date() > new Date(localStorage.getItem("user-token-expiry-date"))
        }
    const listenerFunctionForAuthenticationCheck : (event : PointerEvent) => void = (event) => {
        event.preventDefault();
        if(localStorage.getItem("user-token") === null || localStorage.getItem("user-token-expiry-date") === null ){
            localStorage.clear();
            setAuthenticationStatus(false);
            window.removeEventListener('pointerover', (e) => listenerFunctionForAuthenticationCheck(e));
        }else if(new Date() > new Date(localStorage.getItem("user-token-expiry-date"))){
            localStorage.clear();
            setAuthenticationStatus(false);
            window.removeEventListener('pointerover', (e) =>  listenerFunctionForAuthenticationCheck(e));
        }
        // console.log("active", window.addEventListener)
    }

    React.useEffect(() => {
        if(localStorage.getItem("user-token") === null || localStorage.getItem("user-token-expiry-date") === null ){
            localStorage.clear();
            setAuthenticationStatus(false);
            window.removeEventListener('pointerover', (e) => listenerFunctionForAuthenticationCheck(e))
        }else if(new Date() > new Date(localStorage.getItem("user-token-expiry-date"))){
            localStorage.clear();
            setAuthenticationStatus(false);
            window.removeEventListener('pointerover', (e) => listenerFunctionForAuthenticationCheck(e))
        }else {
            setAuthenticationStatus(true);
            window.addEventListener('pointerover',(e) => listenerFunctionForAuthenticationCheck(e))
            if(location.pathname === '/login'){
                navigate('/')
            }  
        }
       return window.removeEventListener('pointerover', (e) => listenerFunctionForAuthenticationCheck(e))
    }, [loginDetail])

    return [authenticationStatus, authenticationConsoleArray]
}

export default useCheckAuthentication;
import {createSlice} from "@reduxjs/toolkit"

type initialstateType = {
    loginDetail : {}[],
    userRole : string,
    custRecoverSuccessMsg : string,
    status : {
        userRoleLoading : boolean,
    }
    errorInfo : {
        severity : string,
        errorMsg : string,
        open : boolean
    }
}

var initialstate : initialstateType = {
    loginDetail : [],
    userRole : "",
    custRecoverSuccessMsg: "",
    status : {
        userRoleLoading: false
    }, 
    errorInfo : {
        severity : "",
        errorMsg : "",
        open : false
    }

}

const authSlice = createSlice({
    name : "auth",
    initialState : initialstate,
    reducers : {
        setLoginDetails : (state, action) => {
            state.loginDetail = action.payload
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload;
        },
        setUserRoleLoading: (state, action) => {
            state.status.userRoleLoading = action.payload;
        },
        setCustRecoverSuccessMsg: (state, action) => {
            state.custRecoverSuccessMsg = action.payload;
        },
        logOut : (state) => {
            state.loginDetail = [];
            state.userRole = "";
            localStorage.clear()
        },
        errorInfoTrigger : (state, action) => {
           state.errorInfo = action.payload
        },
        reset : (state) => {
            state.loginDetail = []
            state.userRole = "";
        }
        
    }
})

export const authReducer = authSlice.reducer;
export const authSliceAction = authSlice.actions;
import { Footer, FooterVariants } from "@hid-galaxy-ui/galaxy-react";
import "./HIDFooter.scss";

const HIDFooter = () => {
  return (
    <Footer
      mode={FooterVariants.Expanded}
      versionText="Version 1.1"
      primaryText="©2024 HID Global Corporation/ASSA ABLOY. All rights reserved."
      linkText={[
        {
          text: "Privacy Statement",
          link: "https://www.hidglobal.com/about/privacy",
        },
        {
          text: "Terms of Use",
          link: "https://www.hidglobal.com/about/terms-of-use",
        },
        {
          text: "Modern Slavery",
          link: "https://www.hidglobal.com/about/modern-slavery-statement",
        },
      ]}
    />
  );
};

export default HIDFooter;

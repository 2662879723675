import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchCustomerDetailSagaAction,
  getAppsForContractSagaAction,
  getDevelopersForContractSagaAction,
  myAccountSagaActionType,
} from "./saga-actions";
import { axiosGraphqlInstance, axiosRestInstance } from "../../../common/axios";
import { myAccountSliceAction } from "../../slices/my-account";
import { AxiosResponse } from "axios";
import { insertCartIDSagaActions } from "../cart/saga-actions";

type contractFindAllAPIResponceType = {
  Active: string;
  ContractEndDate: string;
  ContractId: string;
  ContractName: string;
  ContractStartDate: string;
  Created: string;
  LastUpdated: string;
  OnboardingStageId: string;
  PartnerId: string;
  PartnerManagerId: string;
  Status: string; // changed for Status: string date 14/04/2023 by yash
  UserId: string;
  Orderdate: string;
  OrderId: string;
  Collection: string;
  SubscriptionId: string;
  Price: string;
  Sku: string;
  SubscriptionStatus: string;
  ProductType: string;
}[];

const contractFindAllAPI = async (company: string) => {
  let user_emailId = localStorage.getItem("user-emailId");
  let response = axiosRestInstance
    .get<AxiosResponse<contractFindAllAPIResponceType>>(
      `/users/getcontractsforpartner/${company}`
    )
    .then((res) => res.data);
  return response;
};

// type getDevelopersForContractAPIResponceType = {
//   UserId: string;
//   UserName: string;
//   UserTypeId: string;
//   UserCompany: string;
//   UserAddress: string;
//   UserEMAILId: string;
//   Created: string;
//   LastUpdated: string;
//   Active: string;
//   ContractId: string;
//   DeveloperId: string;
// }[];
// const getDevelopersForContractAPI = (contractId: any) => {
//   let response = axiosRestInstance
//     .get<AxiosResponse<getDevelopersForContractAPIResponceType>>(
//       "/users/getdevelopersforcontract/" + contractId
//     )
//     .then((res) => res.data);
//   return response;
// };

// type addDevelopersToContractAPIResponceType = {
//   result: string;
// };
// const addDevelopersToContractAPI = (Developer: any) => {
//   let response = axiosRestInstance
//     .post<AxiosResponse<addDevelopersToContractAPIResponceType>>(
//       "/users/addDevelopertoContract",
//       Developer
//     )
//     .then((res) => res.data);
//   return response;
// };

// type getAppsForContractAPIResponseType = {
//   ApplicationId: string;
//   ApplicationName: string;
//   ValidationsRequested: string;
//   ValidationId: string;
//   Created: string;
//   LastUpdated: string;
//   Active: string;
// }[];
// const getAppsForContractAPI = (contractId: any) => {
//   let response = axiosRestInstance
//     .get<AxiosResponse<getAppsForContractAPIResponseType>>(
//       "/apps/find/" + contractId
//     )
//     .then((res) => res.data);
//   return response;
// };

// type addAppsToContractAPIResponseType = {
//   status: string;
//   result: string;
// };
// const addAppsToContractAPI = (Application: any) => {
//   let response = axiosRestInstance
//     .post<AxiosResponse<addAppsToContractAPIResponseType>>(
//       "/apps/create",
//       Application
//     )
//     .then((res) => res.data);
//   return response;
// };
type createContractAPIResponceType = {
  Active: string;
  ContractEndDate: string;
  ContractId: string;
  ContractName: string;
  ContractStartDate: string;
  Created: string;
  LastUpdated: string;
  OnboardingStageId: string;
  PartnerId: string;
  PartnerManagerId: string;
}[];

const createContractAPI = (orderId: string) => {
  let response = axiosRestInstance
    .get<AxiosResponse<createContractAPIResponceType>>(
      "/contracts/createContract/" + orderId
    )
    .then((res) => res.data);
  return response;
};

// type allOrdersAPIResponceType = {
//   "data": {
//       "customer": {
//           "id": string,
//           "displayName": string,
//           "orders": {
//               "edges":{
//                       "node": {
//                           "id": string,
//                           "name": string,
//                           "orderNumber": number,
//                           "processedAt": string,
//                           "lineItems": {
//                             "nodes":{
//                                     "variant": {
//                                         "image": {
//                                             "url": string | null
//                                         }
//                                     }
//                                 }[],
//                         }
//                           "totalPrice": {
//                               "amount": string
//                           }
//                       }
//                   }[]
//           }
//       }
//   }
// }

// const allOrdersFetchAPI = async (customerAccessToken: string) => {
//     let response = axiosGraphqlInstance.post<AxiosResponse<allOrdersAPIResponceType>>('/graphql.json', {
//         query: ` {
//             customer(customerAccessToken: "${customerAccessToken}") {
//               id
//               displayName
//               orders(first: 100) {
//                 edges {
//                   node {
//                     id
//                     name
//                     orderNumber
//                     processedAt
//                     lineItems(first: 100) {
//                       nodes {
//                         variant {
//                           image {
//                             url
//                           }
//                         }
//                       }
//                     }
//                     totalPrice {
//                         amount
//                       }
//                   }
//                 }
//               }
//             }
//           }
//           `,
//         variables: {

//         }
//     }).then((response) => response?.data)
//     return response;
// }

type allOrdersByCompanyAPIResponceType = {
  id: string;
  name: string;
  orderNumber: number;
  processedAt: string;
  customerName: string;
  customerEmail: string;
  lineItems: {
    id: string;
    name: string;
  }[];
  totalPrice: string;
}[];

const allOrdersByCompanyAPI = async (company: string) => {
  let response = axiosRestInstance
    .get<AxiosResponse<allOrdersByCompanyAPIResponceType>>(`/orders/${company}`)
    .then((res) => res.data);
  return response;
};

type orderDetailAPIResponce = {
  data: {
    node: {
      id?: string | null;
      name?: string | null;
      processedAt?: string | null;
      shippingAddress?: {
        firstName: string | null;
        lastName: string | null;
        address1: string | null;
        address2: string | null;
        zip: string | null;
        phone: string | null;
      };
      billingAddress?: {
        firstName: string | null;
        lastName: string | null;
        company: string | null;
        address1: string | null;
        address2: string | null;
        zip: string | null;
        phone: string | null;
        city: string | null;
        country: string | null;
      };
      orderNumber?: number;
      fulfillmentStatus?: string | null;
      financialStatus?: string | null;
      totalPrice?: {
        amount: string | null;
        currencyCode: string | null;
      };
      subtotalPrice?: {
        amount: string | null;
        currencyCode: string | null;
      };
      totalShippingPrice?: {
        amount: string | null;
        currencyCode: string | null;
      };
      totalTax?: {
        amount: string | null;
        currencyCode: string | null;
      };
      lineItems?: {
        nodes: {
          title: string | null;
          quantity: number;
          variant: {
            sku: string | null;
            image: string | null;
            product: {
              productType: string | null;
            };
            sellingPlanAllocations: {
              nodes: {
                sellingPlan: {
                  id: string | null;
                  name: string | null;
                };
              }[];
            };
          };
          originalTotalPrice: {
            amount: string | null;
            currencyCode: string | null;
          };
        }[];
      };
    };
  };
};

const orderDetailAPI = async (orderId: string) => {
  let response = axiosGraphqlInstance
    .post<AxiosResponse<orderDetailAPIResponce>>("/graphql.json", {
      query: ` {
            node(id: "${orderId}") {
              id
              ... on Order {
                id
                name
                processedAt
                shippingAddress {
                  firstName
                  lastName
                  address1
                  address2
                  zip
                  phone
                }
                billingAddress {
                  firstName
                  lastName
                  company
                  address1
                  address2
                  zip
                  phone
                  city
                  country
                }
                orderNumber
                fulfillmentStatus
                financialStatus
                subtotalPrice {
                  amount
                  currencyCode
                }
                totalShippingPrice {
                  amount
                  currencyCode
                }
                totalTax {
                  amount
                  currencyCode
                }
                totalPrice {
                  amount
                  currencyCode
                }
                lineItems(first: 100) {
                  nodes {
                    title
                    quantity
                    variant {
                      sku
                      image {
                        url
                      }
                      product {
                        productType
                      }
                      sellingPlanAllocations(first: 10) {
                        nodes {
                          sellingPlan {
                            id
                            name
                          }
                        }
                      }
                    }
                    originalTotalPrice {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
          
          `,
      variables: {},
    })
    .then((response) => response.data);
  return response;
};

type fetchCustomerDetailAPIResponceType = {
  data: {
    customer: {
      id: string;
      displayName: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      defaultAddress: {
        company: string;
      };
    };
  };
};

const fetchCustomerDetailAPI = async (customerAccessToken: string) => {
  let response = axiosGraphqlInstance
    .post<AxiosResponse<fetchCustomerDetailAPIResponceType>>("/graphql.json", {
      query: ` {
          customer(customerAccessToken: "${customerAccessToken}") {
            id
            displayName
            firstName
            lastName
            email
            phone
            defaultAddress {
              company
            }
          }
        }
        `,
      variables: {},
    })
    .then((response) => response?.data);
  return response;
}

type CustomerUpdatePayloadType = {
  id: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}
type updateCustomerAPIResponseType = {
  success?: boolean;
  message: string;
  result?: any;
}
const updateCustomerAPI = (customerUpdatePayload: CustomerUpdatePayloadType) => {
  let response = axiosRestInstance
    .post<AxiosResponse<updateCustomerAPIResponseType>>(
      "/api/update-customer",
      customerUpdatePayload
    )
    .then((res) => res.data);
  return response;
};

type boldSubscriptionAPIResponseType = {
  status: boolean;
  message: string;
  data?: string;
}
const activateBoldSubscriptionAPI = async (subscriptionId: string) => {
  let response = axiosRestInstance
    .post<AxiosResponse<boldSubscriptionAPIResponseType>>(`/api/subscription/activate/${subscriptionId}`)
    .then((res) => res.data);
  return response;
};
const pauseBoldSubscriptionAPI = async (subscriptionId: string) => {
  let response = axiosRestInstance
    .post<AxiosResponse<boldSubscriptionAPIResponseType>>(`/api/subscription/pause/${subscriptionId}`)
    .then((res) => res.data);
  return response;
};

// ---- ALL API ABOVE AND SAGA BELOW ----

function* contractFindAllSaga(actions: {
  type: string;
  payload: {
    company: string;
  };
}) {
  try {
    yield put(myAccountSliceAction.setContractsLoadingStatus(true));
    let response: contractFindAllAPIResponceType = yield call(
      contractFindAllAPI,
      actions.payload.company
    );
    response.sort(
      (a, b) =>
        new Date(b.Orderdate).getTime() - new Date(a.Orderdate).getTime()
    );
    yield put(myAccountSliceAction.setContracts(response));
    yield put(myAccountSliceAction.setContractsLoadingStatus(false));
  } catch (e) {
    yield put(myAccountSliceAction.setContractsLoadingStatus(false));
    console.log(e);
  }
}

function* fetchCustomerDetailSaga(actions: { type: string }) {
  try {
    yield put(myAccountSliceAction.setCustomerDetailLoadingStatus(true));
    let customerAccessToken = localStorage.getItem("user-token");
    const response: fetchCustomerDetailAPIResponceType = yield call(
      fetchCustomerDetailAPI,
      customerAccessToken
    );
    yield put(
      myAccountSliceAction.setCompany(
        response.data.customer.defaultAddress.company
      )
    );
    yield put(
      myAccountSliceAction.setCustomerDetail({
        id: response.data.customer.id,
        firstName: response.data.customer.firstName,
        lastName: response.data.customer.lastName,
        displayName: response.data.customer.displayName,
        email: response.data.customer.email,
        phone: response.data.customer.phone,
        company: response.data.customer.defaultAddress.company,
      })
    );
    yield put(myAccountSliceAction.setCustomerDetailLoadingStatus(false));
  } catch (e) {
    yield put(myAccountSliceAction.setCustomerDetailLoadingStatus(false));
    console.log(e);
  }
}

// function* getDevelopersForContractSaga(actions: {
//   type: string;
//   payload: {
//     contractId: string;
//   };
// }) {
//   try {
//     let response: getDevelopersForContractAPIResponceType = yield call(
//       getDevelopersForContractAPI,
//       actions.payload.contractId
//     );
//     yield put(myAccountSliceAction.setDevelopers(response.reverse()));
//     console.log("response --> developers", response);
//   } catch (e) {
//     console.log(e);
//   }
// }
// type developerPayloadType = {
//   UserId: string;
//   UserName: string;
//   UserTypeId: string;
//   UserCompany: string;
//   UserAddress: string;
//   UserEMAILId: string;
//   Active: string;
//   ContractId: string;
// };
// function* addDeveloperToContractSaga(actions: {
//   type: string;
//   payload: {
//     Developer: developerPayloadType;
//   };
// }) {
//   try {
//     console.log(actions.payload.Developer);
//     let response: addDevelopersToContractAPIResponceType = yield call(
//       addDevelopersToContractAPI,
//       actions.payload.Developer
//     );
//     console.log("response --> adddeveloper", response);
//     yield put(
//       getDevelopersForContractSagaAction(actions.payload.Developer.ContractId)
//     );
//   } catch (e) {
//     console.log(e);
//   }
// }

// function* getAppsForContractSaga(actions: {
//   type: string;
//   payload: {
//     contractId: string;
//   };
// }) {
//   try {
//     let response: getAppsForContractAPIResponseType = yield call(
//       getAppsForContractAPI,
//       actions.payload.contractId
//     );
//     yield put(myAccountSliceAction.setApplications(response.reverse()));
//     console.log("response --> apps", response);
//   } catch (e) {
//     console.log(e);
//   }
// }

// type applicationPayloadType = {
//   ApplicationId: string;
//   ApplicationName: string;
//   Active: string;
// };
// function* addAppsToContractSaga(actions: {
//   type: string;
//   payload: {
//     Application: applicationPayloadType;
//   };
// }) {
//   try {
//     console.log(actions.payload.Application);
//     let response: addAppsToContractAPIResponseType = yield call(
//       addAppsToContractAPI,
//       actions.payload.Application
//     );
//     console.log("response --> addapp", response);
//     yield put(
//       getAppsForContractSagaAction(actions.payload.Application.ApplicationId)
//     );
//   } catch (e) {
//     console.log(e);
//   }
// }

function* allOrdersSaga(actions: {
  type: string;
  payload: {
    company: string;
  };
}) {
  try {
    let customerAccessToken = localStorage.getItem("user-token");
    yield put(myAccountSliceAction.setOrderHistoryLoadingStatus(true));
    // let response: allOrdersAPIResponceType = yield call(allOrdersFetchAPI, customerAccessToken)
    let response: allOrdersByCompanyAPIResponceType = yield call(
      allOrdersByCompanyAPI,
      actions.payload.company
    );
    yield put(myAccountSliceAction.setAllOrders(response));
    yield put(myAccountSliceAction.setOrderHistoryLoadingStatus(false));
  } catch (e) {
    yield put(myAccountSliceAction.setOrderHistoryLoadingStatus(false));
    console.log(e);
  }
}

function* createContractSaga(actions: {
  type: string;
  payload: {
    orderId: string;
  };
}) {
  try {
    yield put(insertCartIDSagaActions(""));
    localStorage.removeItem("cartId");
    let response: createContractAPIResponceType = yield call(
      createContractAPI,
      actions.payload.orderId
    );
  } catch (e) {
    console.log(e);
  }
}

type sortedLineitemNodeType = {
  title: string;
  quantity: number;
  variant: {
    sku: string;
    image: string;
    product: {
      productType: string;
    };
    sellingPlanAllocations: {
      nodes: {
        sellingPlan: {
          id: string | null;
          name: string | null;
        };
      }[];
    };
  };
  originalTotalPrice: {
    amount: string;
    currencyCode: string;
  };
}[];
const arraySorter = (main_array: sortedLineitemNodeType) => {
  let sortedArray: sortedLineitemNodeType = [];
  main_array?.forEach((element, index) => {
    let ammount: string = element?.originalTotalPrice?.amount;
    if (ammount == "0.0") {
      sortedArray?.push(element);
    } else {
      sortedArray?.unshift(element);
    }
  });
  return sortedArray;
};
function* orderDetailSaga(actions: {
  type: string;
  payload: {
    orderId: string;
  };
}) {
  try {
    const getCustomFulfillmentStatus: (data: string) => string = (data) => {
      switch (data) {
        case "UNFULFILLED":
          return "Placed";

        case "ON_HOLD":
          return "ON HOLD";

        default:
          return "Completed";
      }
    };
    yield put(myAccountSliceAction.setOrderDetailLoadingStatus(true));
    let response: orderDetailAPIResponce = yield call(
      orderDetailAPI,
      actions.payload.orderId
    );
    let sortedArray: sortedLineitemNodeType = yield call(
      arraySorter,
      response?.data?.node?.lineItems?.nodes
    );
    let sortedResponce: orderDetailAPIResponce = {
      data: {
        node: {
          ...response?.data?.node,
          fulfillmentStatus: getCustomFulfillmentStatus(
            response?.data?.node?.fulfillmentStatus
          ),
          lineItems: { nodes: [...sortedArray] },
        },
      },
    };
    yield put(myAccountSliceAction.setOrderDetail(sortedResponce?.data?.node));
    yield put(myAccountSliceAction.setOrderDetailLoadingStatus(false));
  } catch (e) {
    console.log(e);
    yield put(myAccountSliceAction.setOrderDetailLoadingStatus(false));
  }
}

type orderDetailPDFSagaActionPayloadType = {
  orderId: string;
  generatePdfDocumentFunction: (
    orderDetailPdfData: orderDetailAPIResponce["data"]["node"]
  ) => void;
};
function* orderDetailPDFSaga(actions: {
  type: string;
  payload: orderDetailPDFSagaActionPayloadType;
}) {
  try {
    const getCustomFulfillmentStatus: (data: string) => string = (data) => {
      switch (data) {
        case "UNFULFILLED":
          return "Placed";

        case "ON_HOLD":
          return "ON HOLD";

        default:
          return "Completed";
      }
    };
    yield put(
      myAccountSliceAction.setOrderDetailPDFID(actions.payload.orderId)
    );
    yield put(myAccountSliceAction.setOrderDetailPDFLoadingStatus(true));
    let response: orderDetailAPIResponce = yield call(
      orderDetailAPI,
      actions.payload.orderId
    );
    let sortedArray: sortedLineitemNodeType = yield call(
      arraySorter,
      response?.data?.node?.lineItems?.nodes
    );
    let sortedResponce: orderDetailAPIResponce = {
      data: {
        node: {
          ...response?.data?.node,
          fulfillmentStatus: getCustomFulfillmentStatus(
            response?.data?.node?.fulfillmentStatus
          ),
          lineItems: { nodes: [...sortedArray] },
        },
      },
    };
    yield put(
      myAccountSliceAction.setOrderDetailPDF(sortedResponce?.data?.node)
    );
    yield put(myAccountSliceAction.setOrderDetailPDFLoadingStatus(false));
    yield call(
      actions?.payload?.generatePdfDocumentFunction,
      sortedResponce?.data?.node
    );
  } catch (e) {
    console.log(e);
    yield put(myAccountSliceAction.setOrderDetailPDFLoadingStatus(false));
  }
}

function* updateCustomerSaga(actions: {
  type: string;
  payload: CustomerUpdatePayloadType;
}) {
  try {
    yield put(myAccountSliceAction.setCustomerUpdateLoadingStatus(true));
    let response: updateCustomerAPIResponseType = yield call(
      updateCustomerAPI,
      actions.payload
    );
    yield put(fetchCustomerDetailSagaAction());
    yield put(myAccountSliceAction.setCustomerUpdateLoadingStatus(false));
  } catch (e) {
    yield put(myAccountSliceAction.setCustomerUpdateLoadingStatus(false));
    console.log(e);
  }
}

function* activateBoldSubscriptionSaga(actions: {
  type: string;
  payload: {
    subscriptionId: string;
  };
}) {
  try {
    yield put(myAccountSliceAction.setSubscriptionID(actions.payload.subscriptionId));
    yield put(myAccountSliceAction.setSubscriptionLoading(true));
    let response: boldSubscriptionAPIResponseType = yield call(
      activateBoldSubscriptionAPI,
      actions.payload.subscriptionId
    );
    yield put(myAccountSliceAction.setSubscriptionStatus(response.data));
    yield put(myAccountSliceAction.setSubscriptionLoading(false));
  } catch (e) {
    yield put(myAccountSliceAction.setSubscriptionStatus("paused"));
    yield put(myAccountSliceAction.setSubscriptionLoading(false));
    console.log(e);
  }
}

function* pauseBoldSubscriptionSaga(actions: {
  type: string;
  payload: {
    subscriptionId: string;
  };
}) {
  try {
    yield put(myAccountSliceAction.setSubscriptionID(actions.payload.subscriptionId));
    yield put(myAccountSliceAction.setSubscriptionLoading(true));
    let response: boldSubscriptionAPIResponseType = yield call(
      pauseBoldSubscriptionAPI,
      actions.payload.subscriptionId
    );
    yield put(myAccountSliceAction.setSubscriptionStatus(response.data));
    yield put(myAccountSliceAction.setSubscriptionLoading(false));
  } catch (e) {
    yield put(myAccountSliceAction.setSubscriptionStatus("active"));
    yield put(myAccountSliceAction.setSubscriptionLoading(false));
    console.log(e);
  }
}

function* myAccountSaga() {
  yield takeLatest(myAccountSagaActionType.callContractFindAllSaga,contractFindAllSaga);
  yield takeLatest(myAccountSagaActionType.callAllOrdersSaga, allOrdersSaga);
  yield takeLatest(myAccountSagaActionType.callOrderDetailSaga, orderDetailSaga);
  yield takeLatest(myAccountSagaActionType.callCreateContractSaga, createContractSaga);
  yield takeLatest(myAccountSagaActionType.callfetchCustomerDetailSaga, fetchCustomerDetailSaga);
  yield takeLatest(myAccountSagaActionType.callOrderDetailPDFSaga, orderDetailPDFSaga);
  yield takeLatest(myAccountSagaActionType.callUpdateCustomerSaga, updateCustomerSaga);
  yield takeLatest(myAccountSagaActionType.callActivateBoldSubscriptionSaga, activateBoldSubscriptionSaga);
  yield takeLatest(myAccountSagaActionType.callPauseBoldSubscriptionSaga, pauseBoldSubscriptionSaga);
}

export default myAccountSaga;

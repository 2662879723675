import React from "react";
import { useLocation, Outlet, useNavigate } from "react-router";
import HIDHeader from "../../common/components/HIDHeader/HIDHeader";
import HIDFooter from "../../common/components/HIDFooter/HIDFooter";

const PartnerLandingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;

  React.useEffect(() => {
    if (pathName === "/partner" || pathName === "/partner/")
      navigate("/partner/dashboard");
  }, [pathName]);

  return (
    <>
      <HIDHeader />
      <div style={{ minHeight: "calc(100vh - 60px)" }}>
        <Outlet />
      </div>
      <HIDFooter />
    </>
  );
};

export default PartnerLandingPage;

// creating actionTypes so that we do not have a mismatch of action type stings in saga!!
export const productDetailSagaActionTypes = {
    callGetProductDetailSaga : 'callGetProductDetailSaga',
    callGetProductDetailAndAddToCartSaga : 'callGetProductDetailAndAddToCartSaga',
}

// these are the saga acton that are need to be dispatched from components;
export const getProductDetailAction : (productID? : string) => {
    type: string,
    payload? : {
        productID : string,
    }
} = (productID) => {
    return {
        type : productDetailSagaActionTypes.callGetProductDetailSaga,
        payload :  {
            productID : productID 
        }
    }
}

export const getProductDetailAndAddToCartAction : (data : {productID: string, cartID: string, userToken: string}) => {
    type: string,
    payload? : {
        data : {
            productID : string,
            cartID: string | null,
            userToken: string | null,
        }
    }
} = (data) => {
    return {
        type : productDetailSagaActionTypes.callGetProductDetailAndAddToCartSaga,
        payload :  {
            data : data
        }
    }
}


import React from "react";
import { useLocation, Outlet, Navigate } from "react-router";
import HIDHeader from "../common/components/HIDHeader/HIDHeader";
import HIDFooter from "../common/components/HIDFooter/HIDFooter";
import { UiPopover } from "../common/components/CustomPopover";
import { useAppSelector } from "../redux";
import HIDAdminLandingPage from "./hid-admin";
import PartnerLandingPage from "./partner";

const LandingPage: React.FC<{}> = () => {
  const location = useLocation();
  const pathName = location.pathname.split("");
  const { userRole, status } = useAppSelector((state) => state.auth);

  // if (status.userRoleLoading || userRole === "") {
  //     return (
  //       <div
  //         style={{
  //           minHeight: "calc(100vh - 94px)",
  //           paddingTop: "100px",
  //           textAlign: "center",
  //         }}
  //       >
  //         Loading...
  //       </div>
  //     );
  // }

  // if (userRole === "HID_ADMIN") {
  //     return <HIDAdminLandingPage />
  // }

  return (
    <>
      {pathName.includes("/") && pathName.length === 1 ? (
        <Navigate to="/partner" />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default LandingPage;

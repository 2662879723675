import React from "react";
import SideNav from "@hid-galaxy-ui/galaxy-react/components/SideNav";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  Iconography,
  IconographySizes,
  Notification,
  NotificationVariants,
} from "@hid-galaxy-ui/galaxy-react";
import { cartSliceAction } from "../../../redux/slices/cart";
import "./PartnerDashboard.scss";

const PartnerDashboard = () => {
  const [toasts, setToasts] = React.useState([]);
  const { addToCartMessage } = useAppSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathName = location.pathname;
  const pathNameArray = pathName.split("/");

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
    return () => {
      dispatch(
        cartSliceAction.setAddToCartMessage({
          success: true,
          message: "",
        })
      );
    };
  }, []);

  React.useEffect(() => {
    if (pathName === "/partner/dashboard" || pathName === "/partner/dashboard/")
      navigate("home");
    const element = document.getElementById(
      pathNameArray[pathNameArray.length - 1]
    );
    element?.click();
  }, [pathName]);

  React.useEffect(() => {
    if (addToCartMessage.message.length > 0) {
      let content = {};
      if (addToCartMessage.success) {
        content = {
          id: Math.floor(Math.random() * 1000),
          title: "",
          message: addToCartMessage.message,
          variant: NotificationVariants.Success,
          icon: (
            <Iconography icon="circleCheck" size={IconographySizes.Medium} />
          ),
        };
      } else {
        content = {
          id: Math.floor(Math.random() * 1000),
          title: "",
          message: addToCartMessage.message,
          variant: NotificationVariants.Error,
          icon: <Iconography icon="ban" size={IconographySizes.Medium} />,
        };
      }
      setToasts([...toasts, content]);
    }
  }, [addToCartMessage]);

  const onNodeClick = (e: any, key: any) => {
    navigate(key);
  };

  return (
    <div className="partner-dashboard-container">
      <Notification toasts={toasts} setToasts={setToasts} />
      <SideNav
        id="sidenav"
        mode="Expanded"
        isToggle={false}
        selected={pathNameArray[pathNameArray.length - 1]}
        navItems={[
          {
            iconName: "home",
            key: "home",
            title: "Home",
          },
          {
            iconName: "cartShopping",
            key: "services",
            title: "Services",
          },
        ]}
        onNodeClick={(e, key) => onNodeClick(e, key)}
      />
      <div className="outlet-view">
        <Outlet />
      </div>
    </div>
  );
};

export default PartnerDashboard;

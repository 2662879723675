
export const myAccountSagaActionType = {
    callContractFindAllSaga: "callContractFindAllSaga",
    callAllOrdersSaga: "callAllOrdersSaga",
    callOrderDetailSaga: 'callOrderDetailSaga',
    callGetDevelopersForContractSaga: "callGetDevelopersForContractSaga",
    callAddDeveloperToContractSaga: "callAddDeveloperToContractSaga",
    callCreateContractSaga : 'callCreateContractSaga',
    callGetAppsForContractSaga: 'callGetAppsForContractSaga',
    callAddAppsToContractSaga: 'callAddAppsToContractSaga',
    callfetchCustomerDetailSaga: 'callfetchCustomerDetailSaga',
    callOrderDetailPDFSaga: 'callOrderDetailPDFSaga',
    callUpdateCustomerSaga: 'callUpdateCustomerSaga',
    callActivateBoldSubscriptionSaga: 'callActivateBoldSubscriptionSaga',
    callPauseBoldSubscriptionSaga: 'callPauseBoldSubscriptionSaga'
}


export const contractFindAllSagaAction : (company: string) => {
    type: string,
    payload : {
        company : string
    }
} = (company) => {
    return {
        type: myAccountSagaActionType.callContractFindAllSaga,
        payload : {
            company : company
        }
    }
}

export const allOrdersSagaAction: (company: string) => {
    type: string,
    payload : {
        company : string
    }
} = (company) => {
    return {
        type: myAccountSagaActionType.callAllOrdersSaga,
        payload : {
            company : company
        }
    }
}

export const fetchCustomerDetailSagaAction: () => {
    type: string,
} = () => {
    return {
        type: myAccountSagaActionType.callfetchCustomerDetailSaga,
    }
}

export const createContractSagaAction : (orderId : string) => {
    type: string,
    payload : {
        orderId : string
    }
} = (orderId) => {
    return {
        type : myAccountSagaActionType.callCreateContractSaga,
        payload : {
            orderId : orderId
        }
    }
}

export const orderDetailSagaAction : (orderId : string) => {
    type: string,
    payload: {
        orderId: string
    }
} = (orderId) => {
    return {
        type: myAccountSagaActionType.callOrderDetailSaga,
        payload: {
            orderId: orderId
        }
    }
}

type orderDetailPdfDataType = {
    id?: string | null;
    name?: string | null;
    processedAt?: string | null;
    shippingAddress?: {
        firstName: string | null;
        lastName: string | null;
        address1: string | null;
        address2: string | null;
        zip: string | null;
        phone: string | null;
    };
    billingAddress?: {
        firstName: string | null;
        lastName: string | null;
        company: string | null;
        address1: string | null;
        address2: string | null;
        zip: string | null;
        phone: string | null;
        city: string | null;
        country: string | null;
    };
    orderNumber?: number;
    fulfillmentStatus?: string | null;
    financialStatus?: string | null;
    subtotalPrice?: {
        amount: string | null;
        currencyCode: string | null;
    };
    totalShippingPrice?: {
        amount: string | null;
        currencyCode: string | null;
    };
    totalTax?: {
        amount: string | null;
        currencyCode: string | null;
    };
    totalPrice?: {
        amount: string | null;
        currencyCode: string | null;
    };
    lineItems?: {
        nodes: {
        title: string | null;
        quantity: number;
        variant: {
            sku: string | null;
            image: string | null;
            product: {
                productType: string | null;
            };
            sellingPlanAllocations: {
                nodes: 
                    {
                        sellingPlan: {
                            id: string | null,
                            name: string | null,
                        }
                    }[];
            };
        };
        originalTotalPrice: {
            amount: string | null;
            currencyCode: string | null;
        };
        }[];
    };
}
type orderDetailPDFSagaActionPayloadType = {
    orderId: string,
    generatePdfDocumentFunction : (orderDetailPdfData: orderDetailPdfDataType) => void
}
export const orderDetailPDFSagaAction : (data : orderDetailPDFSagaActionPayloadType) => {
    type: string,
    payload: orderDetailPDFSagaActionPayloadType
} = (data) => {
    return {
        type: myAccountSagaActionType.callOrderDetailPDFSaga,
        payload: data
    }
}

type CustomerUpdatePayloadType = {
    id: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
}
export const updateCustomerSagaAction : (customerUpdatePayload : CustomerUpdatePayloadType) => {
    type: string,
    payload : {
        customerUpdatePayload : CustomerUpdatePayloadType
    }
} = (customerUpdatePayload) => {
    return {
        type : myAccountSagaActionType.callUpdateCustomerSaga,
        payload : {
            customerUpdatePayload : customerUpdatePayload
        }
    }
}

export const activateBoldSubscriptionSagaAction: (subscriptionId: string) => {
    type: string,
    payload : {
        subscriptionId : string
    }
} = (subscriptionId) => {
    return {
        type: myAccountSagaActionType.callActivateBoldSubscriptionSaga,
        payload : {
            subscriptionId : subscriptionId
        }
    }
}

export const pauseBoldSubscriptionSagaAction: (subscriptionId: string) => {
    type: string,
    payload : {
        subscriptionId : string
    }
} = (subscriptionId) => {
    return {
        type: myAccountSagaActionType.callPauseBoldSubscriptionSaga,
        payload : {
            subscriptionId : subscriptionId
        }
    }
}





export const getDevelopersForContractSagaAction: (contractId: string) => {
    type: string,
    payload: {
        contractId: string
    }
} = (contractId) => {
    return {
        type: myAccountSagaActionType.callGetDevelopersForContractSaga,
        payload: {
            contractId: contractId
        }
    }
}

type developerPayloadType = {
    UserId: string,
    UserName: string,
    UserTypeId: string,
    UserCompany: string,
    UserAddress: string,
    UserEMAILId: string,
    Active: string,
    ContractId: string,
}

export const addDeveloperToContractSagaAction: (Developer: developerPayloadType) => {
    type: string,
    payload: {
        Developer: developerPayloadType
    }
} = (Developer) => {
    return {
        type: myAccountSagaActionType.callAddDeveloperToContractSaga,
        payload: {
            Developer: Developer
        }
    }
}

export const getAppsForContractSagaAction: (contractId: string) => {
    type: string,
    payload: {
        contractId: string
    }
} = (contractId) => {
    return {
        type: myAccountSagaActionType.callGetAppsForContractSaga,
        payload: {
            contractId: contractId
        }
    }
}

type applicationPayloadType = {
    ApplicationId: string
    ApplicationName: string
    Active: string
  }
export const addAppsToContractSagaAction: (Application: applicationPayloadType) => {
    type: string,
    payload: {
        Application: applicationPayloadType
    }
} = (Application) => {
    return {
        type: myAccountSagaActionType.callAddAppsToContractSaga,
        payload: {
            Application: Application
        }
    }
}
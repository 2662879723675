import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  Iconography,
  Logo,
  Notification,
  NotificationVariants,
  Button,
  PasswordInput,
  LogoThemes,
  IconographySizes,
  IconographyThemes
} from "@hid-galaxy-ui/galaxy-react";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import HIDFooter from "../../../common/components/HIDFooter/HIDFooter";
import { authSliceAction } from "../../../redux/slices/auth";
import { customerResetSagaAction } from "../../../redux/saga/auth/saga-actions";
import useCheckAuthentication from "../../../hooks/useCheckAuthentication";
import "./ResetPassword.scss";

const ResetPassword: React.FC = () => {
  const { customerId, resetToken } = useParams();
  const { errorInfo } = useAppSelector((state) => state.auth);
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [passwordStatus, setPasswordStatus] = React.useState<{status: "success" | "error"}| {}>({});
  const [confirmPasswordStatus, setConfirmPasswordStatus] = React.useState<{status: "success" | "error"}| {}>({});
  const [toasts, setToasts] = React.useState([]);
  const [btnClicked, setBtnClicked] = React.useState<boolean>(false)
  const [authenticationStatus, authenticationConsoleArray] =
    useCheckAuthentication();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailId = queryParams.get('email') || null;
  const [validation, setValidation] = React.useState({
    isMinLengthValid: false,
    isUpperLowerCaseValid: false,
    isSymbolValid: false,
    isUniqueCharValid: false,
    isMaxLengthValid: false,
    isNoUserAttrValid: true,
  });
  const hasUpperCase = /[A-Z]/;
  const hasLowerCase = /[a-z]/;
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/;
  const hasMinLength = /.{10,}/;
  const hasMaxLength = /.{1,100}/;

  React.useEffect(() => {
    dispatch(
      authSliceAction.errorInfoTrigger({
        severity: "",
        errorMsg: "",
        open: false,
      })
    );
  }, []);

  React.useEffect(() => {
    if (authenticationStatus) {
      navigate("/");
    }
  }, [authenticationStatus]);

  React.useEffect(() => {
    if (Object.values(validation).every((isValid) => isValid))
      setPasswordStatus({ status: "success" });
    else setPasswordStatus({});
  }, [password]);

  React.useEffect(() => {
    if (confirmPassword.length > 0 && confirmPassword === password)
      setConfirmPasswordStatus({ status: "success" });
    else setConfirmPasswordStatus({});
  }, [password, confirmPassword]);

  React.useEffect(() => {
    let content = {
      id: Math.floor(Math.random() * 1000),
      title: "",
      message: errorInfo?.errorMsg,
      variant: NotificationVariants.Error,
      icon: <Iconography icon="ban" size={IconographySizes.Medium} />,
    };
    if (btnClicked && errorInfo?.errorMsg.length > 0) setToasts([content]);
  }, [errorInfo]);

  const validatePassword = (password: string) => {
    const newValidation = {
      isMinLengthValid: hasMinLength.test(password),
      isUpperLowerCaseValid:
        hasUpperCase.test(password) && hasLowerCase.test(password),
      isSymbolValid: hasSymbol.test(password),
      isUniqueCharValid: new Set(password).size >= 5, // at least 5 unique characters
      isMaxLengthValid: hasMaxLength.test(password),
      isNoUserAttrValid: emailId ? !password.toLowerCase().includes(emailId.toLowerCase()) : true,
    };

    setValidation(newValidation);
  };

  const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newPassword = event.currentTarget.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setBtnClicked(true);
    dispatch(
      customerResetSagaAction({
        id: customerId,
        password: confirmPassword,
        resetToken: resetToken,
      })
    );
  };

  const validationIcon = (isValid: boolean) => {
    if (password.length === 0)
      return (
        <Iconography
          icon="circleCheck"
          size={IconographySizes.Small}
          style={{ color: "#B6B6B6" }}
        />
      );
    if (isValid)
      return (
        <Iconography
          icon="circleCheck"
          size={IconographySizes.Small}
          theme={IconographyThemes.Success}
        />
      );
    return (
      <Iconography
        icon="circleX"
        size={IconographySizes.Small}
        theme={IconographyThemes.Error}
      />
    );
  };

  // return (
  //   <div className="reset-password-container" style={{ marginTop: "0px" }}>
  //     <Box sx={{ bgcolor: "#FFFFFF" }}>
  //       <Grid container>
  //         <Grid item xs={6} md={8} className="login-header">
  //           <div>
  //             <div className="header-1">Welcome To The Services Portal</div>
  //             <div className="header-2">
  //               Portal for ordering the services for Packages and Validations
  //             </div>
  //           </div>
  //         </Grid>
  //         <Grid item xs={6} md={4} className="right-view">
  //           <div className="form-box">
  //             <Formik
  //               initialValues={initialValues}
  //               validationSchema={validationSchema}
  //               onSubmit={handleSubmit}
  //             >
  //               {() => (
  //                 <Form>
  //                   <div className="logo-head">
  //                     <Logo title="Services Portal" id="logo" />
  //                   </div>
  //                   <div className="form-input">
  //                     <label>New Password</label>
  //                     <Field
  //                       name="password"
  //                       component={MyInput}
  //                       onKeyDown={onKeyDownHandler(confirmPassInputRef)}
  //                     />
  //                     <ErrorMessage
  //                       name="password"
  //                       component="div"
  //                       className="error"
  //                     />
  //                   </div>
  //                   <div className="form-input">
  //                     <label>Confirm password</label>
  //                     <Field
  //                       name="confirmPassword"
  //                       component={MyInput}
  //                       onKeyDown={onKeyDownHandler(submitButtonRef)}
  //                       inputRef={confirmPassInputRef}
  //                     />
  //                     <ErrorMessage
  //                       name="confirmPassword"
  //                       component="div"
  //                       className="error"
  //                     />
  //                   </div>
  //                   <div className="error-msg">{errorInfo.errorMsg}</div>
  //                   <div className="box-bottom">
  //                     <Button
  //                       elementRef={submitButtonRef}
  //                       label="Submit"
  //                       variant="primary"
  //                       type="submit"
  //                     >
  //                       Login
  //                     </Button>
  //                   </div>
  //                 </Form>
  //               )}
  //             </Formik>
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //     <HIDFooter />
  //   </div>
  // );

  return (
    <>
      <Notification
        toasts={toasts}
        autoClose={8000}
        isAutoClose={true}
        setToasts={setToasts}
      />
      <div className="reset-password-container">
        <Logo theme={LogoThemes.Light} title="Partner Portal" />
        <div className="form-box">
          <h1 className="title">Reset Password</h1>
          <div className="input-container">
            <div className="password-wrapper">
              <PasswordInput
                {...passwordStatus}
                label="Password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                theme="dark"
                onChange={handlePasswordChange}
              />
              <span>
                {validationIcon(validation.isMinLengthValid)}
                At least 10 character in length
              </span>
              <span>
                {validationIcon(validation.isUpperLowerCaseValid)}
                Both upper & lower case letters
              </span>
              <span>
                {validationIcon(validation.isSymbolValid)}
                Must contain Symbol
              </span>
              <span>
                {validationIcon(validation.isUniqueCharValid)}
                Password must have at least 5 different characters
              </span>
              <span>
                {validationIcon(validation.isNoUserAttrValid)}
                User attributes can not be saved as password
              </span>
              <span>
                {validationIcon(validation.isMaxLengthValid)}
                The maximum length of the password required is 100
              </span>
            </div>
            <div className="password-wrapper">
              <PasswordInput
                {...confirmPasswordStatus}
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                theme="dark"
                onChange={(event) =>
                  setConfirmPassword(event.currentTarget.value)
                }
              />
              <span>
                {validationIcon(confirmPassword === password)}
                Passwords Match
              </span>
            </div>
          </div>
          <Button
            label="RESET PASSWORD"
            variant="primary"
            type="submit"
            disabled={
              !(
                Object.values(validation).every((isValid) => isValid) &&
                password === confirmPassword
              )
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
      <HIDFooter />
    </>
  );
};

export default ResetPassword;

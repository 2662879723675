import React from "react";
import {
  Button,
  Divider,
  DividerVariants,
  Iconography,
  IconographySizes,
  Loader,
  LoaderSizes,
  Modal,
  Notification,
  NotificationVariants,
} from "@hid-galaxy-ui/galaxy-react";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import moment from "moment";
import InvoiceGenerator from "../../../../common/components/InvoiceGenerator";
import "./TabContentOrderHistory.scss";
import { orderDetailSagaAction } from "../../../../redux/saga/my-account/saga-actions";
import convertToNoDecimalValue from "../../../../common/utils/convertToNoDecimalValue";

const TabContentOrderHistory = () => {
  const [detailModalOpen, setDetailModalOpen] = React.useState<boolean>(false);
  const [toasts, setToasts] = React.useState([
    {
      id: Math.floor(Math.random() * 1000),
      title: "",
      message: "These Orders have been Confirmed by Shopify",
      variant: NotificationVariants.Success,
      icon: <Iconography icon="circleCheck" size={IconographySizes.Medium} />,
    },
  ]);
  const { allOrders, orderDetail, status } = useAppSelector(
    (state) => state.my_account
  );
  const dispatch = useAppDispatch();

  const checkNullString = (value: string | null): string => {
    if (value) {
      return value;
    }
    return "";
  };

  return (
    <div className="tab-content-order-history" key={orderDetail.id}>
      {status.orderHistoryLoading ? (
        <div
          style={{
            padding: "32px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader variant={LoaderSizes.large} />
        </div>
      ) : allOrders.length > 0 ? (
        <>
          <Notification
            toasts={toasts}
            isSticky={true}
            isAutoClose={false}
            setToasts={setToasts}
          />
          {allOrders.map((order) => {
            let temp = order.id.split("/");
            const orderID = temp[temp.length - 1].split("?")[0];
            return (
              <div className="order-history-card" key={order.id}>
                <div className="icon-info">
                  <Iconography
                    icon="circleCheck"
                    size={IconographySizes.Medium}
                  />
                  <div className="info">
                    <div className="name">{order.name}</div>
                    <div className="detail">
                      <span>{orderID}</span>
                      <Divider mode={DividerVariants.Content} />
                      <span>
                        {moment(order.processedAt).format("DD MMM YYYY, HH:mm")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <Button
                    variant="secondary"
                    label="View Details"
                    onClick={() => {
                      setDetailModalOpen(true);
                      dispatch(orderDetailSagaAction(order.id));
                    }}
                  />
                  <Divider mode={DividerVariants.Content} />
                  <InvoiceGenerator orderID={order.id} showInvoice={false} />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="no-contract">
          <Iconography icon="cartShopping" size={IconographySizes.Medium} />
          <p>No Products Purchased</p>
        </div>
      )}
      {detailModalOpen && (
        <Modal
          width={658}
          isDisplayCloseIcon={false}
          footerConfig={{
            secondaryBtnLabel: "CLOSE",
          }}
          onClose={() => setDetailModalOpen(false)}
          className="order-detail-modal"
        >
          {status.orderDetailLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "32px",
              }}
            >
              <Loader variant={LoaderSizes.medium} />
            </div>
          ) : (
            <>
              <h1 className="title">HID Partner Order Summary</h1>
              <h1 className="order-name">{orderDetail?.name}</h1>
              <div className="info-group">
                <p className="info-title">Created</p>
                <p className="line">
                  {moment(orderDetail.processedAt).format("DD MMM, YYYY")}
                </p>
              </div>
              <div className="row">
                <div className="info-group">
                  <p className="info-title">Ordered By</p>
                  <p className="line">{`${checkNullString(
                    orderDetail.billingAddress?.firstName
                  )} ${checkNullString(
                    orderDetail.billingAddress?.lastName
                  )}`}</p>
                  <p className="line">{`${checkNullString(
                    orderDetail.billingAddress?.address1
                  )}, ${checkNullString(
                    orderDetail.billingAddress?.address2
                  )}`}</p>
                  <p className="line">{`${checkNullString(
                    orderDetail.billingAddress?.zip
                  )}, ${checkNullString(
                    orderDetail.billingAddress?.city
                  )}, ${checkNullString(
                    orderDetail.billingAddress?.country
                  )}`}</p>
                </div>
                <div className="info-group">
                  <p className="info-title">Partner Information</p>
                  <p className="line">
                    {checkNullString(orderDetail.billingAddress?.company)}
                  </p>
                </div>
              </div>
              <div className="table">
                <div className="table-header">
                  <div className="col-1">Item</div>
                  <div className="col-2">Cost</div>
                  <div className="col-3">Qty</div>
                  <div className="col-4">Total</div>
                </div>
                <Divider mode={DividerVariants.Content} />
                {orderDetail.lineItems?.nodes?.map((lineItem, index) => {
                  return (
                    <div key={lineItem.title}>
                      {index === 0 ? (
                        <></>
                      ) : (
                        <Divider mode={DividerVariants.Canvas}/>
                      )}
                      <div className="table-row">
                        <div className="col-1">
                          <p>{lineItem.title}</p>
                          <span>
                            {lineItem.variant.sellingPlanAllocations?.nodes[0]
                              ? `${lineItem.variant.sellingPlanAllocations.nodes[0].sellingPlan.name} Plan Subscription`
                              : lineItem.title === "Builder"
                              ? "4 Months Plan"
                              : "1 Year Plan"}
                          </span>
                        </div>
                        <div className="col-2">{`$${convertToNoDecimalValue(
                          (
                            Number(lineItem.originalTotalPrice?.amount) /
                            Number(lineItem.quantity)
                          ).toString()
                        )}`}</div>
                        <div className="col-3">{lineItem.quantity}</div>
                        <div className="col-4">{`$${convertToNoDecimalValue(
                          lineItem.originalTotalPrice?.amount
                        )}`}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="total">
                <Divider mode={DividerVariants.Content} />
                <div className="row">
                  <label>Total</label>
                  <p>{`$${convertToNoDecimalValue(
                    orderDetail.totalPrice?.amount
                  )}`}</p>
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default TabContentOrderHistory;

import { createSlice } from "@reduxjs/toolkit"
// import { productDetailType, featureSectionType, recommendedSectionType } from "./Types"

type initialstateType = {
    allOrders: {
            "id": string,
            "name": string,
            "orderNumber": number,
            "processedAt": string,
            "customerName": string,
            "customerEmail": string,
            "lineItems": {
                "id": string,
                "name": string,
            }[],
            "totalPrice"?: string
    }[],
    contracts: {
        Active: string
        ContractEndDate: string
        ContractId: string
        ContractName: string
        ContractStartDate: string
        Created: string
        LastUpdated: string
        OnboardingStageId: string
        PartnerId: string
        PartnerManagerId: string
        Status: string
        Orderdate: string
        OrderId: string
        Collection: string
        SubscriptionId: string;
        Price: string;
        Sku: string;
        SubscriptionStatus: string;
        ProductType: string;
    }[],
    orderDetail: {
        id?: string | null,
        name?: string | null,
        processedAt?: string | null,
        shippingAddress?: {
            firstName: string | null,
            lastName: string | null,
            address1: string | null,
            address2: string | null,
            zip: string | null,
            phone: string | null
        },
        billingAddress?: {
            firstName: string | null,
            lastName: string | null,
            company: string | null,
            address1: string | null,
            address2: string | null,
            zip: string | null,
            phone: string | null,
            city: string | null,
            country: string | null,
        },
        orderNumber?: number,
        fulfillmentStatus?: string | null,
        financialStatus?: string | null,
        totalPrice?: {
            amount: string | null,
            currencyCode: string | null,
        },
        subtotalPrice?: {
            amount: string | null,
            currencyCode: string | null,
        },
        totalShippingPrice?: {
            amount: string | null,
            currencyCode: string | null,
        },
        totalTax?: {
            amount: string | null,
            currencyCode: string | null,
        },
        lineItems?: {
            nodes:
            {
                title: string | null,
                quantity: number,
                variant: {
                    sku: string | null,
                    image: string | null,
                    product: {
                            productType: string | null,
                    },
                    sellingPlanAllocations: {
                        nodes: 
                            {
                                sellingPlan: {
                                    id: string | null,
                                    name: string | null,
                                }
                            }[]
                    },
                },
                originalTotalPrice: {
                    amount: string | null,
                    currencyCode: string | null
                },
            }[],
        }
    },
    orderDetailPDF: {
        id?: string | null,
        name?: string | null,
        processedAt?: string | null,
        shippingAddress?: {
            firstName: string | null,
            lastName: string | null,
            address1: string | null,
            address2: string | null,
            zip: string | null,
            phone: string | null
        },
        billingAddress?: {
            firstName: string | null,
            lastName: string | null,
            company: string | null,
            address1: string | null,
            address2: string | null,
            zip: string | null,
            phone: string | null,
            city: string | null,
            country: string | null,
        },
        orderNumber?: number,
        fulfillmentStatus?: string | null,
        financialStatus?: string | null,
        totalPrice?: {
            amount: string | null,
            currencyCode: string | null,
        },
        subtotalPrice?: {
            amount: string | null,
            currencyCode: string | null,
        },
        totalShippingPrice?: {
            amount: string | null,
            currencyCode: string | null,
        },
        totalTax?: {
            amount: string | null,
            currencyCode: string | null,
        },
        lineItems?: {
            nodes:
            {
                title: string | null,
                quantity: number,
                variant: {
                    sku: string | null,
                    image: string | null,
                    product: {
                            productType: string | null,
                    },
                    sellingPlanAllocations: {
                        nodes: 
                            {
                                sellingPlan: {
                                    id: string | null,
                                    name: string | null,
                                }
                            }[]
                    },
                },
                originalTotalPrice: {
                    amount: string | null,
                    currencyCode: string | null
                },
            }[],
        }
    },
    status: {
        orderDetailLoading: boolean,
        orderDetailPDFLoading: boolean,
        orderHistoryLoading: boolean,
        contractsLoading: boolean,
        customerDetailLoading: boolean,
        customerUpdateLoading: boolean,
        subscriptionLoading: boolean,
        error: boolean,
        errorMessage: string,
        errorType: string,
    },
    developers: {
        UserId: string
        UserName: string
        UserTypeId: string
        UserCompany: string
        UserAddress: string
        UserEMAILId: string
        Created: string
        LastUpdated: string
        Active: string
        ContractId: string
        DeveloperId: string
    }[],

    applications: {
        ApplicationId: string
        ApplicationName: string
        ValidationsRequested: string
        ValidationId: string
        Created: string
        LastUpdated: string
        Active: string
    }[],
    company: string,
    orderDetailPDFID: string,
    subscriptionStatus: string,
    subscriptionID: string,
    customer: {
        id: string,
        firstName: string,
        lastName: string,
        displayName: string,
        email: string,
        phone: string,
        company: string
    }
}

var initialstate: initialstateType = {
    allOrders: [],
    orderDetail: {},
    orderDetailPDF: {},
    contracts: [],
    status: {
        error: false,
        orderDetailPDFLoading: false,
        orderHistoryLoading: false,
        contractsLoading: false,
        customerDetailLoading: false,
        customerUpdateLoading: false,
        subscriptionLoading: false,
        errorMessage: "",
        errorType: "",
        orderDetailLoading: false
    },
    developers: [],
    applications: [],
    company: "",
    orderDetailPDFID: "",
    subscriptionStatus: "",
    subscriptionID: "",
    customer: {
        id: "",
        firstName: "",
        lastName: "",
        displayName: "",
        email: "",
        phone: "",
        company: ""
    }
}

const myAccount = createSlice({
    name: "my-account",
    initialState: initialstate,
    reducers: {
        setContracts: (state, action) => {
            state.contracts = action.payload;
        },
        setAllOrders: (state, action) => {
            state.allOrders = action.payload;
        },
        setOrderDetail: (state, action) => {
            state.orderDetail = action.payload;
        },
        setOrderDetailLoadingStatus: (state, action) => {
            state.status.orderDetailLoading = action.payload;
        },
        setOrderHistoryLoadingStatus: (state, action) => {
            state.status.orderHistoryLoading = action.payload;
        },
        setContractsLoadingStatus: (state, action) => {
            state.status.contractsLoading = action.payload;
        },
        setCustomerDetailLoadingStatus: (state, action) => {
            state.status.customerDetailLoading = action.payload;
        },
        setCustomerUpdateLoadingStatus: (state, action) => {
            state.status.customerUpdateLoading = action.payload;
        },
        setDevelopers: (state, action) => {
            state.developers = action.payload;
        },
        setApplications: (state, action) => {
            state.applications = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setCustomerDetail: (state, action) => {
            state.customer = action.payload;
        },
        setOrderDetailPDF: (state, action) => {
            state.orderDetailPDF = action.payload;
        },
        setOrderDetailPDFLoadingStatus: (state, action) => {
            state.status.orderDetailPDFLoading = action.payload;
        },
        setOrderDetailPDFID: (state, action) => {
            state.orderDetailPDFID = action.payload;
        },
        setSubscriptionLoading: (state, action) => {
            state.status.subscriptionLoading = action.payload;
        },
        setSubscriptionID: (state, action) => {
            state.subscriptionID = action.payload;
        },
        setSubscriptionStatus: (state, action) => {
            state.subscriptionStatus = action.payload;
        },
        reset : (state) => {
            state.allOrders = []
            state.applications = [];
            state.contracts = [];
            state.orderDetail = {};
            state.developers = [];
            state.company = "";
            state.customer = {
                id: "",
                firstName: "",
                lastName: "",
                displayName: "",
                email: "",
                phone: "",
                company: ""
            }
        }
    }
})

export const myAccountScileReducer = myAccount.reducer;
export const myAccountSliceAction = myAccount.actions;

import React from "react";
import "./PartnerInfo.scss";
import { Iconography, IconographySizes } from "@hid-galaxy-ui/galaxy-react";
import { useAppSelector } from "../../../../../redux";

const PartnerInfo: React.FC = () => {
  const { customer } = useAppSelector((state) => state.my_account);
  return (
    <div className="partner-info-container">
      <div className="name">
        <div className="icon-wrapper">
          <Iconography icon="award" size={IconographySizes.Large} />
        </div>
        <p>{customer.company}</p>
      </div>
    </div>
  );
};

export default PartnerInfo;
import { call, put, takeLatest, delay, select } from "redux-saga/effects"
import { productListSagaActionTypes } from "./saga-action"
import {axiosGraphqlInstance, axiosRestInstance} from "../../../common/axios"
import { productListSliceAction } from "../../slices/product-list"
import { AxiosResponse } from "axios"

const productMenuApi = async (sub_type: string) => {
    let response = axiosGraphqlInstance.post<AxiosResponse<{
        menu: {
            items: { title: string, url: string }[]
        }
    }>>('/graphql.json', {
        query: ` {
            menu(handle: "${sub_type}") {
            title
            items {
              title
              url
            }
          }
        }`,
        variables: {

        }
    }).then((response) => response.data.data)
    return response;
}

const productCollectionApi = async (sub_package_type: string) => {
    let featureKeys: string[] = []
    if (sub_package_type === "support-service")
      featureKeys = [
        "development_support_validity",
        "sla_management",
        "access_to_hiddeveloper_community",
        "access_to_hid_academy",
        "development_support",
        "sandbox_environment",
        "demo_licenses",
        "test_hardware",
        "solution_validation",
        "dedicated_regional_consultant",
        "escalation_manager",
        "solution_certification",
        "24_7_emergency_contact",
        "monthly_follow_up_meeting",
        "instructor_led_training",
        "sla_p1_defination",
        "sla_p1_initial",
        "sla_p1_cadence",
        "sla_p2_defination",
        "sla_p2_initial",
        "sla_p2_cadence",
        "sla_p3_defination",
        "sla_p3_initial",
        "sla_p3_cadence",
        "sla_p4_defination",
        "sla_p4_initial",
        "sla_p4_cadence"
      ];
    else if (sub_package_type === "project-assurance-service")
      featureKeys = ["milestones", "additional_milestones"];
    else featureKeys = ["key_features"];

    const identifiers = featureKeys.map((key) => {
    return { key: key, namespace: "custom" };
    });
    const query = `
    query ProductCollection($subPackage : String!, $identifiers: [HasMetafieldsIdentifier!]!) {
      collection(handle: $subPackage) {
        id
        handle
        title
        description
        products(first: 250) {
          nodes{
            title
            id
            description
            descriptionHtml
            productType
            priceRange{
              minVariantPrice{
                amount
                currencyCode
              }
              maxVariantPrice{
                amount
                currencyCode
              }
            }
            featuredImage{
              url
            }
            metafields(identifiers: $identifiers) {
              key
              value
            }
            variants(first: 1) {
              edges {
                node {
                  sku
                  id
                }
              }
            }
            tags
            sellingPlanGroups(first:1) {
              edges {
                node {
                  sellingPlans(first: 3) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
    let response = axiosGraphqlInstance.post<AxiosResponse>('/graphql.json', {
        query,
        variables: {
            subPackage: sub_package_type,
            identifiers
        }
    }).then((response) => response.data.data.collection)
    return response;
}

type allowedSupportPackageApiResponseType = {
  success: boolean;
  message: string;
  data?: {
    allowedSupportPackage: string;
    purchasedSupportPackage: string;
  };
}

const allowedSupportPackageApi = async (company: string) => {
  let response = axiosRestInstance.get<AxiosResponse<allowedSupportPackageApiResponseType>>(`/allowedSupportPackage/getpackagename/${company}`)
      .then((res) => res.data);
  return response;
}

function* getCollectionListSaga(actions: {
  type: string,
  payload: {
      subType: string,
  }
}) {
  try {
      yield put(productListSliceAction.setCollectionListLoading(true));
      let productMenuApiResponse: {
          menu: {
              items: { title: string, url: string }[]
          }
      } = yield call(productMenuApi, actions.payload.subType);
      yield put(productListSliceAction.setCollectionList(productMenuApiResponse.menu.items))
      yield put(productListSliceAction.setCollectionListLoading(false));

  } catch (e) {
      console.error(e);
      yield put(productListSliceAction.setCollectionListLoading(false));
  }
}


function* getCollection(actions: {
    type: string,
    payload: {
        subType: string,
        subTypeIndex : number,
    }
}) {
    try {
        yield put(productListSliceAction.setLoadingStatus(true));
        let productMenuApiResponse: {
            menu: {
                items: { title: string, url: string }[]
            }
        } = yield call(productMenuApi, actions.payload.subType);
        let i: number = 0
        let items: any = productMenuApiResponse.menu.items
        while (items.length > i) {
            let result: {} = yield productCollectionApi(items[i].url.split("/")[items[i].url.split("/").length - 1])
            yield put(productListSliceAction.setCollection({
                subType: actions?.payload?.subType,
                subTypeIndex : actions?.payload?.subTypeIndex,
                data: result
            }))
            i++
        }
        yield put(productListSliceAction.setLoadingStatus(false));

    } catch (e) {
        console.error(e);
        yield put(productListSliceAction.setLoadingStatus(false));
    }
}

function* getAllowedSupportPackageSaga(actions: {
  type: string,
  payload: {
    company: string,
  }
}) {
  try {
    let response: allowedSupportPackageApiResponseType = yield call(allowedSupportPackageApi, actions.payload.company);
    if (response.success) {
      let allowedSupportPackageArray = response.data.allowedSupportPackage.split(",");
      yield put(productListSliceAction.setAllowedSupportPackage(allowedSupportPackageArray));
      yield put(productListSliceAction.setPurchasedSupportPackage(response.data.purchasedSupportPackage))
    }
  } catch (e) {
    console.error(e);
  }
}

function* productListSaga() {
    yield takeLatest(productListSagaActionTypes.callGetCollectionListSaga, getCollectionListSaga)
    yield takeLatest(productListSagaActionTypes.callGetCollectionSaga, getCollection);
    yield takeLatest(productListSagaActionTypes.callAllowedSupportPackageSaga, getAllowedSupportPackageSaga)
}

export default productListSaga;
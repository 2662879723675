export const faqSagaActionType = {
    callGetFAQSaga : 'callGetFAQSaga',
    callFetchFAQSupportServiceSaga : 'callFetchFAQSupportServiceSaga',
    callFetchFAQAssuranceServiceSaga : 'callFetchFAQAssuranceServiceSaga',
    callFetchFAQAddOnServiceSaga : 'callFetchFAQAddOnServiceSaga'
}

export const getFAQ : () => {
    type: string,
} = () => {
    return {
        type : faqSagaActionType.callGetFAQSaga,
    }
}

export const fetchFAQSupportService :  () => {
    type: string,
} = () => {
    return {
        type : faqSagaActionType.callFetchFAQSupportServiceSaga,
    }
}

export const fetchFAQAssuranceService :  () => {
    type: string,
} = () => {
    return {
        type : faqSagaActionType.callFetchFAQAssuranceServiceSaga,
    }
}

export const fetchFAQAddOnService :  () => {
    type: string,
} = () => {
    return {
        type : faqSagaActionType.callFetchFAQAddOnServiceSaga,
    }
}
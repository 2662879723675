import { createSlice } from "@reduxjs/toolkit"

type initialstateType = {
    title: string,
    body: string,
    faqSupportService : {
        title: string
        faq: Array<{
            question: string,
            answer: string
        }>
    },
    faqAssuranceService : {
        title: string
        faq: Array<{
            question: string,
            answer: string
        }>
    }
    faqAddOnService : {
        title: string
        faq: Array<{
            question: string,
            answer: string
        }>
    }
    status : {
        loading : boolean,
        error : boolean,
        errorMessage : string,
        errorType : string,
    }
}

var initialstate: initialstateType = {
    title: "",
    body: "",
    faqSupportService: {
        title: "",
        faq: []
    },
    faqAssuranceService: {
        title: "",
        faq: []
    },
    faqAddOnService: {
        title: "",
        faq: []
    },
    status: {
        error: false,
        errorMessage: "",
        errorType: "",
        loading: false
    },
}

const faqSlice = createSlice({
    name: "faq",
    initialState: initialstate,
    reducers: {
        setFAQTitle: (state, action) => {
            state.title = action.payload;
        },
        setFAQBody: (state, action) => {
            state.body = action.payload;
        },
        setSupportServiceFAQTitle: (state, action) => {
            state.faqSupportService.title = action.payload
        },
        setSupportServiceFAQ: (state, action) => {
            state.faqSupportService.faq = action.payload
        },
        setAssuranceServiceFAQTitle: (state, action) => {
            state.faqAssuranceService.title = action.payload
        },
        setAssuranceServiceFAQ: (state, action) => {
            state.faqAssuranceService.faq = action.payload
        },
        setAddOnServiceFAQTitle: (state, action) => {
            state.faqAddOnService.title = action.payload
        },
        setAddOnServiceFAQ: (state, action) => {
            state.faqAddOnService.faq = action.payload
        },
        setLoadingStatus : (state, action) => {
            state.status.loading = action.payload
        }
    }
})

export const faqSliceReducer = faqSlice.reducer;
export const faqSliceAction = faqSlice.actions;
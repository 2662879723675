export const partnerDetailsSagaActionTypes = {
    callGetAllPartnersSaga : "callGetAllPartnersSaga",
    callGetPartnerDetailsSaga : "callGetPartnerDetailsSaga",
    callAddPartnerSaga : "callAddPartnerSaga",
    callUpdatePartnerSaga : "callUpdatePartnerSaga"
}

export const getAllPartnersSagaAction : () => {
    type: string,
} = () => {
    return {
        type : partnerDetailsSagaActionTypes.callGetAllPartnersSaga,
    }
}

export const getPartnerDetailsSagaAction : (partner: string) => {
    type: string,
    payload: {
        partner: string,
    }
} = (partner: string) => {
    return {
        type : partnerDetailsSagaActionTypes.callGetPartnerDetailsSaga,
        payload : {
            partner : partner,
        }
    }
}

type PartnerDetailsType = {
    partner_name: string
    description: string,
    address: string,
    company_contact: string,
    corporate_website: string,
    company_email: string,
    company_logo: string | File,
    region: string,
    country: string,
    support_email: string,
    bu: string
}

export const addPartnerSagaAction : (partnerDetails: PartnerDetailsType) => {
    type: string,
    payload: {
        partnerDetails: PartnerDetailsType,
    }
} = (partnerDetails: PartnerDetailsType) => {
    return {
        type : partnerDetailsSagaActionTypes.callAddPartnerSaga,
        payload : {
            partnerDetails : partnerDetails,
        }
    }
}

export const updatePartnerSagaAction : (partnerDetails: PartnerDetailsType) => {
    type: string,
    payload: {
        partnerDetails: PartnerDetailsType,
    }
} = (partnerDetails: PartnerDetailsType) => {
    return {
        type : partnerDetailsSagaActionTypes.callUpdatePartnerSaga,
        payload : {
            partnerDetails : partnerDetails,
        }
    }
}
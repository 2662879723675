import React from "react";
import useCheckAuthentication from "./hooks/useCheckAuthentication";
import { useAppDispatch, useAppSelector } from "./redux";
import { getUserRoleSagaAction } from "./redux/saga/auth/saga-actions";
import AppRoutes from "./AppRoutes";

const App: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const [authenticationStatus, authenticationConsoleArray] =
    useCheckAuthentication();
  const { userRole } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (authenticationStatus && userRole === "") {
      // dispatch(getUserRoleSagaAction());
    }
  }, [authenticationStatus]);

  return (
    <div className="App">
      <AppRoutes authenticationStatus={authenticationStatus} />
    </div>
  );
};

export default App;

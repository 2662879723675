import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroungImage from "../../assets/images/Rectangle_26.png";
import mobileImage from "../../assets/images/mobile-img.png";
import {
  Button,
  Iconography,
  Loader,
  LoaderSizes,
  ThickNessLevel,
} from "@hid-galaxy-ui/galaxy-react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  cartCreateOrUpdateCartSagaActions,
  cartCreateOrUpdateCartSagaActionsSubscription,
} from "../../../redux/saga/cart/saga-actions";
import ProductDetailModal from "../ProductDetailModal";
import {
  getProductDetailAction,
  getProductDetailAndAddToCartAction,
} from "../../../redux/saga/product-detail/saga-action";
import "./AnnouncementCarousel.scss";

interface AnnouncementCarouselProps {
  announcements: {
    title: string;
    content: string;
    productID?: string;
    imageURL?: string;
    productType?: string;
    variantID?: string;
    sku?: string;
  }[];
}

const AnnouncementCarousel: React.FC<AnnouncementCarouselProps> = ({
  announcements,
}) => {
  const [addedProductID, setAddedProductID] = React.useState<string>("");
  const [detailModalOpen, setDetailModalOpen] = React.useState<boolean>(false);
  const { collection, status } = useAppSelector((state) => state.pdp);
  const {
    cartItems,
    addToCartLoading,
    addedProductID: addedProductIDCart,
    supportServiceInCart,
  } = useAppSelector((state) => state.cart);
  const { allowedSupportPackage, purchasedSupportPackage } = useAppSelector(
    (state) => state.plp
  );
  const dispatch = useAppDispatch();
  const userToken: string | null = localStorage.getItem("user-token");
  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };

  const onAddToCartClick = (productID: string) => {
    setAddedProductID(productID);
    let cartID = null;
    if (cartItems?.id) {
      let idArray = cartItems.id.split("/");
      cartID = idArray[idArray.length - 1];
    }
    dispatch(
      getProductDetailAndAddToCartAction({
        productID: productID,
        cartID: cartID,
        userToken: userToken,
      })
    );
  };

  const onLearnMoreClick = (productID: string) => {
    dispatch(getProductDetailAction(productID));
    setDetailModalOpen(true);
  };

  const onAddToCartClickModal: (
    productVariantId: string,
    sellingPlanID: string | undefined
  ) => void = (productVariantId, sellingPlanID) => {
    let cartID = null;
    if (cartItems?.id) {
      let idArray = cartItems.id.split("/");
      cartID = idArray[idArray.length - 1];
    }
    if (sellingPlanID) {
      dispatch(
        cartCreateOrUpdateCartSagaActionsSubscription({
          cartId: cartID,
          userToken: userToken,
          pdp: {
            productVariantId: productVariantId,
            sellingPlanId: sellingPlanID,
          },
          navigateFunction: () => {},
        })
      );
    } else {
      dispatch(
        cartCreateOrUpdateCartSagaActions({
          cartId: cartID,
          userToken: userToken,
          pdp: {
            featureSectionTitleAndVariantIdArray: [],
            productVariantId: productVariantId,
          },
          navigateFunction: () => {},
        })
      );
    }
  };

  const addedToCart = (productID: string): boolean => {
    for (let i = 0; i < cartItems?.lines?.edges?.length; i++) {
      if (cartItems.lines.edges[i].node.merchandise.product.id === productID)
        return true;
    }
    return false;
  };

  return (
    <div className="announcement-carousel-container">
      <Slider {...settings}>
        {announcements.map((announcement, index: number) => {
          const addToCartLoadingStatus =
            (announcement.productID === addedProductID &&
              status.productDetailAndAddToCartLoading) ||
            (announcement.variantID === addedProductIDCart && addToCartLoading);
          let addToCartDisabledSupportService = false;
          let allowedSupPackage = true;
          let isPurchasedSupPackage = false;
          if (
            announcement.productType?.replaceAll(" ", "_").toLowerCase() ===
            "support_service"
          ) {
            allowedSupPackage = allowedSupportPackage.includes(
              announcement?.sku
            );
            addToCartDisabledSupportService =
              supportServiceInCart || !allowedSupPackage;
            isPurchasedSupPackage =
              purchasedSupportPackage === announcement?.sku;
          }
          const hideAddToCartButton =
            announcement.productType?.replaceAll(" ", "_").toLowerCase() ===
              "support_service" &&
            !allowedSupPackage &&
            !isPurchasedSupPackage;
          const addToCartDisabled =
            addToCartDisabledSupportService ||
            addedToCart(announcement.productID);
          return (
            <div className="announcement-container" key={index}>
              <div className="left">
                <div className="info">
                  <div className="title">{announcement.title}</div>
                  <div className="content">{announcement.content}</div>
                </div>
                <div className="button-group">
                  {!hideAddToCartButton && (
                    <div className="cart-button">
                      {!allowedSupPackage && isPurchasedSupPackage ? (
                        <Button
                          label="Purchased"
                          variant="override"
                          icon={<Iconography icon="circleCheck" />}
                          onClick={() => {}}
                        />
                      ) : (
                        <>
                          <Button
                            label="Add to cart"
                            variant="primary"
                            icon={<Iconography icon="cartShopping" />}
                            disabled={
                              addToCartDisabled || addToCartLoadingStatus
                            }
                            onClick={() =>
                              onAddToCartClick(announcement.productID)
                            }
                          />
                          {addToCartLoadingStatus && (
                            <Loader
                              variant={LoaderSizes.small}
                              thicknessLevel={ThickNessLevel.thick}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <Button
                    variant="secondary"
                    label="Learn more"
                    onClick={() => onLearnMoreClick(announcement.productID)}
                  />
                </div>
              </div>
              <div className="right">
                <div className="image-wrapper">
                  <img src={mobileImage} alt="product-image" />
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      {detailModalOpen && (
        <ProductDetailModal
          product={collection.productDetail}
          onAddToCartClick={onAddToCartClickModal}
          setDetailModalOpen={setDetailModalOpen}
        />
      )}
    </div>
  );
};

export default AnnouncementCarousel;

import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux";
import convertToNoDecimalValue from "../../../../../common/utils/convertToNoDecimalValue";
import {
  Button,
  Iconography,
  IconographySizes,
  Loader,
} from "@hid-galaxy-ui/galaxy-react";
import {
  LoaderSizes,
  ThickNessLevel,
} from "@hid-galaxy-ui/galaxy-react/components/Loader/loaderEnums";
import CircleMinus from "./CircleMinus";
import ResizeObserver from "resize-observer-polyfill";
import SLAModal from "./sla-modal";
import "./FeatureCarousel.scss";

interface FeatureCarouselProps {
  collection: {
    id?: string;
    handle?: string;
    title?: string;
    description?: string;
    products?: {
      nodes?: {
        title?: string;
        id?: string;
        description?: string;
        descriptionHtml?: string;
        productType?: string;
        priceRange?: {
          minVariantPrice?: {
            amount?: string;
            currencyCode?: string;
          };
          maxVariantPrice?: {
            amount?: string;
            currencyCode?: string;
          };
        };
        featuredImage?: {
          url?: string;
        };
        metafields: Array<{
          key: string;
          value: string;
        }>;
        variants?: {
          edges?: {
            node?: {
              sku?: string;
              id?: string;
            };
          }[];
        };
        tags?: string[];
        sellingPlanGroups: {
          edges: {
            node: {
              sellingPlans: {
                edges: {
                  node: {
                    id: string;
                  };
                }[];
              };
            };
          }[];
        };
      }[];
    };
  };
  onAddToCartClick: (
    productVariantId: string,
    sellingPlanID: string | undefined
  ) => void;
}

const FeatureCarousel: React.FC<FeatureCarouselProps> = ({
  collection,
  onAddToCartClick,
}) => {
  const [showCarouselButton, setShowCarouselButton] =
    React.useState<boolean>(false);
  const [slaModalOpen, setSlaModalOpen] = React.useState<boolean>(false);
  const [slaDetailSKU, setSlaDetailSKU] = React.useState<string>("");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { addToCartLoading, addedProductID, supportServiceInCart } =
    useAppSelector((state) => state.cart);
  const { allowedSupportPackage, purchasedSupportPackage } = useAppSelector(
    (state) => state.plp
  );

  React.useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        let newWidth = 0;
        if (entry.contentBoxSize) {
          newWidth =
            entry.contentBoxSize[0]?.inlineSize || entry.contentRect.width;
        } else {
          newWidth = entry.contentRect.width;
        }
        if (newWidth <= 871) {
          setShowCarouselButton(true);
        } else {
          setShowCarouselButton(false);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const features = [
    {
      key: "development_support_validity",
      name: "Development Support Validity",
    },
    { key: "sla_management", name: "SLA Management" },
    {
      key: "access_to_hiddeveloper_community",
      name: "Access to HID Developer Community",
    },
    { key: "access_to_hid_academy", name: "Access to HID Academy" },
    { key: "development_support", name: "Development Support" },
    { key: "sandbox_environment", name: "Sandbox environment" },
    { key: "demo_licenses", name: "Demo Licenses" },
    { key: "test_hardware", name: "Test Hardware" },
    { key: "solution_validation", name: "Solution Validation" },
    {
      key: "dedicated_regional_consultant",
      name: "Dedicated regional consultant",
    },
    { key: "escalation_manager", name: "Escalation manager" },
    { key: "solution_certification", name: "Solution Certification" },
    { key: "24_7_emergency_contact", name: "24*7 Emergency contact" },
    { key: "monthly_follow_up_meeting", name: "Monthly follow up meeting" },
    { key: "instructor_led_training", name: "Instructor-led training" },
  ];

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 224;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 224;
    }
  };

  const ValueForKey = (
    features: Array<{
      key: string;
      value: string;
    }>,
    key: string
  ): string => {
    for (let i = 0; i < features.length; i++) {
      if (features[i]?.key === key) {
        if (key === "development_support_validity")
          return features[i].value + " Months";
        else return features[i].value;
      }
    }
    return "NA";
  };

  return (
    <div className="feature-carousel-container">
      <div className="feature-list">
        {features.map((feature, index) => (
          <div className="feature-label" key={index}>
            {feature.name}
          </div>
        ))}
      </div>
      <div className="product-carousel">
        <div className="carousel-container" ref={containerRef}>
          {collection.products.nodes.map((product: any, index: number) => {
            const addToCartLoadingStaus =
              addToCartLoading &&
              addedProductID === product.variants.edges[0].node.id;
            const sellingPlanID =
              product.sellingPlanGroups.edges[0]?.node.sellingPlans.edges[0]
                ?.node.id;
            const allowedSupPackage = allowedSupportPackage.includes(
              product?.variants?.edges[0]?.node?.sku
            );
            const addToCartDisabled =
              supportServiceInCart || !allowedSupPackage;
            const isPurchasedSupPackage =
              purchasedSupportPackage ===
              product?.variants?.edges[0]?.node?.sku;
            return (
              <div className="feature-service-card" key={product.id}>
                <div className="info">
                  <div className="image-wrapper">
                    <img src={product.featuredImage?.url} alt="product-image" />
                  </div>
                  <div className="name">{product.title}</div>
                  <div className="price">
                    ${" "}
                    {convertToNoDecimalValue(
                      product.priceRange.maxVariantPrice.amount
                    )}
                  </div>

                  <div className="subscription">
                    {sellingPlanID ? "Subscription" : "One Time"}
                  </div>

                  <div className="cart-button">
                    {!allowedSupPackage && isPurchasedSupPackage ? (
                      <Button
                        label="Purchased"
                        variant="override"
                        icon={<Iconography icon="circleCheck" />}
                        onClick={() => {}}
                      />
                    ) : (
                      <>
                        <Button
                          label="Add to cart"
                          variant="primary"
                          icon={<Iconography icon="cartShopping" />}
                          disabled={addToCartLoadingStaus || addToCartDisabled}
                          onClick={() =>
                            onAddToCartClick(
                              product.variants.edges[0].node.id,
                              sellingPlanID
                            )
                          }
                        />
                        {addToCartLoadingStaus && (
                          <Loader
                            variant={LoaderSizes.small}
                            thicknessLevel={ThickNessLevel.thick}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {features.map((feature, index: number) => {
                  const value = ValueForKey(product.metafields, feature.key);
                  const borderNone = index === 0 ? " border-none" : "";
                  if (value.toString().toLowerCase() === "yes") {
                    return (
                      <div
                        className={`feature-value circle-check${borderNone}`}
                        key={feature.key}
                      >
                        <Iconography
                          icon="circleCheck"
                          size={IconographySizes.Medium}
                        />
                      </div>
                    );
                  } else if (value.toString().toLowerCase() === "no") {
                    return (
                      <div
                        className={`feature-value${borderNone}`}
                        key={feature.key}
                      >
                        <CircleMinus />
                      </div>
                    );
                  }
                  return (
                    <div
                      className={`feature-value${borderNone}`}
                      key={feature.key}
                    >
                      {product.variants?.edges[0]?.node?.sku !==
                        "ORIGO-TPS-OMI-STARTER-NEW" &&
                      feature.key === "sla_management" ? (
                        <Button
                          label="View Details"
                          variant="secondary"
                          onClick={() => {
                            setSlaDetailSKU(
                              product.variants?.edges[0]?.node?.sku
                            );
                            setSlaModalOpen(true);
                          }}
                        />
                      ) : (
                        <div className="feature-box">{value}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {showCarouselButton && (
          <div className="button-group">
            <Button
              variant="secondary"
              icon={
                <Iconography
                  icon="circleChevronRight"
                  size={IconographySizes.Medium}
                />
              }
              onClick={scrollRight}
            />
            <Button
              variant="secondary"
              icon={
                <Iconography
                  icon="circleChevronLeft"
                  size={IconographySizes.Medium}
                />
              }
              onClick={scrollLeft}
            />
          </div>
        )}
      </div>
      {slaModalOpen && (
        <SLAModal
          collection={collection}
          setDetailModalOpen={setSlaModalOpen}
          slaDetailSKU={slaDetailSKU}
        />
      )}
    </div>
  );
};

export default FeatureCarousel;



// creating actionTypes so that we do not have a mismatch of action type stings in saga!!
 export const authSagaActionTypes = {
    callAuthSaga : 'callAuthSaga',
    callGetUserRoleSaga: 'callGetUserRoleSaga',
    callCustomerRecoverSaga: 'callCustomerRecoverSaga',
    callCustomerResetSaga: 'callCustomerResetSaga'
}


export const getLoginSagaActions : (data : { email: string, password: string }) => {
    type: string,
    payload? : { email: string, password: string }
} = (data) => {
    return {
        type : authSagaActionTypes.callAuthSaga,
        payload : data
    }
}

export const customerRecoverSagaAction : ( email: string ) => {
    type: string,
    payload? : { email: string }
} = (email) => {
    return {
        type : authSagaActionTypes.callCustomerRecoverSaga,
        payload : {
            email: email
        }
    }
}

export const customerResetSagaAction : (data: { id: string, password: string, resetToken: string }) => {
    type: string,
    payload? : { id: string, password: string, resetToken: string }
} = (data) => {
    return {
        type : authSagaActionTypes.callCustomerResetSaga,
        payload : data
    }
}

export const getUserRoleSagaAction : () => {
    type: string
} = () => {
    return {
        type : authSagaActionTypes.callGetUserRoleSaga
    }
}

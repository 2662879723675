import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import useProductListPageRoute from "../../../../hooks/useProductListPageRoute";
import { Loader, LoaderSizes, Tabs } from "@hid-galaxy-ui/galaxy-react";
import {
  cartCreateOrUpdateCartSagaActions,
  cartCreateOrUpdateCartSagaActionsSubscription,
} from "../../../../redux/saga/cart/saga-actions";
import FeatureCarousel from "./feature-carousel";
import ServiceCard from "../../../../common/components/ServiceCard";
import { ProductType } from "../../../../redux/slices/product-detail/Types";
import ProductDetailModal from "../../../../common/components/ProductDetailModal";
import { fetchCustomerDetailSagaAction } from "../../../../redux/saga/my-account/saga-actions";
import { getAllowedSupportPackageAction } from "../../../../redux/saga/product-list/saga-action";
import "./Services.scss";

const Services = () => {
  const [detailModalOpen, setDetailModalOpen] = React.useState<boolean>(false);
  const [detailModalProduct, setDetailModalProduct] =
    React.useState<ProductType>({
      title: "",
      id: "",
      description: "",
      productType: "",
      priceRange: {
        minVariantPrice: {
          amount: "",
          currencyCode: "",
        },
        maxVariantPrice: {
          amount: "",
          currencyCode: "",
        },
      },
      featuredImage: {
        url: "",
      },
      metafields: [
        {
          key: "",
          value: "",
        },
      ],
      variants: {
        edges: [
          {
            node: {
              sku: "",
              id: "",
            },
          },
        ],
      },
      tags: [],
      sellingPlanGroups: {
        edges: [
          {
            node: {
              sellingPlans: {
                edges: [
                  {
                    node: {
                      id: "",
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    });
  const { collection, allowedSupportPackage, status } = useAppSelector(
    (state) => state.plp
  );
  const { faqSupportService, faqAssuranceService, faqAddOnService } =
    useAppSelector((state) => state.faq);
  const { cartItems } = useAppSelector((state) => state.cart);
  const { customer } = useAppSelector((state) => state.my_account);
  const dispatch = useAppDispatch();
  const { getRouteIndex, dispatchIt } = useProductListPageRoute();
  const shopifyNavigationHandle = "service-catalog";
  const subCollection = collection[getRouteIndex(shopifyNavigationHandle)];
  const sortedCollection = React.useMemo(() => subCollection, [collection]);
  const userToken: string | null = localStorage.getItem("user-token");

  React.useInsertionEffect(() => {
    if (collection[getRouteIndex(shopifyNavigationHandle)]?.length === 0) {
      dispatchIt(shopifyNavigationHandle);
    }
    if (customer.email === "") dispatch(fetchCustomerDetailSagaAction());
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    if (allowedSupportPackage.length === 0 && customer.company !== "") {
      dispatch(getAllowedSupportPackageAction(customer.company));
    }
  }, [customer]);

  function onTabClickHandler(e: any, key: string): void {}

  const onAddToCartClick: (
    productVariantId: string,
    sellingPlanID: string | undefined
  ) => void = (productVariantId, sellingPlanID) => {
    let cartID = null;
    if (cartItems?.id) {
      let idArray = cartItems.id.split("/");
      cartID = idArray[idArray.length - 1];
    }
    if (sellingPlanID) {
      dispatch(
        cartCreateOrUpdateCartSagaActionsSubscription({
          cartId: cartID,
          userToken: userToken,
          pdp: {
            productVariantId: productVariantId,
            sellingPlanId: sellingPlanID,
          },
          navigateFunction: () => {},
        })
      );
    } else {
      dispatch(
        cartCreateOrUpdateCartSagaActions({
          cartId: cartID,
          userToken: userToken,
          pdp: {
            featureSectionTitleAndVariantIdArray: [],
            productVariantId: productVariantId,
          },
          navigateFunction: () => {},
        })
      );
    }
  };

  const onViewClick: (product: ProductType) => void = (product) => {
    setDetailModalOpen(true);
    setDetailModalProduct(product);
  };

  //   React.useEffect(() => {
  //     if (faqSupportService.faq.length === 0) dispatch(fetchFAQSupportService());
  //     if (faqAssuranceService.faq.length === 0)
  //       dispatch(fetchFAQAssuranceService());
  //     if (faqAddOnService.faq.length === 0) dispatch(fetchFAQAddOnService());
  //     if (company === "") dispatch(fetchCompanySagaAction());
  //   }, []);

  const TabContent: React.FC<{ categorie: (typeof sortedCollection)[0] }> = ({
    categorie,
  }) => {
    if (categorie.handle === "support-service") {
      return (
        <FeatureCarousel
          collection={categorie}
          onAddToCartClick={onAddToCartClick}
        />
      );
    } else {
      return (
        <div className="tile-container">
          {categorie.products.nodes.map((product: any, index: number) => {
            return (
              <ServiceCard
                product={product}
                onAddToCartClick={onAddToCartClick}
                onViewClick={onViewClick}
                key={product.id}
              />
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className="services-container">
      <h1 className="page-title">Services</h1>
      <div className="service-categories">
        <div className="title">Explore Services by Categories</div>
        {status.loading ? (
          <div className="loading-tabs">
            <Loader variant={LoaderSizes.large} />
          </div>
        ) : sortedCollection?.length > 0 ? (
          <Tabs
            theme="frameless"
            items={sortedCollection.map(
              (categorie: (typeof sortedCollection)[0], index: number) => {
                return {
                  key: categorie.id,
                  label: categorie.title,
                  content: <TabContent categorie={categorie} />,
                };
              }
            )}
            onClick={onTabClickHandler}
          />
        ) : (
          <></>
        )}
      </div>
      {detailModalOpen && (
        <ProductDetailModal
          product={detailModalProduct}
          onAddToCartClick={onAddToCartClick}
          setDetailModalOpen={setDetailModalOpen}
        />
      )}
    </div>
  );
};

export default Services;

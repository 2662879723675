import React from "react";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "./redux";

const ProtectedRoute: React.FC<{
  element: ReactElement;
  authenticationStatus?: boolean | Object;
  requiredRole?: Array<string>;
}> = ({ element, authenticationStatus = true, requiredRole = [] }) => {
  const { userRole, status } = useAppSelector((state) => state.auth);
  if (!authenticationStatus) {
    return <Navigate to="/login" />;
  }

  if (requiredRole.length > 0 && (status.userRoleLoading || userRole === "")) {
    return (
      <div
        style={{
          minHeight: "calc(100vh - 94px)",
          paddingTop: "100px",
          textAlign: "center",
        }}
      >
        Authenticating...
      </div>
    );
  }

  if (requiredRole.length > 0 && !requiredRole.includes(userRole)) {
    return <Navigate to="/" />; // Redirect if the user role does not match
  }

  return element; // Render the protected element if checks pass
};

export default ProtectedRoute;

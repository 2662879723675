const HIDCustomLogo = () => {
  return (
    <svg
      width="102"
      height="38"
      viewBox="0 0 102 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.7896 3.58606e-08H4.21045C3.09377 3.58606e-08 2.02282 0.445941 1.23321 1.23972C0.443599 2.0335 0 3.1101 0 4.23267V33.4908C0.0178228 34.6015 0.469278 35.6606 1.25697 36.4397C2.04466 37.2188 3.10543 37.6553 4.21045 37.6552H86.7896C87.3425 37.6552 87.89 37.5457 88.4008 37.333C88.9117 37.1203 89.3758 36.8085 89.7668 36.4155C90.1578 36.0224 90.4679 35.5558 90.6795 35.0423C90.8911 34.5287 91 33.9783 91 33.4225V4.1644C90.9822 3.0537 90.5307 1.99456 89.743 1.21549C88.9554 0.436413 87.8946 -0.000144464 86.7896 3.58606e-08ZM35.1679 28.5169H25.4664V20.2759H18.9664V28.5266H9.26493V9.16754H18.9664V17.4183H25.4664V9.16754H35.1679V28.5169ZM50.0306 28.5169H40.3291V9.16754H50.0306V28.5169ZM70.85 28.5852H55.0269V9.16754H70.85C76.3993 9.16754 81.4246 13.4002 81.4149 18.9202C81.4052 24.4403 76.3993 28.5852 70.85 28.5852ZM64.6022 10.9133V26.7809C68.5896 26.7809 70.8791 27.4343 70.8791 18.8617C70.8791 10.2891 68.153 10.9133 64.6022 10.9133Z"
        fill="#003087"
      />
      <path
        d="M35.1679 28.5169H25.4664V20.2759H18.9664V28.5266H9.26493V9.16754H18.9664V17.4183H25.4664V9.16754H35.1679V28.5169Z"
        fill="white"
      />
      <path
        d="M50.0306 28.5169H40.3291V9.16754H50.0306V28.5169Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.85 28.5852H55.0269V9.16754H70.85C76.3993 9.16754 81.4246 13.4002 81.4149 18.9202C81.4052 24.4403 76.3993 28.5852 70.85 28.5852ZM64.6022 26.7809V10.9133C68.153 10.9133 70.8791 10.2891 70.8791 18.8617C70.8791 27.4343 68.5896 26.7809 64.6022 26.7809Z"
        fill="white"
      />
      <path
        d="M95.7001 3.49103C95.7001 2.73213 96.0005 2.00431 96.5352 1.46769C97.07 0.931063 97.7953 0.629591 98.5515 0.629591C99.3078 0.629591 100.033 0.931063 100.568 1.46769C101.103 2.00431 101.403 2.73213 101.403 3.49103C101.403 4.24993 101.103 4.97775 100.568 5.51438C100.033 6.051 99.3078 6.35247 98.5515 6.35247C97.7953 6.35247 97.07 6.051 96.5352 5.51438C96.0005 4.97775 95.7001 4.24993 95.7001 3.49103ZM98.5618 7.02393C99.2504 7.01172 99.9201 6.79592 100.487 6.40356C101.054 6.01119 101.493 5.45969 101.749 4.81813C102.005 4.17657 102.067 3.47345 101.927 2.79683C101.786 2.12021 101.45 1.50015 100.96 1.01432C100.471 0.528476 99.8489 0.198445 99.173 0.0655489C98.4972 -0.0673471 97.7973 0.00279923 97.161 0.267204C96.5247 0.531609 95.9802 0.97852 95.5959 1.55197C95.2115 2.12543 95.0042 2.79993 95.0001 3.49103C94.9972 3.95926 95.0878 4.42333 95.2663 4.85595C95.4448 5.28857 95.7077 5.681 96.0396 6.01016C96.3714 6.33933 96.7655 6.59858 97.1987 6.77268C97.6318 6.94678 98.0953 7.0322 98.5618 7.02393ZM97.8207 3.78027H98.5206L99.5089 5.53639H100.188L99.0353 3.73895C99.1891 3.7519 99.3438 3.73068 99.4884 3.67681C99.6331 3.62294 99.7641 3.53775 99.8722 3.42729C99.9804 3.31683 100.063 3.18381 100.114 3.03771C100.165 2.89161 100.183 2.73601 100.168 2.58198C100.173 2.40233 100.137 2.22382 100.062 2.06059C99.9873 1.89737 99.8755 1.75393 99.7356 1.64165C99.5957 1.52936 99.4317 1.45133 99.2566 1.41372C99.0814 1.37612 98.8999 1.37998 98.7265 1.42501H97.203V5.55705H97.8207V3.78027ZM97.8207 3.26377V2.0035H98.6648C99.0971 2.0035 99.5501 2.0035 99.5501 2.60264C99.5501 3.20179 99.0971 3.26377 98.5618 3.26377H97.8207Z"
        fill="white"
      />
    </svg>
  );
};

export default HIDCustomLogo;
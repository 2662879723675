import { createSlice } from "@reduxjs/toolkit";

type initialstateType = {
    allPartners: {
        partner_name: string
    }[];
    partnerDetails: {
        partner_name: string
        description: string,
        address: string,
        company_contact: string,
        corporate_website: string,
        company_email: string,
        company_logo: string,
        region: string,
        country: string,
        support_email: string,
        bu: string
    };
    status: {
        allPartnersLoading: boolean;
        partnerDetailsLoading: boolean;
        addPartnerLoading: boolean;
        addPartnerMessage: string;
        updatePartnerLoading: boolean;
        updatePartnerMessage: string;
    };
}

var initialState: initialstateType = {
    allPartners: [],
    partnerDetails: {
        partner_name: "",
        description: "",
        address: "",
        company_contact: "",
        corporate_website: "",
        company_email: "",
        company_logo: "",
        region: "",
        country: "",
        support_email: "",
        bu: ""
    },
    status: {
        allPartnersLoading: false,
        partnerDetailsLoading: false,
        addPartnerLoading: false,
        addPartnerMessage: "",
        updatePartnerLoading: false,
        updatePartnerMessage: ""
    },
};

const partnerDetailsSlice = createSlice({
    name : "partner-details",
    initialState : initialState,
    reducers : {
        setAllPartners: (state, action) => {
            state.allPartners = action.payload; 
        },
        setAllPartnersLoading : (state, action) => {
            state.status.allPartnersLoading = action.payload;
        },
        setPartnerDetails: (state, action) => {
            state.partnerDetails = action.payload; 
        },
        setPartnerDetailsLoading : (state, action) => {
            state.status.partnerDetailsLoading = action.payload;
        },
        setAddPartnerLoading : (state, action) => {
            state.status.addPartnerLoading = action.payload;
        },
        setAddPartnerMessage : (state, action) => {
            state.status.addPartnerMessage = action.payload;
        },
        setUpdatePartnerLoading : (state, action) => {
            state.status.updatePartnerLoading = action.payload;
        },
        setUpdatePartnerMessage : (state, action) => {
            state.status.updatePartnerMessage = action.payload;
        },
    }
})

export const partnerDetailsSliceReducer = partnerDetailsSlice.reducer;
export const partnerDetailsSliceAction = partnerDetailsSlice.actions;
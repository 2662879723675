import { call, put, takeLatest, delay, select } from "redux-saga/effects"
import { axiosGraphqlInstance, axiosRestInstance } from "../../../common/axios"
import { faqSagaActionType} from './saga-actions'
import { faqSliceAction} from './../../slices/faq'
import { AxiosResponse } from "axios"

const faqFetchAPI = async () => {
    let response = axiosGraphqlInstance.post<AxiosResponse<{
        page: {
            title: string,
            body: string
        }

    }>>('/graphql.json', {
        query: `{
            page(handle:"faq"){
              title
              body
            }
          }`,
        variables: {

        }
    }).then((response) => response.data.data.page)
    return response;
}

type fetchFaqServiceCatalogAPIResponseType = {
    pageByHandle: {
        title: string,
        metafield: {
            value: string
        }
    }
}

const fetchFaqServiceCatalogAPI = async (pageHandle : string) => {
    const query = `
    query FetchFaqServiceCatalog($pageHandle: String!) {
        pageByHandle(handle: $pageHandle) {
            title
            metafield(namespace: "custom", key: "faq_catalog") {
                value
            }
        }
    }`;
    let response = axiosGraphqlInstance.post<AxiosResponse<fetchFaqServiceCatalogAPIResponseType>>('/graphql.json', {
        query,
        variables: {
            pageHandle
        }
      }).then((response) => response.data.data)
      return response;
}

function* faqFetchSaga(actions: {
    type: string
}) {
    try {
        yield put(faqSliceAction.setLoadingStatus(true));
        let response: { body: string, title: string} = yield call(faqFetchAPI);
        yield put(faqSliceAction.setFAQBody(response.body));
        yield put(faqSliceAction.setFAQTitle(response.title));
        yield put(faqSliceAction.setLoadingStatus(false));
    } catch (e) {
        yield put(faqSliceAction.setLoadingStatus(false));
        console.log(e)
    }
}

function* fetchFaqSupportServiceSaga(actions: {
    type: string
}) {
    try {
        yield put(faqSliceAction.setLoadingStatus(true));
        const pageHandle = "faq-support-service";
        let response: fetchFaqServiceCatalogAPIResponseType = yield call(fetchFaqServiceCatalogAPI, pageHandle);
        yield put(faqSliceAction.setSupportServiceFAQTitle(response.pageByHandle.title));
        const faqString = response.pageByHandle.metafield.value;
        const faqData = JSON.parse(faqString);
        yield put(faqSliceAction.setSupportServiceFAQ(faqData.faq));
        yield put(faqSliceAction.setLoadingStatus(false));
    } catch (e) {
        yield put(faqSliceAction.setLoadingStatus(false));
        console.log(e)
    }
}

function* fetchFaqAssuranceServiceSaga(actions: {
    type: string
}) {
    try {
        yield put(faqSliceAction.setLoadingStatus(true));
        const pageHandle = "faq-assurance-service";
        let response: fetchFaqServiceCatalogAPIResponseType = yield call(fetchFaqServiceCatalogAPI, pageHandle);
        yield put(faqSliceAction.setAssuranceServiceFAQTitle(response.pageByHandle.title));
        const faqString = response.pageByHandle.metafield.value;
        const faqData = JSON.parse(faqString);
        yield put(faqSliceAction.setAssuranceServiceFAQ(faqData.faq));
        yield put(faqSliceAction.setLoadingStatus(false));
    } catch (e) {
        yield put(faqSliceAction.setLoadingStatus(false));
        console.log(e)
    }
}

function* fetchFaqAddOnServiceSaga(actions: {
    type: string
}) {
    try {
        yield put(faqSliceAction.setLoadingStatus(true));
        const pageHandle = "faq-add-on";
        let response: fetchFaqServiceCatalogAPIResponseType = yield call(fetchFaqServiceCatalogAPI, pageHandle);
        yield put(faqSliceAction.setAddOnServiceFAQTitle(response.pageByHandle.title));
        const faqString = response.pageByHandle.metafield.value;
        const faqData = JSON.parse(faqString);
        yield put(faqSliceAction.setAddOnServiceFAQ(faqData.faq));
        yield put(faqSliceAction.setLoadingStatus(false));
    } catch (e) {
        yield put(faqSliceAction.setLoadingStatus(false));
        console.log(e)
    }
}

function* faqSaga() {
    yield takeLatest(faqSagaActionType.callGetFAQSaga, faqFetchSaga);
    yield takeLatest(faqSagaActionType.callFetchFAQSupportServiceSaga, fetchFaqSupportServiceSaga);
    yield takeLatest(faqSagaActionType.callFetchFAQAssuranceServiceSaga, fetchFaqAssuranceServiceSaga);
    yield takeLatest(faqSagaActionType.callFetchFAQAddOnServiceSaga, fetchFaqAddOnServiceSaga);
}

export default faqSaga;
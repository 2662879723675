import React from "react";
import ServiceContractImage from "../../../../common/assets/images/contract-image.png";
import { useAppSelector } from "../../../../redux";
import moment from "moment";
import {
  Button,
  ContentSwitcher,
  Iconography,
  IconographySizes,
  Loader,
  LoaderSizes,
} from "@hid-galaxy-ui/galaxy-react";
import ServiceContractDetailModal from "../../../../common/components/ServiceContractDetailModal";
import { ContractType } from "../../../../redux/slices/my-account/Types";
import "./TabContentServiceContract.scss";

const TabContentServiceContract: React.FC<{
  collectionKey: string;
  collection: any;
}> = ({ collectionKey, collection }) => {
  const [selectedState, setSelectedState] = React.useState<string>("active");
  const [detailModalOpen, setDetailModalOpen] = React.useState<boolean>(false);
  const [detailModalContract, setDetailModalContract] =
    React.useState<ContractType>({
      Active: "",
      ContractEndDate: "",
      ContractId: "",
      ContractName: "",
      ContractStartDate: "",
      Created: "",
      LastUpdated: "",
      OnboardingStageId: "",
      PartnerId: "",
      PartnerManagerId: "",
      Status: "",
      Orderdate: "",
      OrderId: "",
      Collection: "",
      SubscriptionId: "",
      Price: "",
      Sku: "",
      SubscriptionStatus: "",
      ProductType: "",
    });
  const [contractState, setContractState] = React.useState({
    filteredServiceContract: [],
    activeContract: [],
    inActiveContract: [],
  });
  const { customer, contracts, status } = useAppSelector(
    (state) => state.my_account
  );

  React.useEffect(() => {
    setSelectedState("active");
  }, [collectionKey]);

  React.useEffect(() => {
    const filteredServiceContract = contracts.filter(
      (contract) =>
        contract.Collection?.trim().toLowerCase().replaceAll(" ", "_") ===
        collectionKey
    );
    const activeContract = filteredServiceContract.filter(
      (contract) =>
        moment(contract.ContractEndDate).startOf("day") >=
        moment().startOf("day")
    );
    const inActiveContract = filteredServiceContract.filter(
      (contract) =>
        moment(contract.ContractEndDate).startOf("day") <
        moment().startOf("day")
    );

    setContractState({
      filteredServiceContract: filteredServiceContract,
      activeContract: activeContract,
      inActiveContract: inActiveContract,
    });
  }, [contracts, collectionKey]);

  const onTabContextClickHandler = (
    e: React.KeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key: string
  ) => {
    setSelectedState(key);
  };

  const ContractCard: React.FC<{
    contract: (typeof contracts)[0];
    active: boolean;
  }> = ({ contract, active }) => {
    const [isProductAvailable, setIsProductAvailable] =
      React.useState<boolean>(true);
    const subscription = contract.SubscriptionId ? true : false;
    const dateBoolean =
      subscription && contract.SubscriptionStatus === "active";
    React.useEffect(() => {
      setIsProductAvailable(
        collection.products.nodes.filter(
          (product: any) => product.variants.edges[0].node.sku === contract.Sku
        ).length > 0
      );
    }, []);

    return (
      <div className="contract-card" key={contract.ContractId}>
        <div className="image-info">
          <div className="image-wrapper">
            <img src={ServiceContractImage} alt="contract-img" />
          </div>
          <div className="info">
            <div className="name">{contract.ContractName}</div>
            {isProductAvailable ? (
              <div className="expires">
                {active ? (dateBoolean ? "Renews " : "Expires ") : "Expired "}
                <span>
                  {dateBoolean
                    ? moment(contract.ContractEndDate)
                        .add(1, "d")
                        .format("DD-MM-YYYY")
                    : moment(contract.ContractEndDate).format("DD-MM-YYYY")}
                </span>
              </div>
            ) : (
              <div className="no-product">Product Discontinued</div>
            )}
          </div>
        </div>
        <div className="button-wrapper">
          <Button
            variant="secondary"
            icon={<Iconography icon="gear" size={IconographySizes.Medium} />}
            disabled={!isProductAvailable}
            onClick={() => {
              setDetailModalOpen(true);
              setDetailModalContract(contract);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="tab-content-service">
      <div className="switcher-row">
        <ContentSwitcher
          data={[
            { key: "active", label: "Active" },
            { key: "inactive", label: "Inactive" },
          ]}
          selected={selectedState}
          onClick={onTabContextClickHandler}
        />
      </div>
      <div className="tile-container">
        {status.contractsLoading || customer.company === "" ? (
          <div
            style={{
              padding: "32px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader variant={LoaderSizes.large} />
          </div>
        ) : selectedState === "active" ? (
          contractState.activeContract.length > 0 ? (
            contractState.activeContract.map(
              (contract: (typeof contracts)[0]) => {
                return (
                  <ContractCard
                    contract={contract}
                    active={true}
                    key={contract.ContractId}
                  />
                );
              }
            )
          ) : (
            <div className="no-contract">
              <Iconography icon="cartShopping" size={IconographySizes.Medium} />
              <p>No Products Purchased</p>
            </div>
          )
        ) : contractState.inActiveContract?.length > 0 ? (
          contractState.inActiveContract?.map(
            (contract: (typeof contracts)[0]) => {
              return (
                <ContractCard
                  contract={contract}
                  active={false}
                  key={contract.ContractId}
                />
              );
            }
          )
        ) : (
          <div className="no-contract">
            <Iconography icon="cartShopping" size={IconographySizes.Medium} />
            <p>No Products Purchased</p>
          </div>
        )}
      </div>
      {detailModalOpen && (
        <ServiceContractDetailModal
          serviceContractDetail={detailModalContract}
          setDetailModalOpen={setDetailModalOpen}
        />
      )}
    </div>
  );
};

export default TabContentServiceContract;

import React from "react";
import { useAppDispatch } from "../../../redux";
import { getLoginSagaActions } from "../../../redux/saga/auth/saga-actions";
import { authSliceAction } from "../../../redux/slices/auth";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../redux";
import HIDFooter from "../../../common/components/HIDFooter/HIDFooter";
import {
  Button,
  Divider,
  DividerVariants,
  NotificationVariants,
  PasswordInput,
  TextField,
  Notification,
  Iconography,
  IconographySizes,
} from "@hid-galaxy-ui/galaxy-react";
import HIDCustomLogo from "./HIDCustomLogo";
import "./Login.scss";

const Login = () => {
  const { loginDetail, errorInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [userDetail, setUserDetail] = React.useState<{
    user: {
      email: string;
      password: string;
    };
  }>({
    user: {
      email: "",
      password: "",
    },
  });
  const [toasts, setToasts] = React.useState([]);
  const loginButtonRef = React.useRef<HTMLButtonElement>();

  React.useEffect(() => {
    if (errorInfo?.errorMsg !== "") {
      let content = {
        id: Math.floor(Math.random() * 1000),
        title: errorInfo.errorMsg,
        message: "Please try again",
        variant: NotificationVariants.Error,
        icon: <Iconography icon="lockHashtag" size={IconographySizes.Medium} />,
        expanded: true,
      };
      let textisEmpty =
        userDetail.user.email === "" || userDetail.user.password === "";
      if (errorInfo?.errorMsg && textisEmpty) {
        content = {
          id: Math.floor(Math.random() * 1000),
          title: "Please enter email and password",
          message: "",
          variant: NotificationVariants.Error,
          icon: (
            <Iconography icon="lockHashtag" size={IconographySizes.Medium} />
          ),
          expanded: false,
        };
      }
      setToasts([content]);
    }
  }, [errorInfo]);

  React.useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (
      emailRegex.test(userDetail.user.email) &&
      toasts[0]?.title === "Enter Valid E-Mail Domain"
    )
      setToasts([]);
  }, [userDetail.user.email]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setUserDetail({
      user: {
        ...userDetail.user,
        [event.currentTarget.name]: event.currentTarget.value,
      },
    });
  };

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(userDetail.user.email)) {
      let content = {
        id: Math.floor(Math.random() * 1000),
        title: "Enter Valid E-Mail Domain",
        message: "",
        variant: NotificationVariants.Warning,
        icon: <Iconography icon="envelop" size={IconographySizes.Medium} />,
        expanded: false,
      };
      setToasts([content]);
    }
  };

  const onKeyDownHandler = (toRef: React.MutableRefObject<HTMLElement>) => {
    return (event: React.KeyboardEvent<HTMLElement>) => {
      try {
        if (event.key === "Enter") {
          toRef.current.focus();
          toRef.current.click();
        }
      } catch (error) {
        console.warn("Error handling keydown event:", error);
      }
    };
  };

  return (
    <>
      <div className="login-container">
        <div className="left-view">
          <div className="logo-wrapper">
            <HIDCustomLogo />
          </div>
          <div className="welcome-text">Welcome to the Partner Portal</div>
        </div>
        <div className="right-view">
          <div className="login-box">
            <p className="title">SIGN IN</p>
            <div className="input-wrapper">
              <TextField
                label="E-Mail ID"
                id="email"
                name="email"
                placeholder="name@organization.com"
                value={userDetail.user.email}
                theme="dark"
                onChange={handleChange}
                onBlur={validateEmail}
              />
            </div>
            <div
              className="password-wrapper"
              onKeyDown={onKeyDownHandler(loginButtonRef)}
            >
              <PasswordInput
                label="Password"
                id="password"
                name="password"
                placeholder="Password"
                value={userDetail.user.password}
                theme="dark"
                onChange={handleChange}
              />
            </div>
            <Notification
              toasts={toasts}
              autoClose={5000}
              isSticky={true}
              isAutoClose={false}
              setToasts={setToasts}
            />
            <Button
              label="SIGN IN"
              variant="primary"
              type="submit"
              elementRef={loginButtonRef}
              onClick={() =>
                dispatch(getLoginSagaActions({ ...userDetail.user }))
              }
            />
            <Button
              label="RESET PASSWORD"
              variant="secondary"
              onClick={() => navigate("/forgot-password")}
            />
            <div style={{ padding: "8px 24px" }}>
              <Divider mode={DividerVariants.Content} />
            </div>
            <p className="title-2">Don’t have an account?</p>
            <Button
              label="Join the partner program"
              variant="secondary"
              onClick={() =>
                window.open(
                  "https://www.hidglobal.com/partners/origo-technology-partner-program",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      </div>
      <HIDFooter />
    </>
  );
};

export default React.memo(Login);

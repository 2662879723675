import React from "react";
import { Header, Logo, LogoThemes } from "@hid-galaxy-ui/galaxy-react";
import { Popper, ClickAwayListener } from "@mui/material";
import { fetchCartIDSagaActions } from "../../../redux/saga/cart/saga-actions";
import { fetchCustomerDetailSagaAction } from "../../../redux/saga/my-account/saga-actions";
import useLogoutHandler from "../../../hooks/useLogoutHandler";
import { useAppDispatch, useAppSelector } from "../../../redux";
import MiniCart from "../MiniCart";
import "./HIDHeader.scss";
import { useNavigate } from "react-router";

const HIDHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [cartOpen, setCartOpen] = React.useState<boolean>(false);
  const [triggerLogout] = useLogoutHandler();
  const { cartSize } = useAppSelector((state) => state.cart);
  const { customer } = useAppSelector((state) => state.my_account);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(fetchCartIDSagaActions());
    if (customer.firstName === "") dispatch(fetchCustomerDetailSagaAction());
  }, []);

  const onActionClick = (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.ChangeEvent<HTMLElement>,
    key: string
  ) => {
    if (key === "cartShopping") handleCartClick(e);
    if (key === "my-account") navigate("/partner/my-account");
    if (key === "logout") triggerLogout();
  };

  const handleCartClick = (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.ChangeEvent<HTMLElement>
  ) => {
    if (!anchorEl && !cartOpen)
      setTimeout(() => {
        setCartOpen(true);
      }, 100);
    setAnchorEl(event.currentTarget);
  };

  const handleCartClose = () => {
    if (anchorEl && cartOpen) {
      setAnchorEl(null);
      setCartOpen(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "cart-popper" : undefined;

  return (
    <>
      <Header
        originHref="/partner/dashboard/home"
        logo={
          <span
            style={{ display: "flex" }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/partner/dashboard/home");
            }}
          >
            <Logo theme={LogoThemes.Light} title="Partner Portal" />
          </span>
        }
        userName={customer.firstName}
        actions={[
          {
            icon: "cartShopping",
            key: "cartShopping",
            badge: cartSize,
          },
          {
            icon: "circleUser",
            key: "user",
            isUserAction: true,
            subNavItems: [
              {
                data: [
                  {
                    key: "my-account",
                    value: "My Account",
                  },
                  {
                    key: "logout",
                    value: "Sign Out",
                  },
                ],
              },
            ],
          },
        ]}
        actionCallback={onActionClick}
      />
      <ClickAwayListener onClickAway={handleCartClose}>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end" style={{zIndex: 5}}>
          <MiniCart />
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default HIDHeader;

import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  Iconography,
  Logo,
  TextField,
  Notification,
  NotificationVariants,
  Button,
  IconographySizes,
  LogoThemes
} from "@hid-galaxy-ui/galaxy-react";
import HIDFooter from "../../../common/components/HIDFooter/HIDFooter";
import { authSliceAction } from "../../../redux/slices/auth";
import { customerRecoverSagaAction } from "../../../redux/saga/auth/saga-actions";
import { useNavigate } from "react-router";
import "./ForgotPassword.scss";

const ForgotPassword: React.FC = () => {
  const { errorInfo, custRecoverSuccessMsg } = useAppSelector(
    (state) => state.auth
  );
  const [email, setEmail] = React.useState<string>("");
  const [toasts, setToasts] = React.useState([]);
  const [btnClicked, setBtnClicked] = React.useState<boolean>(false)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(
      authSliceAction.errorInfoTrigger({
        severity: "",
        errorMsg: "",
        open: false,
      })
    );
    dispatch(authSliceAction.setCustRecoverSuccessMsg(""));
  }, []);

  React.useEffect(() => {
    let content = {
      id: Math.floor(Math.random() * 1000),
      title: "",
      message: custRecoverSuccessMsg,
      variant: NotificationVariants.Success,
      icon: <Iconography icon="circleCheck" size={IconographySizes.Medium} />,
    };
    if (btnClicked && custRecoverSuccessMsg.length > 0) setToasts([content]);
  }, [custRecoverSuccessMsg]);

  React.useEffect(() => {
    let content = {
      id: Math.floor(Math.random() * 1000),
      title: "",
      message: errorInfo?.errorMsg,
      variant: NotificationVariants.Error,
      icon: <Iconography icon="ban" size={IconographySizes.Medium} />,
    };
    if (btnClicked && errorInfo?.errorMsg.length > 0) setToasts([content]);
  }, [errorInfo]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(customerRecoverSagaAction(email));
    setBtnClicked(true);
  };

  // return (
  //   <div className="forgot-password-container" style={{ marginTop: "0px" }}>
  //     <Box sx={{ bgcolor: "#FFFFFF" }}>
  //       <Grid container>
  //         <Grid item xs={6} md={8} className="login-header">
  //           <div>
  //             <div className="header-1">Welcome To The Services Portal</div>
  //             <div className="header-2">
  //               Portal for ordering the services for Packages and Validations
  //             </div>
  //           </div>
  //         </Grid>
  //         <Grid item xs={6} md={4} className="right-view">
  //           <div className="form-box">
  //             <Formik
  //               initialValues={initialValues}
  //               validationSchema={validationSchema}
  //               onSubmit={handleSubmit}
  //             >
  //               {() => (
  //                 <Form>
  //                   <div className="logo-head">
  //                     <Logo title="Services Portal" id="logo" />
  //                   </div>
  //                   <div className="uname-input">
  //                     <label>Email</label>
  //                     <Field type="email" name="email" component={MyInput} />
  //                     <ErrorMessage
  //                       name="email"
  //                       component="div"
  //                       className="error"
  //                     />
  //                   </div>
  //                   <div className="error-msg">{errorInfo.errorMsg}</div>
  //                   <div className="success-msg">{custRecoverSuccessMsg}</div>
  //                   <div className="box-bottom">
  //                     <Button label="Submit" variant="primary" type="submit">
  //                       Login
  //                     </Button>
  //                   </div>
  //                 </Form>
  //               )}
  //             </Formik>
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //     <HIDFooter />
  //   </div>
  // );

  return (
    <>
      <Notification
        toasts={toasts}
        autoClose={8000}
        isAutoClose={true}
        setToasts={setToasts}
      />
      <div className="forgot-password-container">
        <Logo theme={LogoThemes.Light} title="Partner Portal" />
        <div className="form-box">
          <h1 className="title">Reset Password</h1>
          <p className="description">
            Enter the E-Mail associated with your account and we’ll send you a
            link to reset your password.
          </p>
          <div className="input-wrapper">
            <TextField
              label="E-Mail Address"
              id="email"
              name="email"
              placeholder="Enter your E-mail"
              value={email}
              theme="dark"
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
          </div>
          <Button
            label="SEND LINK"
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          />
          <Button
            label="BACK TO SIGN IN"
            variant="secondary"
            icon={<Iconography icon="arrowLeft" />}
            onClick={() => navigate("/login")}
          />
        </div>
      </div>
      <HIDFooter />
    </>
  );
};

export default ForgotPassword;

import React from "react";
import convertToNoDecimalValue from "../../utils/convertToNoDecimalValue";
import {
  Button,
  Iconography,
  Loader,
  LoaderSizes,
  ThickNessLevel,
} from "@hid-galaxy-ui/galaxy-react";
import { useAppSelector } from "../../../redux";
import { ProductType } from "../../../redux/slices/product-detail/Types";
import "./ServiceCard.scss";

interface ServiceCardProps {
  product: ProductType;
  onAddToCartClick: (
    productVariantId: string,
    sellingPlanID: string | undefined
  ) => void;
  onViewClick: (product: ProductType) => void;
  width?: number;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  product,
  onAddToCartClick,
  onViewClick,
  width,
}) => {
  const { addedProductID, addToCartLoading, supportServiceInCart, cartItems } =
    useAppSelector((state) => state.cart);
  const { allowedSupportPackage, purchasedSupportPackage } = useAppSelector(
    (state) => state.plp
  );
  const addToCartLoadingStaus =
    addToCartLoading && addedProductID === product.variants.edges[0].node.id;
  const sellingPlanID =
    product.sellingPlanGroups.edges[0]?.node.sellingPlans.edges[0]?.node.id;

  const addedToCart = (): boolean => {
    for (let i = 0; i < cartItems?.lines?.edges?.length; i++) {
      if (cartItems.lines.edges[i].node.merchandise.product.id === product.id)
        return true;
    }
    return false;
  };

  let addToCartDisabledSupportService = false;
  let allowedSupPackage = true;
  let isPurchasedSupPackage = false;
  if (
    product.productType?.replaceAll(" ", "_").toLowerCase() ===
    "support_service"
  ) {
    allowedSupPackage = allowedSupportPackage.includes(
      product?.variants?.edges[0]?.node?.sku
    );
    addToCartDisabledSupportService =
      supportServiceInCart || !allowedSupPackage;
    isPurchasedSupPackage =
      purchasedSupportPackage === product?.variants?.edges[0]?.node?.sku;
  }

  const addToCartDisabled = addToCartDisabledSupportService || addedToCart();

  return (
    <div className="service-card" style={width ? { width: `${width}px` } : {}}>
      <div className="img-wrapper">
        <img src={product.featuredImage?.url} alt="no img" />
      </div>
      <div className="info">
        <div className="row">
          <div className="title">{product.title}</div>
          <div className="price">
            $
            {convertToNoDecimalValue(product.priceRange.maxVariantPrice.amount)}
          </div>
        </div>
        <div className="row">
          <div className="cart-button">
            {!allowedSupPackage && isPurchasedSupPackage ? (
              <Button
                label="Purchased"
                variant="override"
                icon={<Iconography icon="circleCheck" />}
                onClick={() => {}}
              />
            ) : (
              <>
                <Button
                  label="Add to cart"
                  variant="primary"
                  icon={<Iconography icon="cartShopping" />}
                  disabled={addToCartLoadingStaus || addToCartDisabled}
                  onClick={() =>
                    onAddToCartClick(
                      product.variants.edges[0].node.id,
                      sellingPlanID
                    )
                  }
                />
                {addToCartLoadingStaus && (
                  <Loader
                    variant={LoaderSizes.small}
                    thicknessLevel={ThickNessLevel.thick}
                  />
                )}
              </>
            )}
          </div>
          <Button
            label="VIEW"
            variant="secondary"
            onClick={() => onViewClick(product)}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;

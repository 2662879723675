import { call, put, takeLatest } from "redux-saga/effects"
import { axiosRestInstance } from "../../../common/axios";
import { AxiosResponse } from "axios";
import { partnerDetailsSliceAction } from "../../slices/partner-details";
import { partnerDetailsSagaActionTypes, getAllPartnersSagaAction } from "./saga-actions";

type getAllPartnersAPIResponseType = {
    success: boolean;
    message: string;
    data: {
        partner_name: string;
    }[];
}

const getAllPartnersAPI = async () => {
    let response = axiosRestInstance.get<AxiosResponse<getAllPartnersAPIResponseType>>('/partnerDetails/getAllPartners')
        .then((res) => res.data);
    return response;
}

type PartnerDetailsType = {
    partner_name: string
    description: string,
    address: string,
    company_contact: string,
    corporate_website: string,
    company_email: string,
    company_logo: string | File,
    region: string,
    country: string,
    support_email: string,
    bu: string
}

type getPartnerDetailsAPIResponseType = {
    success: boolean;
    message: string;
    data: PartnerDetailsType;
}

const getPartnerDetailsAPI = async (partner : string) => {
    let response = axiosRestInstance.get<AxiosResponse<getPartnerDetailsAPIResponseType>>('/partnerDetails/getPartner/' + partner)
        .then((res) => res.data);
    return response;
}

type addPartnerAPIResponseType = {
    success: boolean;
    message: string;
    data: any;
}

const addPartnerAPI = async (partnerDetails : PartnerDetailsType) => {
    let response =axiosRestInstance.post<AxiosResponse<addPartnerAPIResponseType>>('/partnerDetails/insertPartner', partnerDetails)
        .then((res) => res.data);
    return response;
}

type updatePartnerAPIResponseType = {
    success: boolean;
    message: string;
    data: any;
}

const updatePartnerAPI = async (partnerDetails : PartnerDetailsType) => {
    let response =axiosRestInstance.put<AxiosResponse<updatePartnerAPIResponseType>>('/partnerDetails/updatePartner/' + partnerDetails.partner_name, partnerDetails)
        .then((res) => res.data);
    return response;
}

function* getAllPartnersSaga(actions: {
    type: string
}) {
    try {
        yield put(partnerDetailsSliceAction.setAllPartnersLoading(true));
        let response: getAllPartnersAPIResponseType = yield call(getAllPartnersAPI);
        yield put(partnerDetailsSliceAction.setAllPartners(response.data));
        yield put(partnerDetailsSliceAction.setAllPartnersLoading(false));

    } catch (e) {
        yield put(partnerDetailsSliceAction.setAllPartnersLoading(false));
        console.log(e)
    }
}

function* getPartnerDetailsSaga(actions: {
    type: string,
    payload: {
        partner: string,
    }
}) {
    try {
        yield put(partnerDetailsSliceAction.setPartnerDetailsLoading(true));
        let response: getPartnerDetailsAPIResponseType = yield call(getPartnerDetailsAPI, actions.payload.partner);
        yield put(partnerDetailsSliceAction.setPartnerDetails(response.data));
        yield put(partnerDetailsSliceAction.setPartnerDetailsLoading(false));

    } catch (e) {
        yield put(partnerDetailsSliceAction.setPartnerDetailsLoading(false));
        console.log(e)
    }
}

function* addPartnerSaga(actions: {
    type: string,
    payload: {
        partnerDetails: PartnerDetailsType,
    }
}) {
    try {
        yield put(partnerDetailsSliceAction.setAddPartnerLoading(true));
        let response: addPartnerAPIResponseType = yield call(addPartnerAPI, actions.payload.partnerDetails);
        yield put(partnerDetailsSliceAction.setAddPartnerMessage(response.message));
        yield put(partnerDetailsSliceAction.setAddPartnerLoading(false));
        yield put(getAllPartnersSagaAction());

    } catch (e) {
        yield put(partnerDetailsSliceAction.setAddPartnerLoading(false));
        console.log(e)
    }
}

function* updatePartnerSaga(actions: {
    type: string,
    payload: {
        partnerDetails: PartnerDetailsType,
    }
}) {
    try {
        yield put(partnerDetailsSliceAction.setUpdatePartnerLoading(true));
        let response: updatePartnerAPIResponseType = yield call(updatePartnerAPI, actions.payload.partnerDetails);
        yield put(partnerDetailsSliceAction.setUpdatePartnerMessage(response.message));
        yield put(partnerDetailsSliceAction.setUpdatePartnerLoading(false));

    } catch (e) {
        yield put(partnerDetailsSliceAction.setUpdatePartnerLoading(false));
        console.log(e)
    }
}

function* partnerDetailsSaga() {
    yield takeLatest(partnerDetailsSagaActionTypes.callGetAllPartnersSaga, getAllPartnersSaga);
    yield takeLatest(partnerDetailsSagaActionTypes.callGetPartnerDetailsSaga, getPartnerDetailsSaga);
    yield takeLatest(partnerDetailsSagaActionTypes.callAddPartnerSaga, addPartnerSaga);
    yield takeLatest(partnerDetailsSagaActionTypes.callUpdatePartnerSaga, updatePartnerSaga);
}

export default partnerDetailsSaga;
import React from "react";
import { useRoutes, Outlet, Navigate } from "react-router-dom";
import LandingPage from "./pages";
import Login from "./pages/auth/login";
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/reset-password";
import useProductListPageRoute from "./hooks/useProductListPageRoute";
import ProtectedRoute from "./ProtectedRoute";
import HIDAdminDashboard from "./pages/hid-admin/dashboard";
import PartnerLandingPage from "./pages/partner";
import PartnerDashboard from "./pages/partner/dashboard";
import PartnerHome from "./pages/partner/dashboard/home";
import PartnerServices from "./pages/partner/dashboard/services";
import PartnerSettings from "./pages/partner/dashboard/settings";
import PartnerMyAccount from "./pages/partner/my-account";

const AppRoutes: React.FC<{
  authenticationStatus: boolean | Object;
}> = ({ authenticationStatus }) => {
  const { productListRoutesArray } = useProductListPageRoute();
  const routes = [
    {
      path: "/",
      element: (
        <ProtectedRoute
          element={<LandingPage />}
          authenticationStatus={authenticationStatus}
        />
      ),
      children: [
        {
          path: "partner",
          element: <PartnerLandingPage />,
          children: [
            {
              path: "dashboard",
              element: <PartnerDashboard />,
              children: [
                {
                  path: "home",
                  element: <PartnerHome />,
                },
                {
                  path: "services",
                  element: <PartnerServices />,
                },
                {
                  path: "settings",
                  element: <PartnerSettings />,
                },
              ],
            },
            {
              path: "my-account",
              element: <PartnerMyAccount />,
            },
          ],
        },
        { path: "*", element: <Navigate to="/" /> },
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    {
      path: "/reset-password/:customerId/:resetToken",
      element: <ResetPassword />,
    },
  ];

  return useRoutes(routes);
};

export default AppRoutes;

import React from "react";
import {
  Iconography,
  IconographySizes,
  Modal,
  Tabs,
} from "@hid-galaxy-ui/galaxy-react";
import "./SLAModal.scss";

interface SLAModalProps {
  collection: {
    id?: string;
    handle?: string;
    title?: string;
    description?: string;
    products?: {
      nodes?: {
        title?: string;
        id?: string;
        description?: string;
        descriptionHtml?: string;
        productType?: string;
        priceRange?: {
          minVariantPrice?: {
            amount?: string;
            currencyCode?: string;
          };
          maxVariantPrice?: {
            amount?: string;
            currencyCode?: string;
          };
        };
        featuredImage?: {
          url?: string;
        };
        metafields: Array<{
          key: string;
          value: string;
        }>;
        variants?: {
          edges?: {
            node?: {
              sku?: string;
              id?: string;
            };
          }[];
        };
        tags?: string[];
        sellingPlanGroups: {
          edges: {
            node: {
              sellingPlans: {
                edges: {
                  node: {
                    id: string;
                  };
                }[];
              };
            };
          }[];
        };
      }[];
    };
  };
  setDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  slaDetailSKU: string;
}
const SLAModal: React.FC<SLAModalProps> = ({
  collection,
  setDetailModalOpen,
  slaDetailSKU,
}) => {
  const SLACollectionProducts = collection.products.nodes.filter(
    (product) =>
      product.variants?.edges[0]?.node?.sku !== "ORIGO-TPS-OMI-STARTER-NEW"
  );

  function onTabClickHandler(e: any, key: string): void {}

  const SLATable: React.FC<{ product: any }> = ({ product }) => {
    const priorityArray: Array<string> = ["p1", "p2", "p3", "p4"];
    const ValueForKey = (key: string): string => {
      const features = product.metafields;
      for (let i = 0; i < features.length; i++) {
        if (features[i]?.key === key) {
          return features[i].value;
        }
      }
      return "NA";
    };
    return (
      <div className="table-container">
        <div className="head">
          <div className="col-1">Priority </div>
          <div className="col-2">Defination</div>
          <div className="col-3">Initial Response</div>
          <div className="col-4">Cadence</div>
        </div>
        {priorityArray.map((priority: string, index: number) => {
          return (
            <div className="row" key={priority}>
              <div className="col-1">{priority}</div>
              <div className="col-2">
                {ValueForKey(`sla_${priority}_defination`)}
              </div>
              <div className="col-3">
                {ValueForKey(`sla_${priority}_initial`)}
              </div>
              <div className="col-4">
                {ValueForKey(`sla_${priority}_cadence`)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      width={832}
      isDisplayCloseIcon={false}
      footerConfig={{
        primaryBtnLabel: "CLOSE WINDOW",
        onClickPrimaryBtn: () => setDetailModalOpen(false),
      }}
      onClose={() => setDetailModalOpen(false)}
      className="sla-management-modal"
    >
      <div className="close-btn">
        <span onClick={() => setDetailModalOpen(false)}>
          <Iconography icon="circleX" size={IconographySizes.Medium} />
        </span>
      </div>
      <div className="header">
        <div className="row-1">SLA Management</div>
        <div className="row-2">Compare SLA Management Offers</div>
      </div>
      <Tabs
        theme="frameless"
        selectedKey={slaDetailSKU}
        items={SLACollectionProducts.map((tab, index: number) => {
          return {
            key: tab.variants?.edges[0]?.node?.sku,
            label: tab.title,
            content: <SLATable product={tab} />,
          };
        })}
        onClick={onTabClickHandler}
      />
    </Modal>
  );
};

export default SLAModal;

import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import {
  getAllowedSupportPackageAction,
  getCollectionListAction,
} from "../../../../redux/saga/product-list/saga-action";
import {
  contractFindAllSagaAction,
  fetchCustomerDetailSagaAction,
} from "../../../../redux/saga/my-account/saga-actions";
import useProductListPageRoute from "../../../../hooks/useProductListPageRoute";
import { useNavigate } from "react-router";
import {
  Iconography,
  IconographySizes,
  Loader,
  LoaderSizes,
  Tabs,
} from "@hid-galaxy-ui/galaxy-react";
import moment from "moment";
import AnnouncementCarousel from "../../../../common/components/AnnouncementCarousel/AnnouncementCarousel";
import TabContentHome from "./tab-content-home";
import PartnerInfo from "./partner-info";
import "./Home.scss";

const Home = () => {
  const { customer, contracts, status } = useAppSelector(
    (state) => state.my_account
  );
  const {
    collection,
    allowedSupportPackage,
    status: statusPLP,
  } = useAppSelector((state) => state.plp);
  const { getRouteIndex, dispatchIt } = useProductListPageRoute();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shopifyNavigationHandle = "service-catalog";
  const subCollection = collection[getRouteIndex(shopifyNavigationHandle)];
  const sortedCollection = React.useMemo(() => subCollection, [collection]);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
    if (customer.email === "") dispatch(fetchCustomerDetailSagaAction());
    if (contracts.length === 0 && customer.company !== "") {
      dispatch(contractFindAllSagaAction(customer.company));
    }
    if (collection[getRouteIndex(shopifyNavigationHandle)]?.length === 0) {
      dispatchIt(shopifyNavigationHandle);
    }
  }, []);

  React.useEffect(() => {
    if (contracts.length === 0 && customer.company !== "") {
      dispatch(contractFindAllSagaAction(customer.company));
    }
    if (allowedSupportPackage.length === 0 && customer.company !== "") {
      dispatch(getAllowedSupportPackageAction(customer.company));
    }
  }, [customer]);

  const actionButton: {
    label: string;
    icon: string;
    onClick: () => void;
  }[] = [
    {
      label: "Explore Services",
      icon: "cartShopping",
      onClick: () => {
        navigate("/partner/dashboard/services");
      },
    },
    {
      label: "View My Services",
      icon: "grid2Plus",
      onClick: () => {
        navigate("/partner/my-account", {
          state: {
            selectedTab: "my-services",
          },
        });
      },
    },
    {
      label: "View Orders",
      icon: "receipt",
      onClick: () => {
        navigate("/partner/my-account", {
          state: {
            selectedTab: "order-history",
          },
        });
      },
    },
  ];

  function onTabClickHandler(e: any, key: string): void {}

  let announcement = [
    {
      title: "Builder - Support Service",
      content:
        "Help your organization use App Based Credential for a seamless and efficient access control experience",
      productID: "gid://shopify/Product/9846716268853",
      productType: "Support Service",
      variantID: "gid://shopify/ProductVariant/50109724262709",
      sku: "ORIGO-TPS-OMI-STARTER-NEW",
    },
    {
      title: "Core - Support Service",
      content:
        "Help your organization use App Based Credential for a seamless and efficient access control experience",
      productID: "gid://shopify/Product/9846716727605",
      productType: "Support Service",
      variantID: "gid://shopify/ProductVariant/50109725278517",
      sku: "PS-SUPPORT-T100",
    },
  ];

  return (
    <div className="home-container">
      <h1 className="page-title">Welcome, {customer.firstName}</h1>
      <PartnerInfo />
      <AnnouncementCarousel announcements={announcement} />
      <div className="service-contract-categories">
        {status.customerDetailLoading ||
        status.contractsLoading ||
        statusPLP.loading ? (
          <div className="loading-tabs">
            <Loader variant={LoaderSizes.large} />
          </div>
        ) : sortedCollection?.length > 0 ? (
          <Tabs
            theme="frameless"
            items={sortedCollection.map(
              (collection: (typeof sortedCollection)[0], index: number) => {
                const filteredServiceContract = contracts.filter(
                  (contract) =>
                    contract.Collection?.trim()
                      .toLowerCase()
                      .replaceAll(" ", "_") ===
                    collection.handle.replaceAll("-", "_")
                );
                const activeContract = filteredServiceContract.filter(
                  (contract) =>
                    moment(contract.ContractEndDate).startOf("day") >=
                    moment().startOf("day")
                );
                const inActiveContract = filteredServiceContract.filter(
                  (contract) =>
                    moment(contract.ContractEndDate).startOf("day") <
                    moment().startOf("day")
                );
                const contractState = {
                  filteredServiceContract: filteredServiceContract,
                  activeContract: activeContract,
                  inActiveContract: inActiveContract,
                };
                return {
                  key: collection.id,
                  label: collection.title,
                  content: (
                    <TabContentHome
                      collection={collection}
                      contractState={contractState}
                    />
                  ),
                };
              }
            )}
            onClick={onTabClickHandler}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="action-container">
        <h1 className="title">Actions</h1>
        <div className="button-container">
          {actionButton.map((button, index) => {
            return (
              <div
                className="action-button"
                key={button.label}
                onClick={button.onClick}
              >
                <div className="icon-wrapper">
                  <Iconography
                    icon={button.icon}
                    size={IconographySizes.Large}
                  />
                </div>
                <div className="label">{button.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;

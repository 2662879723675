import { call, put, takeLatest, delay, select } from "redux-saga/effects"
import { productDetailSagaActionTypes } from "./saga-action"
import { axiosGraphqlInstance } from "../../../common/axios"
import { productDetailSliceAction } from "../../slices/product-detail"
import { AxiosResponse } from "axios"
import { productDetailResponseType, featureSectionResponseType, recommendedSectionResponse } from "./Types"
import { cartCreateOrUpdateCartSagaActions, cartCreateOrUpdateCartSagaActionsSubscription } from "../cart/saga-actions"

const productDetailApi = async (productId: string) => {
  const featureKeys = [
    "development_support_validity",
    "sla_management",
    "access_to_hiddeveloper_community",
    "access_to_hid_academy",
    "development_support",
    "sandbox_environment",
    "demo_licenses",
    "test_hardware",
    "solution_validation",
    "dedicated_regional_consultant",
    "escalation_manager",
    "solution_certification",
    "24_7_emergency_contact",
    "monthly_follow_up_meeting",
    "instructor_led_training",
    "milestones",
    "additional_milestones",
    "key_features"
  ];
  const identifiers = featureKeys.map((key) => {
    return { key: key, namespace: "custom" };
  });
  const query = `
    query ProductDetail($productId: ID!, $identifiers: [HasMetafieldsIdentifier!]!) {
      product(id: $productId) {
        title
        productType
        media(first: 5) {
          edges {
            node {
              mediaContentType
              ...mediaFieldsByType
            }
          }
        }
        featuredImage {
          url
        }
        metafields(identifiers: $identifiers) {
          key
          value
        }
        priceRange {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        variants(first: 1) {
          edges {
            node {
              sku
              id
            }
          }
        }
        tags
        descriptionHtml
        description
        sellingPlanGroups(first:1) {
        edges {
          node {
            sellingPlans(first: 3) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
    }
    fragment mediaFieldsByType on Media {
      ... on MediaImage {
        image {
          url
        }
      }
    }
  `;
  let response = axiosGraphqlInstance.post<AxiosResponse<productDetailResponseType>>('/graphql.json', {
    query,
    variables: {
      productId,
      identifiers
    }
  }).then((response) => response.data.data)
  return response;
}

const featureSectionApi = async (bundle_string: string) => {

  let response = axiosGraphqlInstance.post<AxiosResponse<featureSectionResponseType>>('/graphql.json', {
    query: `{
          products(first: 50, query: "${bundle_string}") {
            edges {
              node {
                id
                title
                descriptionHtml
                description
                featuredImage{
                  url
               }
                variants(first: 5) {
                  edges {
                    node {
                      id
                      sku
                      title
                    }
                  }
                }
              }
            }
          }
        }`,
    variables: {

    }
  }).then((response) => response.data.data)
  return response;
}

const recommendedSectionApi = async (recommended_string: string) => {
  let response = axiosGraphqlInstance.post<AxiosResponse<recommendedSectionResponse>>('/graphql.json', {
    query: ` {
        products(first: 50, query: "${recommended_string}") {
          edges {
            node {
              handle
              id
              media(first: 1) {
                edges {
                  node {
                    mediaContentType
                    ...mediaFieldsByType
                  }
                }
              }
              featuredImage{
                url
             }
              title
              variants(first: 1) {
                edges {
                  node {
                    id
                    sku
                  }
                }
              }
              metafield(namespace: "custom", key: "validation_date") {
                value
              }
              priceRange {
                minVariantPrice {
                  amount
                }
                maxVariantPrice {
                  amount
                }
              }
            }
          }
        }
      }
      fragment mediaFieldsByType on Media {
        ... on MediaImage {
          image {
            url
          }
        }
      }
  `,
    variables: {

    }
  }).then((response) => response.data.data)
  return response;
}

const mapper = async (productDetailApiResponse: productDetailResponseType) => {
  // console.log("productDetailApiResponse", productDetailApiResponse)
  let bundle_string = "";
  let recommend_string = "";
  let bundle_array = productDetailApiResponse?.product?.tags?.filter((element) => element.split('').includes('_') && element.includes('bundle'));
  // console.log("bundle_array", bundle_array);
  let recommended_array = productDetailApiResponse?.product?.tags?.filter((element) => element.split('').includes('_') && element.includes('recommend'));
  // console.log("recommended_array", recommended_array);

  if (bundle_array.length) {
    bundle_array.forEach((element, index) => {
      if (index == bundle_array.length - 1) {
        bundle_string = bundle_string + element.split('_')[1]
      } else {
        bundle_string = bundle_string + element.split('_')[1] + " OR "
      }
    })
  }
  if (recommended_array.length) {
    recommended_array.forEach((element, index) => {
      if (index == recommended_array.length - 1) {
        recommend_string = recommend_string + element.split('_')[1]
      } else {
        recommend_string = recommend_string + element.split('_')[1] + " OR "
      }
    })
  }

  return {
    bundle_string: bundle_array.length ? bundle_string : null,
    bundle_string_aplited_by_OR: bundle_array.length ? bundle_string.split(" OR ") : null,
    recommend_string: recommended_array.length ? recommend_string : null,
    recommend_string_aplited_by_OR: recommended_array.length ? recommend_string.split(" OR ") : null

  };
}

function* getProductDetail(actions: {
  type: string,
  payload: {
    productID: string
  }
}) {
  try {
    let productId = actions.payload.productID;

    yield put(productDetailSliceAction.setLoadingStatus(true));

    let productDetailApiResponse: productDetailResponseType = yield call(productDetailApi, productId);


    // let stringObject: {
    //   bundle_string: string | null,
    //   bundle_string_aplited_by_OR: string[],
    //   recommend_string: string | null
    //   recommend_string_aplited_by_OR: string[],
    // } = yield call(mapper, productDetailApiResponse);
    // console.log("stringObject", stringObject)

    // let featureSectionApiResponse: featureSectionResponseType;
    // let recommendedSectionApiResponse: recommendedSectionResponse;

    // if (stringObject.bundle_string) {
    //   featureSectionApiResponse = yield call(featureSectionApi, stringObject.bundle_string)
    // }
    // if (stringObject.recommend_string) {
    //   recommendedSectionApiResponse = yield call(recommendedSectionApi, stringObject.recommend_string)
    // }
    // console.log("response in ---product-detail--- saga!!", { productDetailApiResponse, featureSectionApiResponse, recommendedSectionApiResponse })
    // console.log("productId", productId)

    // console.log("mapper ---> ", stringObject)
    // yield delay(500);
    // let featureSectionApiResponseFilteredBySKU: any = [];
    // let featureSectionApiResponseProducts = featureSectionApiResponse?.products?.edges.map((element) => element.node);
    // let recommendedSectionApiResponseFilteredBySKU: any = [];
    // let recommendedSectionApiResponseProducts = recommendedSectionApiResponse?.products?.edges.map((element) => element.node)

    // stringObject.bundle_string_aplited_by_OR?.forEach((element) => {
    //   featureSectionApiResponseFilteredBySKU.push(...featureSectionApiResponseProducts.filter((subElement) => {
    //     return subElement.variants.edges[0].node.sku === element // !important --Do Not Touch It -- will not change (verified)
    //   }))
    // })

    // stringObject.recommend_string_aplited_by_OR?.forEach((element) => {
    //   recommendedSectionApiResponseFilteredBySKU.push(...recommendedSectionApiResponseProducts.filter((subElement) => {
    //     return subElement.variants.edges[0].node.sku === element // !important --Do Not Touch It -- will not change (verified)
    //   }))
    // })

    // console.log('featureSectionApiResponseFilteredBySKU , recommendedSectionApiResponseFilteredBySKU', featureSectionApiResponseFilteredBySKU, recommendedSectionApiResponseFilteredBySKU)

    yield put(productDetailSliceAction.set_productDetail_featureSection_recommendedSection({
      productId: productId,
      productDetail: productDetailApiResponse ? productDetailApiResponse?.product : {},
      featureSection: [],
      recommendedSection: []
    }));

    yield put(productDetailSliceAction.setLoadingStatus(false));

  } catch (e) {
    console.error(e)

    yield put(productDetailSliceAction.setLoadingStatus(false));
  }
}

function* getProductDetailAndAddToCart(actions: {
  type: string,
  payload: {
    data : {
      productID: string,
      cartID: string | null;
      userToken: string | null;
    }
  }
}) {
  try {
    let productId = actions.payload.data.productID;
    yield put(productDetailSliceAction.setProductDetailAndAddToCartLoading(true));
    let productDetailApiResponse: productDetailResponseType = yield call(productDetailApi, productId);
    const productVariantId = productDetailApiResponse.product?.variants.edges[0]?.node.id;
    const sellingPlanID = productDetailApiResponse.product?.sellingPlanGroups?.edges[0]?.node.sellingPlans.edges[0]?.node.id;
    if (sellingPlanID) {
      yield put(
        cartCreateOrUpdateCartSagaActionsSubscription({
          cartId: actions.payload.data.cartID,
          userToken: actions.payload.data.userToken,
          pdp: {
            productVariantId: productVariantId,
            sellingPlanId: sellingPlanID,
          },
          navigateFunction: () => {},
        })
      );
    } else {
      yield put(
        cartCreateOrUpdateCartSagaActions({
          cartId: actions.payload.data.cartID,
          userToken: actions.payload.data.userToken,
          pdp: {
            featureSectionTitleAndVariantIdArray: [],
            productVariantId: productVariantId,
          },
          navigateFunction: () => {},
        })
      );
    }
    yield put(productDetailSliceAction.setProductDetailAndAddToCartLoading(false));

  } catch (e) {
    console.error(e)
    yield put(productDetailSliceAction.setProductDetailAndAddToCartLoading(false));
  }
}

function* productDetailSaga() {
  yield takeLatest(productDetailSagaActionTypes.callGetProductDetailSaga, getProductDetail);
  yield takeLatest(productDetailSagaActionTypes.callGetProductDetailAndAddToCartSaga, getProductDetailAndAddToCart);
}

export default productDetailSaga;
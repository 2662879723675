import React from "react";
import { ProductType } from "../../../redux/slices/product-detail/Types";
import { useAppSelector } from "../../../redux";
import {
  Accordion,
  AccordionItem,
  Button,
  Divider,
  DividerVariants,
  Iconography,
  IconographySizes,
  Loader,
  LoaderSizes,
  Modal,
  ThickNessLevel,
} from "@hid-galaxy-ui/galaxy-react";
import convertToNoDecimalValue from "../../utils/convertToNoDecimalValue";
import "./ProductDetailModal.scss";

interface ProductDetailModalProps {
  product: ProductType;
  onAddToCartClick: (
    productVariantId: string,
    sellingPlanID: string | undefined
  ) => void;
  setDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductDetailModal: React.FC<ProductDetailModalProps> = ({
  product,
  onAddToCartClick,
  setDetailModalOpen,
}) => {
  const { addedProductID, addToCartLoading, supportServiceInCart, cartItems } =
    useAppSelector((state) => state.cart);
  const { status } = useAppSelector((state) => state.pdp);
  const { allowedSupportPackage, purchasedSupportPackage } = useAppSelector(
    (state) => state.plp
  );
  const addToCartLoadingStaus =
    addToCartLoading && addedProductID === product.variants.edges[0].node.id;
  const sellingPlanID =
    product.sellingPlanGroups?.edges[0]?.node.sellingPlans.edges[0]?.node.id;
  let accordionItem = [
    {
      id: "description",
      title: "Description",
      children: product?.descriptionHtml,
    },
  ];

  if (
    product.productType?.replaceAll(" ", "_").toLowerCase() !==
    "support_service"
  ) {
    product.metafields
      ?.map((metafield: { key: string; value: string } | null) => {
        if (metafield) {
          let objectValue;
          try {
            objectValue = JSON.parse(metafield.value);
          } catch (e) {
            objectValue = metafield.value;
          }
          const title = metafield.key?.split("_").join(" ");
          const paragraph = objectValue?.children?.[0].children?.[0].value;
          return {
            id: metafield.key,
            title: title,
            children: paragraph,
          };
        }
        return null;
      })
      .forEach((item: any) => {
        if (item) accordionItem.push(item);
      });
  }

  const addedToCart = (): boolean => {
    for (let i = 0; i < cartItems?.lines?.edges?.length; i++) {
      if (cartItems.lines.edges[i].node.merchandise.product.id === product.id)
        return true;
    }
    return false;
  };

  let addToCartDisabledSupportService = false;
  let allowedSupPackage = true;
  let isPurchasedSupPackage = false;
  if (
    product.productType?.replaceAll(" ", "_").toLowerCase() ===
    "support_service"
  ) {
    allowedSupPackage = allowedSupportPackage.includes(
      product?.variants?.edges[0]?.node?.sku
    );
    addToCartDisabledSupportService =
      supportServiceInCart || !allowedSupPackage;
    isPurchasedSupPackage =
      purchasedSupportPackage === product?.variants?.edges[0]?.node?.sku;
  }

  const addToCartDisabled = addToCartDisabledSupportService || addedToCart();

  return (
    <Modal
      width={698}
      isDisplayCloseIcon={false}
      footerConfig={{
        secondaryBtnLabel: "CLOSE WINDOW",
      }}
      onClose={() => setDetailModalOpen(false)}
      className="product-detail-modal"
    >
      {status.loading ? (
        <div className="loading-product">
          <Loader variant={LoaderSizes.large} />
        </div>
      ) : (
        <>
          <div className="img-wrapper">
            <img src={product.featuredImage?.url} alt="No img" />
            <span onClick={() => setDetailModalOpen(false)}>
              <Iconography icon="circleX" size={IconographySizes.Large} />
            </span>
          </div>
          <div className="product-details">
            <div className="info-1">
              <h1>{product.title}</h1>
              <p>{product.productType}</p>
            </div>
            <div className="info-2">
              <div className="tile">
                <Iconography
                  icon="moneyBill_1"
                  size={IconographySizes.Medium}
                />
                <label>Price</label>
                <p>
                  ${" "}
                  {convertToNoDecimalValue(
                    product.priceRange.maxVariantPrice.amount
                  )}
                </p>
              </div>
              <div className="tile">
                <Iconography icon="circleInfo" size={IconographySizes.Medium} />
                <label>Part Number</label>
                <p>{product.variants.edges[0].node.sku}</p>
              </div>
              <div className="tile">
                <Iconography icon="calendar" size={IconographySizes.Medium} />
                <label>Type</label>
                <p>{sellingPlanID ? "Subscription" : "One Time"}</p>
              </div>
            </div>
            <div className="cart-button">
              {!allowedSupPackage && isPurchasedSupPackage ? (
                <Button
                  label="Purchased"
                  variant="override"
                  icon={<Iconography icon="circleCheck" />}
                  onClick={() => {}}
                />
              ) : (
                <>
                  <Button
                    label="Add to cart"
                    variant="primary"
                    icon={<Iconography icon="cartShopping" />}
                    disabled={addToCartLoadingStaus || addToCartDisabled}
                    onClick={() =>
                      onAddToCartClick(
                        product.variants.edges[0].node.id,
                        sellingPlanID
                      )
                    }
                  />
                  {addToCartLoadingStaus && (
                    <Loader
                      variant={LoaderSizes.small}
                      thicknessLevel={ThickNessLevel.thick}
                    />
                  )}
                </>
              )}
            </div>
            <Divider mode={DividerVariants.Content} />
            <Accordion theme="grey">
              {accordionItem.map((item) => (
                <AccordionItem
                  key={item.id}
                  title={item.title}
                  isExpand={false}
                  disabled={false}
                >
                  <div
                    className="accordian-body"
                    dangerouslySetInnerHTML={{ __html: item.children }}
                  />
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ProductDetailModal;
